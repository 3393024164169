import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Fields } from '../shared/dto/fields';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class WizerValidatorService {
  private field = Fields;

  constructor(private toast: NotificationService) {}

  public CheckMessagesAndNotify(ctrls: {
    [key: string]: AbstractControl | FormGroup;
  }) {
    const error = [];
    this.checkForInvalidAndError(ctrls, error);
    // if (error.length) this.toast.error(error[0]);
  }

  private checkForInvalidAndError(
    ctrls: { [key: string]: AbstractControl | FormGroup },
    error: any
  ) {
    for (let key in ctrls) {
      if (ctrls[key] && ctrls[key]['controls']) {
        this.checkForInvalidAndError(ctrls[key]['controls'], error);
      }
      const errors = ctrls[key].errors;
      if (!errors || !Object.keys(errors).length) continue;
      if (errors && errors['required']) {
        this.toast.error(
          `${this.field[key] || 'One or more field'} is required`
        );
        return;
      }
      if (errors && errors['minlength']) {
        this.toast.error(
          `${this.field[key]} requires ${errors['minlength'].requiredLength} characters`
        );
        return;
      }
      if (errors && (errors['pattern'] || errors['validatePhoneNumber'])) {
        this.toast.error(`Invalid ${this.field[key] || 'field'}`);
        return;
      }
      if (errors && errors['alreadyExist']) {
        this.toast.error(`${this.field[key]} already exists`);
        return;
      }
      if (errors && errors['matching']) {
        this.toast.error(`${errors['collectiveName']} do not match`);
        return;
      }
    }
    // Object.keys(ctrls).forEach((ctrl) => {
    //   if(ctrls[ctrl].value === ''){
    //     this.toast.error(`${this.field[ctrl]} is required`);
    //     return
    //   }
    // if (ctrls[ctrl].invalid && ctrls[ctrl].errors) {
    //   if (ctrls[ctrl].errors['message']) {
    //     error.push(ctrls[ctrl].errors['message']);
    //   }
    //   if (ctrls[ctrl].errors['pattern'] || ctrls[ctrl].errors['invalidAsync'] || ctrls[ctrl].errors['minLength']) {
    //     error.push(`One or more field(s) is invalid`);
    //   }
    // }
    // if (ctrls[ctrl] && ctrls[ctrl]['controls']) {
    //   this.checkForInvalidAndError(ctrls[ctrl]['controls'], error);
    // }
    // });
  }

  public MatchValidator(
    matchTo: string,
    reverse?: boolean,
    collectiveName?: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true, collectiveName };
    };
  }
}

export function forbiddenNameValidator(forbiddenName: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue = control.value?.toLowerCase();
    const forbidden = forbiddenName.some((name) =>
      inputValue.includes(name.toLowerCase())
    );
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
