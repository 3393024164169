import { Component, Inject, OnInit } from '@angular/core';
import {
  PageRequest,
  SearchableSelectTypes,
  StateTypes,
} from '../../models/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { WizerValidatorService } from 'src/app/utils/wizer.validators.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/utils/utility.service';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Unsubscribe } from 'src/app/utils/unsubscribe.service';
import { LoanProductService } from 'src/app/pages/settings/services/loan-product.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { AddressService } from '../../services/address.service';
import { LoanService } from 'src/app/pages/loan-management/services/loan.service';
import { GuarantorService } from 'src/app/pages/loan-management/services/guarantor.service';
import { Permissions } from '../../dto/permissions';
import { PermissionService } from 'src/app/utils/permission.service';
import { environment } from 'src/environments/environment';
import { CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

const guarantorTab = {
  name: 'Guarantor',
  value: 'guarantor',
  perms: [Permissions.NONE],
};

@Component({
  selector: 'app-new-loan-account',
  templateUrl: './new-loan-account.component.html',
  styleUrls: ['./new-loan-account.component.css'],
})
export class NewLoanAccountComponent extends Unsubscribe implements OnInit {
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [
    CountryISO.Nigeria,
    CountryISO.UnitedStates,
  ];

  public basicForm: FormGroup;
  private formValid: boolean[] = [];
  public disableGuarantor: boolean = true;
  public checkingBVN: boolean = false;
  public checkingPhone: boolean = false;
  public guarantorForm: FormGroup;
  public loanProducts: SearchableSelectTypes[] = [];
  public fundSources = [];
  public states: SearchableSelectTypes[] = [];
  public localGovernments: SearchableSelectTypes[] = [];
  public businessLocalGovernments: SearchableSelectTypes[] = [];
  private clientId: number;
  public loanProductRecords: any;
  public activeTab: string = 'basic';

  isViewMode: boolean = false;
  disableForm: boolean = false;
  imageValues = {
    photo: '',
    signature: '',
    idCard: '',
  };

  calendarDays = {
    day: {
      week: 7,
      month: 30,
      year: 365,
    },
    week: {
      month: 4,
      year: 52,
    },
    month: {
      year: 12,
    },
  };

  loanFrequencyOptions = [
    { label: 'Day', value: 'DAY' },
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
    { label: 'Year', value: 'YEAR' },
  ];

  public toggleStates: StateTypes[] = [
    {
      name: 'Basic',
      value: 'basic',
      perms: [Permissions.NONE],
      isActive: true,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private validatorService: WizerValidatorService,
    private notification: NotificationService,
    private loanProductService: LoanProductService,
    private loanAccountService: LoanService,
    private addressService: AddressService,
    private utils: UtilityService,
    private guarantorService: GuarantorService,
    private permissionService: PermissionService,
    private dialogRef: MatDialogRef<NewLoanAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.clientId = data.id;

    this.states = this.utils.mapRecordsToSearchableSelect(data.states);
  }

  close() {
    this.dialogRef.close();
  }

  get dobAgeRange(): Date {
    const date = new Date();
    const yearRange = date.getFullYear() - 18;
    date.setFullYear(yearRange);
    return date;
  }

  private initForm() {
    const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9]+\\.[a-z]{2,4}$';

    this.basicForm = this.fb.group({
      clientId: new FormControl(this.clientId, [Validators.required]),
      loanProductId: new FormControl('', Validators.required),
      guarantorRelationship: new FormControl(''),
      principal: new FormControl(
        {
          value: '',
          disabled: !this.permissionService.hasPermissionList([
            Permissions.MODIFY_LOAN_SIZE,
          ]),
        },
        Validators.required
      ),
      loanPeriodLength: new FormControl(
        {
          value: '',
          disabled: !this.permissionService.hasPermissionList([
            Permissions.MODIFY_LOAN_TERM,
          ]),
        },
        Validators.required
      ),
      loanPeriodLengthUnit: new FormControl(
        { value: 'DAY', disabled: true },
        Validators.required
      ),
      interestRate: new FormControl(
        {
          value: '',
          disabled: !this.permissionService.hasPermissionList([
            Permissions.MODIFY_INTEREST_RATE,
          ]),
        },
        [
          Validators.required,
          Validators.pattern(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/),
        ]
      ),
      interestRateUnit: new FormControl(
        { value: 'DAY', disabled: true },
        Validators.required
      ),
      loanRepaymentFrequency: new FormControl(
        {
          value: '',
          disabled: !this.permissionService.hasPermissionList([
            Permissions.MODIFY_REPAYMENT_FREQUENCY,
          ]),
        },
        Validators.required
      ),
      loanRepaymentFrequencyPeriodUnit: new FormControl(
        { value: 'DAY', disabled: true },
        Validators.required
      ),
      amortizationType: new FormControl('', Validators.required),
      fundSourceAccountId: new FormControl({ value: '', disabled: true }),
      gracePeriodLength: new FormControl({
        value: '',
        disabled: !this.permissionService.hasPermissionList([
          Permissions.MODIFY_GRACE_PERIOD,
        ]),
      }),
      gracePeriodUnit: new FormControl({ value: 'DAY', disabled: true }),
    });
    this.onLoanProductSelected();

    this.guarantorForm = this.fb.group({
      bvn: new FormControl('', [Validators.maxLength(11)]),
      firstName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      lastName: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(''),
      occupation: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.pattern(emailPattern)]),
      stateId: new FormControl('', Validators.required),
      lgaId: new FormControl('', Validators.required),
      townName: new FormControl('', Validators.required),
      addressLine1: new FormControl('', Validators.required),
      businessAddressLine1: new FormControl('', Validators.required),
      businessStateId: new FormControl('', Validators.required),
      businessLgaId: new FormControl('', Validators.required),
      businessTownName: new FormControl('', Validators.required),
      photo: new FormControl('', Validators.required),
      signature: new FormControl('', Validators.required),
      idCard: new FormControl('', Validators.required),
    });

    this.onStateSelected();

    this.guarantorForm.get('mobileNumber').valueChanges.subscribe((res) => {
      if (!res) return;
      this.checkPhoneNumber();
    });
  }

  get basicFormCtrl() {
    return this.basicForm.controls;
  }

  get guarantorsCtrl() {
    return this.guarantorForm.controls;
  }

  onLoanProductSelected() {
    this.basicForm.controls['loanProductId'].valueChanges
      .pipe(
        filter((id) => !!id),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        switchMap((loanProductId) => {
          return this.loanProductService
            .getLoanProduct(loanProductId)
            .pipe(map((record: any) => record.data));
        })
      )
      .subscribe((data) => {
        this.loanProductRecords = data;
        const { fundSources } = data;
        this.fundSources = fundSources;
        if (this.loanProductRecords.requireGuarantor) {
          if (!this.toggleStates.find(state=>state.value === 'guarantor')) {
            this.toggleStates = [...this.toggleStates, guarantorTab];
          }
        } else {
          this.toggleStates = this.toggleStates.filter(
            (val) => val.value !== 'guarantor'
          );
        }
        this.basicForm.patchValue({
          loanProductId: data.id,
          loanPeriodLength: data.defaultLoanPeriodLength,
          principal: data.defaultPrincipal,
          interestRate: data.defaultInterestRate,
          interestRateUnit: data.interestRatePeriodUnit,
          loanPeriodLengthUnit: data.loanPeriodUnit,
          guarantorRelationship: '',
          loanRepaymentFrequency: data.loanRepaymentFrequency,
          loanRepaymentFrequencyPeriodUnit:
            data.loanRepaymentFrequencyPeriodUnit,
          amortizationType: data.amortizationType,
          gracePeriodLength: data.gracePeriodLength,
          gracePeriodUnit: data.gracePeriodUnit,
        });
      });
  }

  navigateToState(val: string) {
    if (val === 'guarantor') {
      if (this.basicForm.invalid) {
        this.validatorService.CheckMessagesAndNotify(this.basicForm.controls);
        this.toggleStates = this.toggleStates.map((val) => {
          val.value === 'basic'
            ? (val.isActive = true)
            : (val.isActive = false);
          return val;
        });
        this.activeTab = 'basic';
        return;
      }
      this.activeTab = 'guarantor';
      return;
    }
    this.activeTab = 'basic';
  }

  onStateSelected() {
    this.guarantorForm.controls['stateId'].valueChanges
      .pipe(
        filter((id) => !!id),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        switchMap((stateId) => {
          return this.addressService
            .getLga(stateId)
            .pipe(map((lga) => lga.data.data));
        })
      )
      .subscribe((data) => {
        this.localGovernments = this.utils.mapRecordsToSearchableSelect(data);
        // this.guarantorForm.get('lgaId').enable()
      });
    this.guarantorForm.controls['businessStateId'].valueChanges
      .pipe(
        filter((id) => !!id),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        switchMap((businessStateId) => {
          return this.addressService
            .getLga(businessStateId)
            .pipe(map((businessLgaId) => businessLgaId.data.data));
        })
      )
      .subscribe((data) => {
        this.businessLocalGovernments =
          this.utils.mapRecordsToSearchableSelect(data);
        // this.guarantorForm.get('lgaId').enable()
      });
  }

  checkBVN() {
    // const bvn = this.guarantorForm.controls['bvn'].value;
    // if (bvn.length !== 11) {
    //   this.notification.error('BVN should be 11 digits');
    //   this.formValid.push(false);
    //   return;
    // }
    // this.checkingBVN = true;
    // this.guarantorService.getGuarantorInfoBVN(bvn).subscribe(
    //   (result) => {
    //     this.checkingBVN = false;
    //     const { data } = result;
    //     if (data?.active) {
    //       this.notification.success('BVN already exist');
    //       this.isViewMode = true;
    //     } else this.notification.success('BVN available');
    //     this.setGuaratorRecords(data);
    //   },
    //   (error) => (this.checkingBVN = false)
    // );
  }

  checkPhoneNumber() {
    const phone = this.guarantorForm.controls['mobileNumber'];
    if (
      this.checkingPhone ||
      !phone.value.number ||
      phone.value.number.length < 10
    )
      return;
    const mobileNumber = phone.value.e164Number;
    this.checkingPhone = true;
    this.guarantorService.getGuarantorInfoPhone(mobileNumber).subscribe({
      next: (result) => {
        if (!result.successful || !result.data?.length) {
          this.guarantorForm.enable();
          this.setGuaratorRecords(this.guarantorForm.getRawValue(), true);
          this.isViewMode = false;
          this.checkingPhone = false;
          return;
        }
        const { data } = result;
        const guarantor = data[data.length - 1];
        if (guarantor?.active) {
          this.notification.error(
            'Guarantor is guaranteeing an active loan and cannot be used'
          );
          this.guarantorForm.reset();
          this.setGuaratorRecords(this.guarantorForm.getRawValue(), true);
          this.isViewMode = false;
        } else {
          this.setGuaratorRecords(guarantor);
          this.guarantorForm.disable();
          this.notification.success(
            'Guarantor found and available to guarantee a loan'
          );
          this.isViewMode = true;
        }
        this.guarantorForm.get('mobileNumber').enable();
        this.checkingPhone = false;
      },
      error: () => {
        this.guarantorForm.enable();
        this.checkingPhone = false;
      },
    });
  }

  setGuaratorRecords(records: any, clear?: boolean) {
    records = {
      ...records,
      stateId: records?.state?.id,
      lgaId: records?.lga?.id,
    };
    const keys = Object.keys(records);
    const excludedKeys = ['mobileNumber'];
    keys.forEach((key) => {
      if (this.guarantorForm.contains(key) && !excludedKeys.includes(key)) {
        this.guarantorForm.controls[key].setValue(clear ? '' : records[key]);
      }
    });
    const imgPath = environment.baseUrl + environment.coreUrl + 'data/';
    this.imageValues.photo = clear
      ? null
      : `${imgPath}${records?.passportPhoto}`;
    this.imageValues.signature = clear
      ? null
      : `${imgPath}${records?.signature}`;
    this.imageValues.idCard = clear ? null : `${imgPath}${records?.idCard}`;
  }

  selectedLoanTenorUnit() {
    const loanRepaymentFrequencyPeriodUnit = this.basicForm.get(
      'loanRepaymentFrequencyPeriodUnit'
    ).value;
    switch (loanRepaymentFrequencyPeriodUnit.toUpperCase()) {
      case 'DAY':
        this.loanFrequencyOptions = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
          { label: 'Year', value: 'YEAR' },
        ];
        break;
      case 'WEEK':
        this.loanFrequencyOptions = [
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
          { label: 'Year', value: 'YEAR' },
        ];
        break;
      case 'MONTH':
        this.loanFrequencyOptions = [
          { label: 'Month', value: 'MONTH' },
          { label: 'Year', value: 'YEAR' },
        ];
        break;
      case 'YEAR':
        this.loanFrequencyOptions = [{ label: 'Year', value: 'YEAR' }];
        break;
      default:
        this.loanFrequencyOptions = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
          { label: 'Year', value: 'YEAR' },
        ];
    }
  }

  validateLoanSize() {
    const principal = this.basicForm.get('principal').value;
    if (Number(principal) > Number(this.loanProductRecords.maximumPrincipal)) {
      this.notification.error(
        'Loan Size cannot be greater than the maximum loan size'
      );
      this.formValid.push(false);
      return;
    }

    if (Number(principal) < Number(this.loanProductRecords.minimumPrincipal)) {
      this.notification.error(
        'Loan Size cannot be less than the minimum loan size'
      );
      this.formValid.push(false);
      return;
    }

    this.formValid.push(true);
  }

  validateLoanFrequency() {
    const loanRepaymentFrequency = this.basicForm.get(
      'loanRepaymentFrequency'
    ).value;
    if (
      Number(loanRepaymentFrequency) >
      Number(this.loanProductRecords.maximumLoanRepaymentFrequency)
    ) {
      this.notification.error(
        'Loan Frequency cannot be greater than the maximum loan frequency'
      );
      this.formValid.push(false);
      return;
    }

    if (
      Number(loanRepaymentFrequency) <
      Number(this.loanProductRecords.minimumLoanRepaymentFrequency)
    ) {
      this.notification.error(
        'Loan Frequency cannot be less than the minimum loan frequency'
      );
      this.formValid.push(false);
      return;
    }

    this.formValid.push(true);
  }

  validateGracePeriod() {
    const gracePeriodLength = this.basicForm.get('gracePeriodLength').value;
    if (
      Number(gracePeriodLength) >
      Number(this.loanProductRecords.maximumGracePeriod)
    ) {
      this.notification.error(
        'Grace period cannot be greater than the maximum Grace period'
      );
      this.formValid.push(false);
      return;
    }

    if (
      Number(gracePeriodLength) <
      Number(this.loanProductRecords.minimumGracePeriod)
    ) {
      this.notification.error(
        'Grace period cannot be less than the minimum Grace period'
      );
      this.formValid.push(false);
      return;
    }

    this.formValid.push(true);
  }

  validateLoanTerm() {
    const loanPeriodLength = this.basicForm.get('loanPeriodLength').value;
    const loanRepaymentFrequency = this.basicForm.get(
      'loanRepaymentFrequency'
    ).value;
    const loanPeriodLengthUnit = this.basicForm.get(
      'loanPeriodLengthUnit'
    ).value;
    const loanRepaymentFrequencyPeriodUnit = this.basicForm.get(
      'loanRepaymentFrequencyPeriodUnit'
    ).value;
    if (
      Number(loanPeriodLength) >
      Number(this.loanProductRecords.maximumLoanPeriodLength)
    ) {
      this.notification.error(
        'Loan Term cannot be greater than the maximum loan term'
      );
      this.formValid.push(false);
      return;
    }

    if (
      Number(loanPeriodLength) <
      Number(this.loanProductRecords.minimumLoanPeriodLength)
    ) {
      this.notification.error(
        'Loan Term cannot be less than the minimum loan term'
      );
      this.formValid.push(false);
      return;
    }

    this.formValid.push(true);

    switch (loanPeriodLengthUnit.toLowerCase()) {
      case 'day':
        {
          if (
            loanRepaymentFrequency &&
            loanPeriodLength % loanRepaymentFrequency !== 0
          ) {
            this.notification.error(
              `Repayment frequency not consistent with loan tenor`
            );
            this.basicFormCtrl['loanPeriodLength'].setErrors({
              incorrect: true,
            });
            this.formValid.push(false);
          } else {
            this.basicFormCtrl['loanPeriodLength'].setErrors(null);
            this.formValid.push(false);
          }
        }
        break;
      case 'week':
        {
          switch (loanRepaymentFrequencyPeriodUnit.toLowerCase()) {
            case 'day':
              {
                if (
                  loanRepaymentFrequency &&
                  (loanPeriodLength * this.calendarDays.day.week) %
                    loanRepaymentFrequency !==
                    0
                ) {
                  this.notification.error(
                    `Repayment frequency not consistent with loan tenor`
                  );
                  this.basicFormCtrl['loanPeriodLength'].setErrors({
                    incorrect: true,
                  });
                  this.formValid.push(false);
                } else {
                  this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                  this.formValid.push(true);
                }
              }
              break;
            case 'week': {
              if (
                loanRepaymentFrequency &&
                loanPeriodLength % loanRepaymentFrequency !== 0
              ) {
                this.notification.error(
                  `Repayment frequency not consistent with loan tenor`
                );
                this.basicFormCtrl['loanPeriodLength'].setErrors({
                  incorrect: true,
                });
                this.formValid.push(false);
              } else {
                this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                this.formValid.push(true);
              }
            }
          }
        }
        break;
      case 'month':
        {
          switch (loanRepaymentFrequencyPeriodUnit.toLowerCase()) {
            case 'day':
              {
                if (
                  loanRepaymentFrequency &&
                  (loanPeriodLength * this.calendarDays.day.month) %
                    loanRepaymentFrequency !==
                    0
                ) {
                  this.notification.error(
                    `Repayment frequency not consistent with loan tenor`
                  );
                  this.basicFormCtrl['loanPeriodLength'].setErrors({
                    incorrect: true,
                  });
                  this.formValid.push(false);
                } else {
                  this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                  this.formValid.push(true);
                }
              }
              break;
            case 'week':
              {
                if (
                  loanRepaymentFrequency &&
                  (loanPeriodLength * this.calendarDays.week.month) %
                    loanRepaymentFrequency !==
                    0
                ) {
                  this.notification.error(
                    `Repayment frequency not consistent with loan tenor`
                  );
                  this.basicFormCtrl['loanPeriodLength'].setErrors({
                    incorrect: true,
                  });
                  this.formValid.push(false);
                } else {
                  this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                  this.formValid.push(true);
                }
              }
              break;
            case 'month': {
              if (
                loanRepaymentFrequency &&
                loanPeriodLength % loanRepaymentFrequency !== 0
              ) {
                this.notification.error(
                  `Repayment frequency not consistent with loan tenor`
                );
                this.basicFormCtrl['loanPeriodLength'].setErrors({
                  incorrect: true,
                });
                this.formValid.push(false);
              } else {
                this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                this.formValid.push(true);
              }
            }
          }
        }
        break;
      case 'year': {
        switch (loanRepaymentFrequencyPeriodUnit.toLowerCase()) {
          case 'day':
            {
              if (
                loanRepaymentFrequency &&
                (loanPeriodLength * this.calendarDays.day.year) %
                  loanRepaymentFrequency !==
                  0
              ) {
                this.notification.error(
                  `Repayment frequency not consistent with loan tenor`
                );
                this.basicFormCtrl['loanPeriodLength'].setErrors({
                  incorrect: true,
                });
                this.formValid.push(false);
              } else {
                this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                this.formValid.push(true);
              }
            }
            break;
          case 'week':
            {
              if (
                loanRepaymentFrequency &&
                (loanPeriodLength * this.calendarDays.week.year) %
                  loanRepaymentFrequency !==
                  0
              ) {
                this.notification.error(
                  `Repayment frequency not consistent with loan tenor`
                );
                this.basicFormCtrl['loanPeriodLength'].setErrors({
                  incorrect: true,
                });
                this.formValid.push(false);
              } else {
                this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                this.formValid.push(true);
              }
            }
            break;
          case 'month':
            {
              if (
                loanRepaymentFrequency &&
                (loanPeriodLength * this.calendarDays.month.year) %
                  loanRepaymentFrequency !==
                  0
              ) {
                this.notification.error(
                  `Repayment frequency not consistent with loan tenor`
                );
                this.basicFormCtrl['loanPeriodLength'].setErrors({
                  incorrect: true,
                });
                this.formValid.push(false);
              } else {
                this.basicFormCtrl['loanPeriodLength'].setErrors(null);
                this.formValid.push(true);
              }
            }
            break;
          case 'year': {
            if (
              loanRepaymentFrequency &&
              loanPeriodLength % loanRepaymentFrequency !== 0
            ) {
              this.notification.error(
                `Repayment frequency not consistent with loan tenor`
              );
              this.basicFormCtrl['loanPeriodLength'].setErrors({
                incorrect: true,
              });
              this.formValid.push(false);
            } else {
              this.basicFormCtrl['loanPeriodLength'].setErrors(null);
              this.formValid.push(true);
            }
          }
        }
      }
    }
  }

  public saveFile(base64String: string, fileType: string) {
    if (!base64String) return;
    this.guarantorForm.get(fileType)?.setValue(base64String);
  }

  public onSubmitBasic() {
    if (this.basicForm.invalid) {
      this.validatorService.CheckMessagesAndNotify(this.basicForm.controls);
      return;
    }

    this.formValid = [];
    this.validateLoanSize();
    this.validateLoanFrequency();
    this.validateGracePeriod();
    this.validateLoanTerm();

    if (this.formValid.some((invalid) => !invalid)) return;

    this.toggleStates.forEach((btnState: StateTypes) => {
      if (btnState.value === 'guarantor') {
        this.activeTab = 'guarantor';
        btnState.isActive = true;
      } else btnState.isActive = false;
    });
  }

  gotoBasic() {
    this.toggleStates.forEach((btnState: StateTypes) => {
      if (btnState.value === 'basic') {
        this.activeTab = 'basic';
        btnState.isActive = true;
      } else btnState.isActive = false;
    });
  }

  onSubmitGuarantor() {
    if (
      this.loanProductRecords?.requireGuarantor &&
      this.guarantorForm.invalid
    ) {
      this.validatorService.CheckMessagesAndNotify(this.guarantorForm.controls);
      return;
    }
    this.submit();
  }

  submit() {
    let guarantorPayload = null;
    if (this.loanProductRecords?.requireGuarantor) {
      guarantorPayload = this.guarantorForm.getRawValue();
      guarantorPayload['passportPhoto'] = guarantorPayload['photo'];
      delete guarantorPayload['photo'];
      guarantorPayload['bvn'] = !guarantorPayload['bvn']
        ? null
        : guarantorPayload['bvn'];
      guarantorPayload['mobileNumber'] =
        guarantorPayload['mobileNumber'].e164Number;
      guarantorPayload['passportPhoto'] = this.isViewMode
        ? null
        : guarantorPayload['photo'];
      guarantorPayload['signature'] = this.isViewMode
        ? null
        : guarantorPayload['signature'];
      guarantorPayload['idCard'] = this.isViewMode
        ? null
        : guarantorPayload['idCard'];
    }

    const payload = {
      ...this.basicForm.getRawValue(),
      guarantorRequest: { ...guarantorPayload },
    };

    if (!this.isViewMode && this.loanProductRecords?.requireGuarantor)
      payload.guarantorRequest.dateOfBirth =
        this.utils.formatDate_custom_yearFirst(
          this.guarantorForm.value.dateOfBirth
        );
    this.loanAccountService.createLoanAccount(payload).subscribe((res: any) => {
      if (!res.successful) return;
      this.notification.success(
        res.message ?? 'Loan account created successfully'
      );
      this.dialogRef.close({ successful: true, loanAccountId: res.data.id });
    });
  }

  public onCompInit() {
    this.initForm();
    // Meant to get all loan products (first 500)
    const pageRequest: PageRequest = {
      page: 0,
      pageSize: 500,
      state: 'APPROVED',
      sortField: '',
      sortOrder: 'DESC',
      filter: '',
    };
    const request = `page=${pageRequest.page}&pageSize=${pageRequest.pageSize}&status=${pageRequest.state}&sort-field=${pageRequest.sortField}&sort-order=${pageRequest.sortOrder}&filter=${pageRequest.filter}`;
    this.loanProductService.getLoanProducts(request).subscribe((res) => {
      if (!res.successful) return;
      this.loanProducts = this.utils.mapRecordsToSearchableSelect(res.data);
    });
  }

  ngOnInit(): void {
    this.onCompInit();
  }

  byLoanId(index, loan) {
    return loan.id;
  }
}
