import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbLinkType } from '../../models/common';

@Component({
  selector: 'app-breadcrumb-ui',
  templateUrl: './breadcrumb-ui.component.html',
  styleUrls: ['./breadcrumb-ui.component.css']
})
export class BreadcrumbUiComponent {
  @Input() breadCrumbLinks:BreadCrumbLinkType[] = [
    {
      name: $localize`:@@dashboard:Dashboard`,
      routeTo: 'dashboard'
    }
  ]

  constructor(private router: Router) { }

  public navigate(route: string){
    this.router.navigate([route]);
  }


}
