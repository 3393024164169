import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { data } from 'jquery';
import { filter, shareReplay, tap } from 'rxjs';
import { PermissionService } from 'src/app/utils/permission.service';
import { TitleActionBtnType } from '../../models/common';

@Component({
  selector: 'app-title-bar-ui',
  templateUrl: './title-bar-ui.component.html',
  styleUrls: ['./title-bar-ui.component.css'],
})
export class TitleBarUiComponent {
  @Input() title: string | undefined;
  @Input() parentPath: string = 'clients';
  @Input() titleStatic: string = '';
  @Input() showNavigation: boolean = true;
  @Input() showBackBtn: boolean = false;
  @Input() navButtons: TitleActionBtnType[] = [];
  @Input() useCustomUrl: boolean = false;
  @Input() disableClick: boolean = false;
  @Input() titleActionButtons: TitleActionBtnType[] = [];
  @Output() triggerTitleActionBtn: EventEmitter<string> = new EventEmitter();

  constructor(private permissions: PermissionService) {}

  public hasPermission(perm: string[]) {
    return this.permissions.hasPermissionList(perm);
  }
  public childrenHasPerm(children: TitleActionBtnType[]) {
    return children.find((child) => this.hasPermission(child.perms));
  }

  public trigger(val: string) {
    this.triggerTitleActionBtn.emit(val);
  }
  getLocalizedBackText() {
    return $localize`:@@back:Back`;
  }
  getLocalizedNextText() {
    return $localize`:@@next:Next`;
  }
  getLocalizedPrevText() {
    return $localize`:@@prevButton:Prev`;
  }
  getLocalizedEditText() {
    return $localize`:@@edit:Edit`;
  }
  getLocalizeddisableText() {
    return $localize`:@@disable:Disable`;
  }

  getLocalizedTooltip() {
    return $localize`:@@go_back:Go Back`;
  }
  getLocalizedCancelTooltip() {
    return $localize`:@@go_back:Go Back`;
  }
  getLocalizedCancel() {
    return $localize`:@@cancel:Cancel`;
  }
  goBack() {
    if (!this.useCustomUrl) {
      history.back();
      return;
    }
    this.triggerTitleActionBtn.emit('back');
  }
}
