export enum Permissions {
  NONE = 'none',

  //Settings Module Permissions
  LIST_CHARGE = 'list-charge',
  CREATE_CHARGE = 'create-charge',
  UPDATE_CHARGE = 'update-charge',
  APPROVE_CHARGE = 'approve-charge',
  ENABLE_CHARGE = 'enable-charge',
  ACTIVATE_CHARGE = 'activate-charge',
  REJECT_CHARGE = 'reject-charge',
  DISABLE_CHARGE = 'disable-charge',
  CREATE_LOAN_PRODUCT = 'create-loan-product',
  LIST_LOAN_PRODUCT = 'list-loan-product',
  CREATE_CURRENT_PRODUCT = 'create-current-product',
  LIST_CURRENT_PRODUCT = 'list-current-product',
  CREATE_INTERNAL_ACCOUNT = 'create-internal-account',
  LIST_INTERNAL_ACCOUNT = 'list-internal-account',
  LIST_ROLES = 'list-role',
  CREATE_ROLE = 'create-role',
  LIST_HOLIDAY = 'list-holiday',
  CREATE_HOLIDAY = 'create-holiday',
  APPROVE_HOLIDAY = 'approve-holiday',
  REJECT_HOLIDAY = 'reject-holiday',
  CREATE_CLIENT_SETUP = 'create-client-setup',
  LIST_CONTRACT = 'list-contract',
  VIEW_CONTRACT = 'view-contract',
  INITIATE_APPROVAL_LIMIT = 'initiate-modify-approval-limit',
  APPROVE_APPROVAL_LIMIT = 'approve-modify-approval-limit',
  INITIATE_MODIFY_POSTING_BACKDATE_LIMIT = 'initiate-modify-posting-backdate-limit',
  APPROVE_MODIFY_POSTING_BACKDATE_LIMIT = 'approve-modify-posting-backdate-limit',

  LIST_AREA = 'list-area',
  CREATE_AREA = 'create-area',
  ENABLE_AREA = 'enable-area',
  DISABLE_AREA = 'disable-area',
  APPROVE_AREA = 'approve-area',
  ACTIVATE_AREA = 'activate-area',
  REJECT_AREA = 'reject-area',

  LIST_BRANCH = 'list-branch',
  CREATE_BRANCH = 'create-branch',
  ENABLE_BRANCH = 'enable-branch',
  DISABLE_BRANCH = 'disable-branch',
  APPROVE_BRANCH = 'approve-branch',
  ACTIVATE_BRANCH = 'activate-branch',
  REJECT_BRANCH = 'reject-branch',

  CREATE_CONTRACT = 'create-contract',
  // ENABLE_CONTRACT = 'enable-contract',
  // DISABLE_CONTRACT = 'disable-contract',
  // APPROVE_CONTRACT = 'approve-contract',

  LIST_SAVINGS_PRODUCT = 'list-deposit-product',
  CREATE_SAVINGS_PRODUCT = 'create-deposit-product',
  ENABLE_SAVINGS_PRODUCT = 'enable-deposit-product',
  DISABLE_SAVINGS_PRODUCT = 'disable-deposit-product',
  APPROVE_SAVINGS_PRODUCT = 'approve-deposit-product',
  ACTIVATE_SAVINGS_PRODUCT = 'activate-deposit-product',
  REJECT_SAVINGS_PRODUCT = 'reject-deposit-product',

  //Dashboard Module Permissions
  VIEW_DASHBOARD_STAT = 'view-dashboard-stat',
  SHOW_CLIENTS_PER_BRANCH_CHART = 'show-clients-per-branch-chart',
  SHOW_DAILY_REPAYMENT_CHAART = 'show-daily-repayment-chart',
  SHOW_SAVINGS_PER_BRANCH_CHART = 'show-savings-per-branch-chart',
  SHOW_AMOUNT_WRITTEN_OFF_CHART = 'show-amount-written-off-chart',
  SHOW_RECENT_ACTIVITIES_TABLE = 'show-recent-activities-table',
  PAR_PER_BRANCH = 'par-per-branch',
  LOGGED_IN_USERS = 'logged-in-users',
  MY_DISBURSEMENTS = 'my-disbursements',

  //Client Module Permissions
  CREATE_CLIENT = 'create-client',
  LIST_CLIENT = 'list-client',
  VIEW_CLIENT = 'view-client',
  APPROVE_CLIENT = 'approve-client',
  REJECT_CLIENT = 'reject-client',
  ENABLE_CLIENT = 'enable-client',
  DISABLE_CLIENT = 'disable-client',
  ACTIVATE_CLIENT = 'activate-client',
  EDIT_CLIENT = 'edit-client',
  EDIT_CLIENT_DATE_OF_BIRTH = 'edit-client-date-of-birth',
  UPDATE_KYC_STATUS = 'update-kyc-status',
  LIST_KYC_REQUESTS = 'list-kyc-requests',
  VIEW_CLIENT_KYC = 'view-client-kyc',
  CREATE_GROUP = 'create-group',
  LIST_GROUP = 'list-group',
  VIEW_GROUP = 'view-group',
  DELETE_CLIENT_DOCUMENT = 'delete-client-document',
  DISABLE_GUARANTOR = 'disable-guarantor',
  ENABLE_GUARANTOR = 'enable-guarantor',
  UPDATE_GUARANTOR_MULTIPLE = 'update-guarantor-multiple',

  //Staff Module Permissions
  CREATE_STAFF = 'create-staff',
  LIST_STAFF = 'list-staff',
  STAFF_APPROVE = 'staff-approve',
  STAFF_REJECT = 'staff-reject',
  STAFF_DISABLE = 'staff-disable',
  STAFF_ENABLE = 'staff-enable',

  //User Module Permissions
  CREATE_USER = 'create-user',
  LIST_USERS = 'list-users',
  UPDATE_USER_ROLE = 'update-user-role',
  USER_ACTIVATE = 'user-activate',
  USER_ENABLE = 'user-enable',
  USER_DISABLE = 'user-disable',

  //Loan Module Permissions
  CREATE_CLIENT_LOAN_ACCOUNT = 'create-client-loan-account',
  LIST_CLIENT_LOAN_ACCOUNT = 'list-client-loan-account',
  VIEW_CLIENT_LOAN_ACCOUNT = 'view-client-loan-account',
  APPROVE_CLIENT_LOAN_ACCOUNT = 'approve-client-loan-account',
  REJECT_CLIENT_LOAN_ACCOUNT = 'reject-client-loan-account',
  POST_CLIENT_LOAN_REPAYMENT = 'post-client-loan-repayment',
  DISBURSE_CLIENT_LOAN = 'disburse-client-loan',
  APPROVE_CLIENT_LOAN_REPAYMENT = 'approve-client-loan-repayment',
  DECLINE_CLIENT_LOAN_REPAYMENT = 'decline-client-loan-repayment',
  REPAY_CLIENT_LOAN = 'repay-client-loan',
  WRITE_OFF_LOAN = 'write-off-loan',
  WAIVE_LOAN_INTEREST = 'waive-loan-interest',
  CANCEL_CLIENT_LOAN = 'cancel-client-loan',
  REVERSE_LOAN_TRANSACTION = 'reverse-loan-transaction',
  BACKDATE_LOAN_DISBURSEMENT = 'backdate-loan-disbursement',
  BACKDATE_LOAN_REPAYMENT = 'backdate-loan-repayment',
  REFUND_CLIENT_LOAN = 'refund-client-loan',
  UPDATE_FIRST_DISBURSEMENT_DATE = 'update-first-disbursement-date',
  EDIT_DATE_FOR_DISBURSEMENT = 'edit-date-for-disbursement',
  EDIT_DATE_FOR_LOAN_APPROVAL = 'edit-date-for-loan-approval',
  WAIVE_LATE_PENALTY_PAYMENT = 'waive-late-penalty-payment',
  BOOK_MULTIPLE_LOANS = 'book-multiple-loans',
  MODIFY_LOAN_SIZE = 'loan-size',
  MODIFY_LOAN_TERM = 'loan-term',
  MODIFY_LOAN_PRODUCT = 'loan-product',
  MODIFY_INTEREST_RATE = 'interest-rate',
  MODIFY_GRACE_PERIOD = 'grace-period',
  MODIFY_REPAYMENT_FREQUENCY = 'repayment-frequency',
  MULTIPLE_RUNNING_LOANS = 'multiple-running-loans',
  MODIFY_CLOSED_LOAN_DOCUMENTS = 'modify-closed-loan-documents',

  //Posting Module Permissions
  CREATE_GROUP_POSTING = 'create-group-posting',
  LIST_GROUP_POSTING = 'list-group-posting',
  APPROVE_GROUP_POSTING = 'approve-group-posting',
  REJECT_GROUP_POSTING = 'reject-group-posting',
  VIEW_GROUP_POSTING = 'view-group-posting',
  CREATE_BRANCH_WIDE_GROUP_POSTING = 'create-branch-wide-group-posting',
  BACK_DATE_GROUP_POSTING = 'back-date-group-posting',
  CREATE_INTER_BRANCH_POSTING = 'create-inter-branch-posting',

  // Savings/Deposit Module Permissions
  CREATE_CLIENT_SAVINGS_ACCOUNT = 'create-client-deposit-account',
  LIST_CLIENT_SAVINGS_ACCOUNT = 'list-client-deposit-account',
  APPROVE_CLIENT_SAVINGS_ACCOUNT = 'approve-client-deposit-account',
  REJECT_CLIENT_SAVINGS_ACCOUNT = 'reject-client-deposit-account',
  POST_SAVINGS_ACCOUNT_DEPOSIT = 'post-deposit-account-deposit',
  POST_SAVINGS_ACCOUNT_WITHDRAWAL = 'post-savings-account-withdrawal',
  REVERSE_SAVINGS_TRANSACTION = 'reverse-savings-transaction',
  POST_SAVINGS_FUND_TRANSFER = 'post-savings-fund-transfer',
  DEPOSIT_REVERSAL = 'deposit-reversal',
  DEPOSIT_ENABLE = 'deposit-enable',
  DEPOSIT_DISABLE = 'deposit-disable',
  //Transfer Module Permissions
  CREATE_CLIENT_TRANSFER = 'create-client-transfer',
  LIST_CLIENT_TRANSFER = 'list-client-transfer',
  APPROVE_CLIENT_TRANSFER = 'approve-client-transfer',
  REJECT_CLIENT_TRANSFER = 'reject-client-transfer',
  CREATE_GROUP_TRANSFER = 'create-group-transfer',
  LIST_GROUP_TRANSFER = 'list-group-transfer',
  APPROVE_GROUP_TRANSFER = 'approve-group-transfer',
  REJECT_GROUP_TRANSFER = 'reject-group-transfer',
  CREATE_LOAN_OFFICER_TRANSFER = 'create-loan-officer-transfer',
  LIST_LOAN_OFFICER_TRANSFER = 'list-loan-officer-transfer',
  APPROVE_LOAN_OFFICER_TRANSFER = 'approve-loan-officer-transfer',
  REJECT_LOAN_OFFICER_TRANSFER = 'reject-loan-officer-transfer',
  CREATE_BRANCH_TRANSFER = 'create-branch-transfer',
  LIST_BRANCH_TRANSFER = 'list-branch-transfer',
  APPROVE_BRANCH_TRANSFER = 'approve-branch-transfer',
  REJECT_BRANCH_TRANSFER = 'reject-branch-transfer',

  //Accounting Module Permissions
  CREATE_GL_ACCOUNT = 'create-gl-account',
  EDIT_GL_ACCOUNT = 'edit-gl-account',
  APPROVE_GL_ACCOUNT = 'approve-gl-account',
  REJECT_GL_ACCOUNT = 'reject-gl-account',
  VIEW_JOURNAL = 'view-journals',
  VIEW_REPORTS = 'view-reports',
  VIEW_GL_ACCOUNTS = 'view-gl-accounts',
  VIEW_GL_TRANSACTIONS = 'view-gl-transactions',
  BACKDATE_JOURNAL_POSTING = 'backdate-journal-posting',
  LIST_JOURNAL_TRANSACTION = 'list-journal-transaction',
  REVERSE_JOURNAL_TRANSACTION = 'reverse-journal-transaction',
  CREATE_JOURNAL_TRANSACTION = 'create-journal-transaction',
  APPROVE_JOURNAL_TRANSACTION = 'approve-journal-transaction',
  REJECT_JOURNAL_TRANSACTION = 'reject-journal-transaction',
  CREATE_POSTING = 'create-posting',
  CREATE_ENTRY_CLOSURE = 'create-entry-closure',
  LIST_ENTRY_CLOSURE = 'list-entry-closure',
  APPROVE_ENTRY_CLOSURE = 'approve-entry-closure',
  DISABLE_ENTRY_CLOSURE = 'disable-entry-closure',
  REJECT_ENTRY_CLOSURE = 'reject-entry-closure',
  EDIT_CHART_OF_ACCOUNT = 'edit-chart-of-account',

  //Update Module Permissions
  CREATE_SYSTEM_UPDATE = 'create-system-update',
  EDIT_SYSTEM_UPDATE = 'edit-system-update',

  //Report Module Permissions
  RUN_CLIENT_REPORTS = 'run-client-reports',
  RUN_LOAN_REPORTS = 'run-loan-reports',
  RUN_DEPOSIT_REPORTS = 'run-deposit-reports',
  RUN_GROUP_REPORTS = 'run-group-reports',
  RUN_ORGANIZATION_REPORTS = 'run-organization-reports',

  //Support Module Permissions
  CREATE_SUPPORT_CHAT = 'create-support-chat',
  VIEW_SUPPORT_MODULE = 'view-support-module',

  //Franchisee Module Permissions
  CREATE_NEW_FRANCHISEE = 'create-new-franchisee',
  IS_FRANCHISEE = 'is-franchisee',

  //Other Permissions
  CAN_VIEW_OTHER_AREAS = 'can-view-other-areas',
  CAN_VIEW_OTHER_BRANCHES = 'can-view-other-branches',
  CAN_VIEW_OTHER_LOANOFFICERS = 'can-view-other-loanOfficers',
  RUN_YEAR_END_ENTRY_CLOSURE = 'run-year-end-entry-closure',
}
