import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'chevronLeft',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/chevronLeft.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chevronRight',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/chevronRight.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/cross.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/info.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'percentage',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/percentage.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/wallet.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'organization',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/organization.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'area',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/areaSetting.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'organized',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/organized.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'invoice',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/invoice.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'signature',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/signature.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'beach',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/beach.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'switch',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/switch.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'roles',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/roles.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'language',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/language.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'lock',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/lock.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chartPie',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/chart-pie.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'report',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/report.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'clients',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/clients.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'hierarchy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/hierarchy.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'loan',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/loan.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'walletOutline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/wallet.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'staff',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/staff.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'users',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/users.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'transfer',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/transfer.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'accounting',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/accounting.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/settings.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'support',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/support.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/logout.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'bell',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/side-bar/bell.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_switch',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/user_switch.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_star',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/user-star-avatar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'hand_money',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/hand-money.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'money',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/money.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'money_refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/money-refresh.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'money_wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/money-wallet.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'money_card',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/money-card.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrow_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/previous-arrow.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'arrow_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/forward-arrow.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par1',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par1.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par7',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par7.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par30',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par30.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par60',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par60.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par90',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par90.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par180',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par180.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'par180Plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/par180Plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'parAll',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/dashboard/parAll.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'hand-write-off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/hand-write-off.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'fullscreen',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/fullscreen.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'danger',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/danger.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'passwordExpiry',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/icons/passwordExpiry.svg'
      )
    );
  }
}
