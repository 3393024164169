import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import {
  ChartFilterControlsInterface,
  InitDisplayedCol,
  LineChartSetup,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import moment from 'moment';
import { UtilityService } from 'src/app/utils/utility.service';
import {
  Subject,
  forkJoin,
  takeUntil,
  merge,
  mergeAll,
  switchMap,
  take,
} from 'rxjs';

interface Summary {
  today: [
    {
      totalDisbursementAsOfToday: number;
      totalRepaymentAsOfToday: number;
    }
  ];
  range: [
    {
      totalDisbursement: number;
      totalRepayment: number;
    }
  ];
}

@Component({
  selector: 'app-daily-repayment-disbursement',
  templateUrl: './daily-repayment-disbursement.component.html',
  styleUrls: ['./daily-repayment-disbursement.component.css'],
})
export class DailyRepaymentDisbursementComponent implements OnInit {
  @Input() startDate = `${moment().subtract(6, 'days').format('YYYY-MM-DD')}`;
  @Input() endDate = this.utils.formatDate_custom_yearFirst(new Date());

  @Input() areaId: any;
  @Input() branchId: any;
  @Input() loanOfficerId: any;
  @Input() productId: any;

  public startDateRange = {
    min: new Date('01/01/2016'),
    max: new Date(),
  };
  public endDateRange = {
    min: new Date(this.startDateRange.max),
    max: new Date(),
  };

  public reportsInput = ReportInputTypes;

  public chartSetup: LineChartSetup = {
    xAxis: {
      label: $localize`:@@date:Date`,
      value: 'date',
    },
    yAxis: [
      {
        label: $localize`:@@repayment:Repayment`,
        color: '#4d5d76',
        value: 'dailyRepayment',
        useAverage: true,
      },
      {
        label: $localize`:@@disbursement:Disbursement`,
        color: '#CC2C2C',
        value: 'dailyDisbursement',
      },
    ],
  };

  public columns: InitDisplayedCol[] = [
    {
      name: '',
      value: 'date',
      type: 'text',
      key: 'date',
      border: 'r',
      maxLen: 40,
    },
    {
      name: $localize`:@@repayment:Repayment`,
      value: 'repayment',
      type: 'currency',
      key: 'repayment',
      border: 'r',
    },
    {
      name: $localize`:@@disbursement:Disbursement`,
      value: 'disbursement',
      type: 'currency',
      key: 'disbursement',
    },
  ];

  payload;

  currentData = {
    startDate: '',
    endDate: '',
    totalDisbursement: 0,
    totalRepayment: 0,
  };

  public form: FormGroup;

  public hideFilters: boolean = false;
  public isLoading: boolean = false;
  dailyDisbursementChartData?: { x: string; y: number; y1: number }[] = [];
  public dailyDisbursementForm: FormGroup;
  public dailyDisbursementFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.START_DATE, type: 'START_DATE', position: 'left' },
    { name: ReportInputTypes.END_DATE, type: 'END_DATE', position: 'left' },
    {
      name: ReportInputTypes.LOAN_PRODUCT,
      type: 'LOAN_PRODUCT',
      position: 'right',
    },
  ];

  public subject = new Subject<number>();
  public summary: Summary;

  constructor(
    private topFilterService: TopFilterService,
    private operationalService: OperationalIndicatorService,
    public utils: UtilityService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.dateListner();
    this.getDailyDisbursementChartData();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.START_DATE]: new FormControl(
        `${moment().subtract(6, 'days').format('YYYY-MM-DD')}`,
        [Validators.required]
      ),
      [ReportInputTypes.END_DATE]: new FormControl(new Date(), [
        Validators.required,
      ]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [
        Validators.required,
      ]),
    });
  }

  public setHideFilters(val: boolean) {
    this.hideFilters = val;
    if (val) {
      this.form.get(ReportInputTypes.START_DATE).disable();
      this.form.get(ReportInputTypes.END_DATE).disable();
      this.form.get(ReportInputTypes.LOAN_PRODUCT).disable();
    } else {
      this.form.get(ReportInputTypes.START_DATE).enable();
      this.form.get(ReportInputTypes.END_DATE).enable();
      this.form.get(ReportInputTypes.LOAN_PRODUCT).enable();
    }
  }
  public get productCtrl() {
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl;
  }

  public dateListner() {
    this.startDateRange.max = new Date(
      this.form.get(ReportInputTypes.END_DATE).value
    );
    this.endDateRange.min = new Date(
      this.form.get(ReportInputTypes.START_DATE).value
    );
  }

  getDailyDisbursementChartData() {
    merge(
      this.topFilterService.topFilterForm.get(ReportInputTypes.AREA)
        .valueChanges,
      this.topFilterService.topFilterForm.get(ReportInputTypes.BRANCH)
        .valueChanges,
      this.topFilterService.topFilterForm.get(ReportInputTypes.LOAN_OFFICER)
        .valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          const { areaId, branchId, loanOfficerId } =
            this.topFilterService.topFilterForm.value;
          const { productId } = this;
          const queryParams = new URLSearchParams({
            startDate: this.startDate,
            endDate: this.endDate,
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(productId && { productId }),
          });
          return forkJoin({
            ChartData:
              this.operationalService.getRepaymentVsDisbursement(queryParams),
            summaryData:
              this.operationalService.getRepaymentVsDisbursementTotal(
                queryParams
              ),
          });
        })
      )
      .subscribe({
        next: ({ ChartData, summaryData }) => {
          this.dailyDisbursementChartData = ChartData.data.data;
          this.summary = summaryData.data;
          this.isLoading = false;
        },
      });
  }

  filterDailyRepayment(ev: any) {
    switch (ev.type) {
      case this.reportsInput.START_DATE:
        this.dateListner();
        this.startDate = this.utils.formatDate_custom_yearFirst(ev.value);
        break;
      case this.reportsInput.END_DATE:
        this.dateListner();
        this.endDate = this.utils.formatDate_custom_yearFirst(ev.value);
        break;
      case this.reportsInput.LOAN_PRODUCT:
        this.productId = ev.value;
        break;
    }
    this.subject.next(Math.random());
  }

  setCurrentData(param: any[]) {
    if (!param || !param.length) return;
    const startDate = param[0].date;
    const endDate = param[param.length - 1].date;
    const { totalDisbursement, totalRepayment } = param.reduce(
      (acc, p) => {
        acc.totalDisbursement += p.dailyDisbursement;
        acc.totalRepayment += p.dailyRepayment;
        return acc;
      },
      { totalDisbursement: 0, totalRepayment: 0 }
    );
    this.currentData = {
      startDate,
      endDate,
      totalDisbursement,
      totalRepayment,
    };
  }
}
