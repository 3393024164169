import { Component, OnInit } from '@angular/core';
import { UserActivityService } from 'src/app/pages/dashboard/services/user-activity.service';
import { InitDisplayedCol } from 'src/app/shared/models/common';

@Component({
  selector: 'app-logged-in-users',
  templateUrl: './logged-in-users.component.html',
  styleUrls: ['./logged-in-users.component.css'],
})
export class LoggedInUsersComponent implements OnInit {
  public isLoading: boolean = false;
  public payload;
  public total = 0;
  public columns: InitDisplayedCol[] = [
    {
      name: 'Name',
      value: 'fullName',
      type: 'text',
      key: 'fullName',
      border: 'r',
      maxLen: 200,
    },
    {
      name: 'Time',
      value: 'lastLogin',
      type: 'dateTime',
      key: 'lastLogin',
      
    },
    
  ];

  constructor(private service: UserActivityService) {}

  ngOnInit(): void {
    this.fetchLoggedInUsers();
  }


  fetchLoggedInUsers() {
    this.isLoading = true;
    this.service.loggedInUser().subscribe((res) => {
      this.payload = res.sort((a, b)=>{
        const date1 = new Date(a.lastLogin).getTime();
        const date2 = new Date(b.lastLogin).getTime();
        return date2 - date1;
      });
      this.isLoading = false;
      this.total = res.length;
     
    });
  }
}
