<div class="row p-2">
  <!-- First Column -->
  <div class="col-lg-4 p-3">
    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@product_label">Product</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@interest_rate_label">Interest Rate</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p>
        <strong i18n="@@interest_compounding_label"
          >Interest Compounding</strong
        >
      </p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@interest_posting_label">Interest Posting</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>
  </div>

  <!-- Second Column -->
  <div class="col-lg-4 p-3 seperator">
    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@product_label">Product</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@interest_rate_label">Interest Rate</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p>
        <strong i18n="@@interest_compounding_label"
          >Interest Compounding</strong
        >
      </p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@interest_posting_label">Interest Posting</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>
  </div>

  <!-- Third Column -->
  <div class="col-lg-4 p-3">
    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@product_label">Product</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@interest_rate_label">Interest Rate</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p>
        <strong i18n="@@interest_compounding_label"
          >Interest Compounding</strong
        >
      </p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>

    <div class="flex justify-content-between align-items-base">
      <p><strong i18n="@@interest_posting_label">Interest Posting</strong></p>
      <p i18n="@@group_savings">Group Savings</p>
    </div>
  </div>
</div>
