import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EMPTY, catchError, map, merge, switchMap } from 'rxjs';
import { CardType, ReportInputTypes } from 'src/app/shared/models/common';
import { SummaryCardsService } from '../services/summary-cards.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { TopFilterService } from '../services/topFIlter.service';
import { CardTypeKey } from 'src/app/pages/dashboard/summary-cards/card-localization-service';
import { CardLocalizationService } from 'src/app/pages/dashboard/summary-cards/card-localization-service';
import { CardName } from 'src/app/pages/dashboard/summary-cards/card-localization-service';

import moment from 'moment';

// enum CardTypeKey {
//   client = 'clients',
//   disbursedLoans = 'disbursedLoans',
//   amountDisbursed = 'amountDisbursed',
//   runningLoans = 'runnningLoans',
//   loansOutstanding = 'loansOutstanding',
//   depositBalance = 'depositBalance',
//   totalRepayment = 'totalRepayment',
//   par = 'par',
// }
// enum CardName {
//   approvedClient = 'Approved Client',
//   activeClient = 'Active Client',
//   disbursedLoans = 'Disbursed Loans',
//   amountDisbursed = 'Amount Disbursed',
//   runningLoans = 'Running Loans',
//   loansOutstanding = 'Loans Outstanding',
//   depositBalance = 'Deposit Balance',
//   totalRepayment = 'Total Repayment',
//   par1 = 'Par > 1',
//   par7 = 'Par > 7',
//   par30 = 'Par > 30',
//   par60 = 'Par > 60',
//   par90 = 'Par > 90',
//   par180 = 'Par > 180',
//   par180Plus = 'Par 180Plus',
//   totalPar = 'Total Par',
// }

interface Cards {
  [CardTypeKey.client]: CardType<CardName>[];
  [CardTypeKey.disbursedLoans]: CardType<CardName>[];
  [CardTypeKey.amountDisbursed]: CardType<CardName>[];
  [CardTypeKey.runningLoans]: CardType<CardName>[];
  [CardTypeKey.loansOutstanding]: CardType<CardName>[];
  [CardTypeKey.depositBalance]: CardType<CardName>[];
  [CardTypeKey.totalRepayment]: CardType<CardName>[];
  [CardTypeKey.totalRepayment]: CardType<CardName>[];
  [CardTypeKey.par]: CardType<CardName>[];
}

@Component({
  selector: 'app-summary-cards',
  templateUrl: './summary-cards.component.html',
  styleUrls: ['./summary-cards.component.css'],
})
export class SummaryCardsComponent implements OnInit {
  @ViewChild('caroulWrapper') caroulWrapper: ElementRef<HTMLDivElement>;
  public topFiltersFormInstance: FormGroup; 

  constructor(
    private service: SummaryCardsService,
    private utils: UtilityService,
    private topFilterService: TopFilterService,
    private cardLocalizationService: CardLocalizationService
  ) {
    this.topFiltersFormInstance = this.topFilterService.topFilterForm;
  }

  public cards: Cards = {
    [CardTypeKey.client]: [
      {
        name: CardName.approvedClient,
        value: '0',
        isFetching: false,
        pipeType: 'number',
        icon: 'user_star',
      },
      {
        name: CardName.activeClient,
        value: '0',
        pipeType: 'number',
        isFetching: false,
        icon: 'user_star',
      },
    ],
    [CardTypeKey.disbursedLoans]: [
      {
        name: CardName.disbursedLoans,
        value: '0',
        pipeType: 'number',
        isFetching: false,
        icon: 'hand_money',
      },
    ],
    [CardTypeKey.amountDisbursed]: [
      {
        name: CardName.amountDisbursed,
        value: '0',
        pipeType: 'currency',
        isFetching: false,
        icon: 'money',
      },
    ],
    [CardTypeKey.runningLoans]: [
      {
        name: CardName.runningLoans,
        value: '0',
        pipeType: 'number',
        isFetching: false,
        icon: 'money_refresh',
      },
    ],
    [CardTypeKey.loansOutstanding]: [
      {
        name: CardName.loansOutstanding,
        value: '0',
        pipeType: 'currency',
        isFetching: false,
        icon: 'hand_money',
      },
    ],
    [CardTypeKey.depositBalance]: [
      {
        name: CardName.depositBalance,
        value: '0',
        pipeType: 'currency',
        isFetching: false,
        icon: 'money_wallet',
      },
    ],
    [CardTypeKey.totalRepayment]: [
      {
        name: CardName.totalRepayment,
        value: '0',
        pipeType: 'currency',
        isFetching: false,
        icon: 'money_card',
      },
    ],
    [CardTypeKey.par]: [
      {
        name: CardName.par1,
        value: '0',
        pipeType: 'percent',
        isFetching: false,
        icon: 'par1',
      },
      // {
      //   name: CardName.par7,
      //   value: '0',
      //   pipeType: 'percent',
      //   isFetching: false,
      //   icon: 'par7',
      // },
      {
        name: CardName.par30,
        value: '0',
        pipeType: 'percent',
        isFetching: false,
        icon: 'par30',
      },
      {
        name: CardName.par60,
        value: '0',
        pipeType: 'percent',
        isFetching: false,
        icon: 'par60',
      },
      {
        name: CardName.par90,
        value: '0',
        pipeType: 'percent',
        isFetching: false,
        icon: 'par90',
      },
      {
        name: CardName.par180,
        value: '0',
        pipeType: 'percent',
        isFetching: false,
        icon: 'par180',
      },
      // {
      //   name: CardName.par180Plus,
      //   value: '0',
      //   pipeType: 'percent',
      //   isFetching: false,
      //   icon: 'par180Plus',
      // },
      // {
      //   name: CardName.totalPar,
      //   value: '0',
      //   pipeType: 'percent',
      //   isFetching: false,
      //   icon: 'parAll',
      // },
    ],
  };

  public startDateRange = {
    min: new Date('01/01/2016'),
    max: new Date(),
  };
  public endDateRange = {
    min: new Date(this.startDateRange.max),
    max: new Date(),
  };

  public slideConfig = {
    slidesToShow: 7.5,
    slidesToScroll: 7.5,
    infinite: false,
    centerPadding: '10px',
    arrows: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 7.5,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  fetchDetails() {
    merge(this.topFiltersFormInstance.valueChanges)
      .pipe(
        switchMap(() => {
          const { areaId, branchId, loanOfficerId, startDate, endDate } =
            this.topFiltersFormInstance.value;
          this.setAllCardsLoading();

          const queryParams = new URLSearchParams({
            startDate: this.utils.formatDate_custom_yearFirst(startDate),
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
          });
          return merge(

            this.service
              .getTotalActiveAndApprovedClients(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.client]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getDisbursedLoans(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.disbursedLoans]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getAmountDisbursed(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.amountDisbursed]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getRunningLoans(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.runningLoans]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getOustandingLoans(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.loansOutstanding]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getDepositBalance(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.depositBalance]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getTotalRepayment(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.totalRepayment]: val })),
                catchError(error => {

                  return EMPTY;
                })),
            this.service
              .getLoanProductPar(queryParams)
              .pipe(map((val) => ({ [CardTypeKey.par]: val })),
                catchError(error => {

                  return EMPTY;
                }))
          );
        })
      )
      .subscribe({
        next: (type) => {
          this.handleRes(type);
        },
        complete: () => {
        },
        error:()=>{
        }
      });
  }
  ngOnInit(): void {
    this.fetchDetails();
    this.topFiltersFormInstance.get(ReportInputTypes.END_DATE).setValue(new Date());
    this.localizeCardNames();
  }
 
  private localizeCardNames(): void {
    Object.keys(this.cards).forEach((key) => {
      this.cards[key].forEach((card: any) => {
        card.name = this.cardLocalizationService.getLocalizedCardName(card.name);
      });
    });
  }

 



  prev(){
    const currentLeftPost = this.caroulWrapper.nativeElement.scrollLeft;
    
    this.caroulWrapper.nativeElement.scrollTo({
      top: 0,
      left: currentLeftPost + (-300),
      behavior: 'smooth',
    })
  }
  next(){

    const currentLeftPost = this.caroulWrapper.nativeElement.scrollLeft;
    
    this.caroulWrapper.nativeElement.scrollTo({
      top: 0,
      left: 300 + currentLeftPost,
      behavior: 'smooth',
    })
  }

  setAllCardsLoading() {
    for (let val in this.cards) {
      this.cards[val].forEach((item) => {
        item.isFetching = true;
      });
    }
  }



  private handleRes(type) {
    if (type[CardTypeKey.client]) {
      const res = type[CardTypeKey.client];
      const activeClients = this.cards[CardTypeKey.client].find(
        (val) => val.name === CardName.activeClient
      );
      const approvedClients = this.cards[CardTypeKey.client].find(
        (val) => val.name === CardName.approvedClient
      );
      activeClients.value = res.data.activeClients;
      approvedClients.value = res.data.approvedClients;
      activeClients.isFetching = false;
      approvedClients.isFetching = false;
    }
    if (type[CardTypeKey.disbursedLoans]) {
      const res = type[CardTypeKey.disbursedLoans];
      const disbursedLoans = this.cards[CardTypeKey.disbursedLoans].find(
        (val) => val.name === CardName.disbursedLoans
      );

      disbursedLoans.value = res.data.disbursedLoans;
      disbursedLoans.isFetching = false;
    }
    if (type[CardTypeKey.amountDisbursed]) {
      const res = type[CardTypeKey.amountDisbursed];
      const amountDisbursed = this.cards[CardTypeKey.amountDisbursed].find(
        (val) => val.name === CardName.amountDisbursed
      );

      amountDisbursed.value = res.data.amountDisbursed || 0;
      amountDisbursed.isFetching = false;
    }
    if (type[CardTypeKey.runningLoans]) {
      const res = type[CardTypeKey.runningLoans];
      const runningLoans = this.cards[CardTypeKey.runningLoans].find(
        (val) => val.name === CardName.runningLoans
      );

      runningLoans.value = res.data.runningLoans || 0;
      runningLoans.isFetching = false;
    }
    if (type[CardTypeKey.loansOutstanding]) {
      const res = type[CardTypeKey.loansOutstanding];
      const loansOutstanding = this.cards[CardTypeKey.loansOutstanding].find(
        (val) => val.name === CardName.loansOutstanding
      );

      loansOutstanding.value = res.data.loansOutstanding || 0;
      loansOutstanding.isFetching = false;
    }
    if (type[CardTypeKey.depositBalance]) {
      const res = type[CardTypeKey.depositBalance];
      const depositBalance = this.cards[CardTypeKey.depositBalance].find(
        (val) => val.name === CardName.depositBalance
      );

      depositBalance.value = res.data.depositBalance || 0;
      depositBalance.isFetching = false;
    }
    if (type[CardTypeKey.totalRepayment]) {
      const res = type[CardTypeKey.totalRepayment];
      const totalRepayment = this.cards[CardTypeKey.totalRepayment].find(
        (val) => val.name === CardName.totalRepayment
      );

      totalRepayment.value = res.data.totalRepayment || 0;
      totalRepayment.isFetching = false;
    }
    if (type[CardTypeKey.par]) {
      const res = type[CardTypeKey.par];
      const par1 = this.cards[CardTypeKey.par].find(
        (val) => val.name === CardName.par1
      );


      par1.value = res.data.loanProductPar1 || 0;
      par1.isFetching = false;
      // const par7 = this.cards[CardTypeKey.par].find(
      //   (val) => val.name === CardName.par7
      // );

      // par7.value = res.data.loanProductPar7 || 0;
      // par7.isFetching = false;
      const par30 = this.cards[CardTypeKey.par].find(
        (val) => val.name === CardName.par30
      );

      par30.value = res.data.loanProductPar30 || 0;
      par30.isFetching = false;
      const par60 = this.cards[CardTypeKey.par].find(
        (val) => val.name === CardName.par60
      );

      par60.value = res.data.loanProductPar60 || 0;
      par60.isFetching = false;
      const par90 = this.cards[CardTypeKey.par].find(
        (val) => val.name === CardName.par90
      );

      par90.value = res.data.loanProductPar90 || 0;
      par90.isFetching = false;
      const par180 = this.cards[CardTypeKey.par].find(
        (val) => val.name === CardName.par180
      );
      par180.value = res.data.loanProductPar180 || 0;
      par180.isFetching = false;

      // const par180Plus = this.cards[CardTypeKey.par].find(
      //   (val) => val.name === CardName.par180Plus
      // );

      // par180Plus.value = res.data.loanProductPar180Plus || 0;
      // par180Plus.isFetching = false;

      // const totalPar = this.cards[CardTypeKey.par].find(
      //   (val) => val.name === CardName.totalPar
      // );

      // totalPar.value = res.data.totalPar || 0;
      // totalPar.isFetching = false;
    }
  }
}
