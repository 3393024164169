<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>



    <app-modal-header-ui [title]="title"></app-modal-header-ui>

    <div class="dialog-body">

        <form [formGroup]="form" class="w-[330px]">
            <mat-form-field class="w-full textarea-input" appearance="outline">
                <mat-label> {{inputTitle}} </mat-label>
                <textarea ngClass="data && data.disabled?'disabled:text-black-50': '' " required matInput [maxlength]="maxLength" formControlName="reason" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
            </mat-form-field>

            <p *ngIf="maxLength">
                {{form.get('reason')?.value?.length}}/{{maxLength}}
            </p>

            <div class="flex items-center justify-between my-2" style="gap: 10px;" *ngIf="!data || !data.disabled">
                <app-button-icon-ui [buttonType]="'button'" [classNames]="proceedVal.class" [name]="proceedVal.name"  value="proceed" (clickEmit)="onSubmit()"></app-button-icon-ui>
                <app-button-icon-ui [buttonType]="'button'" [classNames]="cancelVal.class" (clickEmit)="cancel()" [name]="cancelVal.name"  value="cancel"></app-button-icon-ui>
            </div>
        </form>

    </div>
</div>
