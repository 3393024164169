<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <app-modal-header-ui [title]="''"></app-modal-header-ui>

    <div class="dialog-body">
        <h5 class="my-6 min-h-[15px] text-xl leading-7">{{ message }}</h5>
        <div style="gap: 0 10px;" class="flex items-center justify-between mb-3">
            <app-button-icon-ui [buttonType]="'button'" [classNames]="proceedVal.class" i18n-name="@@proceed" (clickEmit)="onSubmit()" [name]="proceedVal.name" value="proceed"></app-button-icon-ui>
            <app-button-icon-ui [buttonType]="'button'" [classNames]="cancelVal.class" (clickEmit)="cancel()" i18n="@@cancel" [name]="cancelVal.name" value="cancel"></app-button-icon-ui>
        </div>
    </div>
</div>
