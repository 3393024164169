<ng-container *ngIf="showHeader">
    <div class="flex justify-between items-center px-[24px] h-12 border-t-2 border-t-gray-300">
        <p class="flex-1"></p>
        <p class="flex-1 title">{{ headerTitles[1] }}</p>
        <p class="flex-1 text-end title" [ngClass]="{'mr-[5px]': !(headerTitles[2] | valueIsNegativePipe)}"> {{ (headerTitles[2] | valueIsNegativePipe) ? '(' + (headerTitles[2] | stripNegativeSignPipe | number:'1.2-2') + ')' :  headerTitles[2] | number:'1.2-2'  }}</p>
    </div>
</ng-container>

<mat-accordion>
    <ng-container *ngIf="data?.length > 0">
      <mat-expansion-panel #panel *ngFor="let record of data; let i = index">

        <mat-expansion-panel-header>
            <p class="flex gap-[20px] flex-1">
              <img class="accordImg" src="assets/icons/plus-icon.svg" alt="" *ngIf="!panel.expanded">
              <img class="accordImg" src="assets/icons/minus-icon.svg" alt="" *ngIf="panel.expanded">
            </p>
            
            <ng-container *ngIf="!record">
              <p class="text-center text-sm" i18n="@@no_records_found">No Records</p>
            </ng-container>
            <p class="flex-1 content">{{ record?.parent?.label | titlecase }}</p>
            <p class="flex-1 text-end content" [ngClass]="{'mr-[5px]': !(record?.parent?.total | valueIsNegativePipe)}">{{ (record?.parent?.total | valueIsNegativePipe) ? '(' + (record?.parent?.total | stripNegativeSignPipe | number:'1.2-2') + ')' :  record?.parent?.total | number:'1.2-2'  }}</p>
        </mat-expansion-panel-header>
  
        <div class="panel-content flex items-center justify-between pl-10 pr-[0.45] h-8 mb-4" *ngFor="let child of record?.children">
          <p class="flex-1 content">{{ child?.glCode }}</p>
          <p class="flex-1 content">{{ child?.glAccountName }}</p>
          <p class="flex-1 text-end content" [ngClass]="{'mr-[5px]': !(child?.runningBalance | valueIsNegativePipe )}">{{ (child?.runningBalance | valueIsNegativePipe) ? '(' + (child?.runningBalance | stripNegativeSignPipe | number:'1.2-2') + ')' : child?.runningBalance | number:"1.2-2" }}</p>
        </div>
      </mat-expansion-panel>
    </ng-container>    
  </mat-accordion>
  
  <div class="flex justify-between items-center px-[24px] h-12 border-t-2 border-b-2 border-t-gray-300 border-b-gray-300" *ngIf="showFooter">
    <p class="flex-1">{{ footerTitles[0] }}</p>
    <p class="flex-1 title">{{ footerTitles[1] }}</p>
    <p class="flex-1 text-end title" [ngClass]="{'mr-[5px]': !(footerTitles[2] | valueIsNegativePipe)}">{{ (footerTitles[2] | valueIsNegativePipe) ? '(' + (footerTitles[2] | stripNegativeSignPipe | number:'1.2-2') + ')' : footerTitles[2] | number:'1.2-2'  }}</p>
  </div>