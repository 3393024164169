import { Component, OnInit } from '@angular/core';
import { Page, InitDisplayedCol } from '../../models/common';
import { DummyDataService } from '../../services/dummyData.service';

@Component({
  selector: 'app-account-charges',
  templateUrl: './account-charges.component.html',
  styleUrls: ['./account-charges.component.css']
})
export class AccountChargesComponent implements OnInit {

  public pageProp: Page = this.dummyData.dummyPage;
  public showPagination:boolean = true;
  public columns: InitDisplayedCol[] = [
    {
      name: $localize`:@@name:Name`,
      key: 'name',
      type: 'text',
      value: 'name',
    },
    {
      name: $localize`:@@type:Type`,
      key: 'type',
      type: 'text',
      value: 'type',
    },
    {
      name: $localize`:@@rate:Rate`,
      key: 'rate',
      type: 'text',
      value: 'rate',
    },
    {
      name: $localize`:@@value:Value`,
      key: 'value',
      type: 'currency',
      value: 'value',
    }

  ]
  public payload = this.dummyData.depositChargeDummyTableData
  public charges: any[] = []

  constructor(private dummyData: DummyDataService) { }

  ngOnInit(): void {
  }

  public pageSizeToggle (size:number){
  }

  public pageCtrlToggle (ctrl:string){
  }

  onSort(value) {
  }

  onSearch(value) {
  }



}
