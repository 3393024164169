import { Component, OnInit } from '@angular/core';
import { UserActivityService } from 'src/app/pages/dashboard/services/user-activity.service';
import { InitDisplayedCol } from 'src/app/shared/models/common';

@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.css'],
})
export class RecentActivityComponent implements OnInit {
  public isLoading: boolean = false;
  public payload;
  public columns: InitDisplayedCol[] = [
    {
      name: $localize`:Action|label for aciton@@action:Action`,
      value: 'event',
      type: 'text',
      key: 'event',
      border: 'r',
      maxLen: 200,
    },
    {
      name: $localize`:Made By|label for made ny@@made_by:Made By`,
      value: 'initiator_name',
      border: 'r',
      type: 'text',
      key: 'initiator_name',
    },
    {
      name: $localize`:Time|label for time@@time:Time`,
      value: 'event_time',
      type: 'dateTime',
      key: 'event_time',
    },
  ];
  constructor(private service: UserActivityService) {}

  ngOnInit(): void {
    this.fetchUserActivities();
  }

  fetchUserActivities() {
    this.isLoading = true;
    this.service.recentActivity().subscribe((res) => {
      this.payload = res.data;
      this.isLoading = false;
    });
  }
}
