<div class="L-tableUI__tableWrapper">
  <div
    *ngIf="!dataSource?.data"
    style="height: 55vh"
    class="flex justify-center items-center"
  >
    <p class="font-bold">List of records</p>
  </div>
  <div
    *ngIf="dataSource && !dataSource.data.length"
    style="height: 55vh"
    class="flex justify-center items-center"
  >
    <p class="font-bold">No Records found</p>
  </div>
  <table
    #table
    id="grid"
    *ngIf="dataSource?.data.length"
    mat-table
    [dataSource]="dataSource"
    (matSortChange)="announceSortChange($event)"
    class="L-tableUI__table"
    matSort
    [matSortActive]="matSortActive"
    [matSortDirection]="matSortDirection"
  >
    <ng-container
      *ngFor="let column of initColumns"
      [matColumnDef]="column.key"
      [sticky]="column.value === 'checkbox'"
    >
      <!-- kindly check styles for checkbox styling -->
      <ng-container *ngIf="column.type === 'checkbox'">
        <th
          class="bg-background text-lg uppercase"
          mat-header-cell
          *matHeaderCellDef
        >
          <mat-checkbox
            (change)="onCheckAll($event)"
            [checked]="masterCheckboxChecked"
            *ngIf="showCheckboxes"
          ></mat-checkbox>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <mat-checkbox
            class="pr-3"
            (change)="onCheck($event, item)"
            [checked]="item.checked"
            *ngIf="showCheckboxes"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- if checkbox with values -->
      <ng-container *ngIf="column.type === 'chkbxValue'">
        <th class="bg-background text-lg" mat-header-cell *matHeaderCellDef>
          <div class="flex justify-content-between">
            <mat-checkbox
              class="pr-3"
              (change)="onCheckAll($event)"
              [checked]="masterCheckboxChecked"
              *ngIf="showCheckboxes"
            ></mat-checkbox>
            <span>
              {{ column.name }}
            </span>
          </div>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <ng-container *ngIf="item?.isPenaltyChargeWaived; else waivedPenalty">
            <div style="text-align: right !important; color: #e04939">
              <del>{{ item[column.value] | wz_currency }}</del>
            </div>
          </ng-container>

          <ng-template #waivedPenalty>
            <div
              class="flex"
              [ngClass]="
                item?.totalPayment < item?.latePaymentPenalty
                  ? 'my_justifyBetween'
                  : 'my_alignRight'
              "
            >
              <mat-checkbox
                class="pr-3"
                (change)="onCheck($event, item)"
                [checked]="item.checked"
                *ngIf="
                  item[column.value] > 0 &&
                  item?.totalPayment < item?.latePaymentPenalty
                "
              >
              </mat-checkbox>
              <span>
                {{ item[column.value] | wz_currency }}
              </span>
            </div>
          </ng-template>
        </td>
      </ng-container>
      <!-- if repaymentIndex -->
      <ng-container *ngIf="column.type === 'repaymentIndex'">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>
          <span> # </span>
        </th>
        <!-- paid(background green) -->
        <!-- outstanding(background red) -->
        <td
          mat-cell
          [ngClass]="
            !(item.scheduledPayment - item.totalPayment)
              ? 'light-green'
              : 'light-red'
          "
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span>
            {{ item[column.value] }}
          </span>
        </td>
      </ng-container>
      <!-- if minusVal -->
      <ng-container *ngIf="column.type === 'minusValuesCurrency'">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ column.name }} </span>
        </th>
        <!-- paid(background green) -->
        <!-- outstanding(background red) -->
        <td
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span>
            {{ column.values | minusValues : item | wz_currency }}
          </span>
        </td>
      </ng-container>
      <!-- if reversal -->
      <ng-container *ngIf="column.type === 'reversal'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen | titlecase }}
            <span class="text-danger" *ngIf="item[column.isReversed] === true"
              >(reversed)</span
            >
          </span>
        </td>
      </ng-container>
      <!-- if text -->
      <ng-container *ngIf="column.type === 'text'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen | titlecase }}
          </span>
        </td>
      </ng-container>
      <!-- if email -->
      <ng-container *ngIf="column.type === 'email'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen }}
          </span>
        </td>
      </ng-container>
      <!-- if boolean -->
      <ng-container *ngIf="column.type === 'boolean'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | booleanValues }}
          </span>
        </td>
      </ng-container>
      <!-- if object access key in objectVal -->
      <ng-container *ngIf="column.type === 'object'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | findValInObject : column.objectVal }}
          </span>
        </td>
      </ng-container>
      <!-- if number, use commas -->
      <ng-container *ngIf="column.type === 'number'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | numberWithComma }}
          </span>
        </td>
      </ng-container>
      <!-- if id -->
      <ng-container *ngIf="column.type === 'id'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] }}
          </span>
        </td>
      </ng-container>
      <!-- if currency format with symbol -->
      <ng-container *ngIf="column.type === 'currency'">
        <th
          style="text-align: right !important"
          class="text-right"
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          style="text-align: right !important"
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            <ng-container *ngIf="item?.chargeType; else formatCurrency">
              <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
                {{ item[column.value] | wz_currency }}
              </span>
              <ng-template #percentFormat>
                {{ item[column.value] | wz_percentage_format_2 }}
              </ng-template>
            </ng-container>

            <ng-template #formatCurrency>
              {{ item[column.value] | wz_currency }}
            </ng-template>
          </span>
        </td>
      </ng-container>
      <!-- Percentile -->
      <ng-container *ngIf="column.type === 'percentile'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | wz_percentage_format_2 }}
          </span>
        </td>
      </ng-container>
      <!-- Date -->
      <ng-container *ngIf="column.type === 'date'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY" }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'dateTime'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY h:mm:ss a" }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'dateTimeMeridian'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY hh:mm a" }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'phone'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ item[column.value] | wz_phone_number }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'multiValue'">
        <th
          mat-sort-header
          [disabled]="!column.onSort"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-sm text-right"
          *matCellDef="let item"
          (click)="triggerRowClick(item)"
        >
          <span class="">
            {{ column.values | mergeMultiValues : item | titlecase }}
          </span>
        </td>
      </ng-container>

      <!-- if more (...) -->
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <ng-container *ngIf="!usePermission">
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </ng-container>
    <ng-container *ngIf="usePermission">
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.hidden]="!hasPermission(row.perms)"
      ></tr>
    </ng-container>
  </table>
</div>
<app-pagination-ui
  (togglePageControlEmit)="onPageCtrlToggle($event)"
  (pageSizeToggleEmit)="onPageSizeToggle($event)"
  [showPagination]="showPagination"
  [pageProp]="pageProp"
></app-pagination-ui>
