<div class="flex justify-between bg-white gap-2 items-center flex-wrap">

    <div class="flex items-center gap-2 w-full md:w-[unset]">
        <mat-form-field class="w-[48%] md:w-[110px] [&>.mat-form-field-wrapper]:pb-0 removeInputPadding " appearance="outline">
            <mat-label for="startDate">Start Date</mat-label>
            <input (dateChange)="dateListner()" [formControl]="startDateCtrl" name="startDate" [min]="startDateRange.min" [max]="startDateRange.max" readonly matInput [matDatepicker]="startDate" placeholder="Start Date" i18n-placeholder="@@start_date" />
            <mat-datepicker-toggle matSuffix [for]="startDate">
            </mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="w-[48%] md:w-[110px] [&>.mat-form-field-wrapper]:pb-0 removeInputPadding " appearance="outline">
            <mat-label for="endDate">End Date</mat-label>
            <input (dateChange)="dateListner()" [formControl]="endDateCtrl" name="endDate" [min]="endDateRange.min" [max]="endDateRange.max" readonly matInput [matDatepicker]="endDate" placeholder="End Date" i18n-placeholder="@@end_date" />
            <mat-datepicker-toggle matSuffix [for]="endDate">
            </mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="flex flex-wrap items-center gap-2 sm:mb-3">
        <div class="md:w-[100px] w-[48%] removeMatWrapperPadding">
            <app-area-search-select [disabled]="!hasPermission([permissions.CAN_VIEW_OTHER_AREAS])" (emitSelectedVal)="emitSelectedVal(reportInputTypes.AREA,$event)" [control]="getAreaCtrl" [type]="type">
            </app-area-search-select>
        </div>
        <div class="md:w-[100px] w-[48%] removeMatWrapperPadding">
            <app-branch-search-select [disabled]="!hasPermission([permissions.CAN_VIEW_OTHER_BRANCHES, permissions.CAN_VIEW_OTHER_AREAS])" (emitSelectedVal)="emitSelectedVal(reportInputTypes.BRANCH,$event)" [control]="getBranchCtrl" [type]="type"></app-branch-search-select>
        </div>
        <div class="md:w-[100px] w-[48%] removeMatWrapperPadding sm:col-span-3">
            <app-loan-officer-search-select [disabled]="!hasPermission([permissions.CAN_VIEW_OTHER_LOANOFFICERS, permissions.CAN_VIEW_OTHER_AREAS, permissions.CAN_VIEW_OTHER_BRANCHES])" (emitSelectedVal)="emitSelectedVal(reportInputTypes.LOAN_OFFICER,$event)" [control]="getLoanOfficerCtrl"
                [branchId]="selectedBranchId" [areaId]="selectedAreaId" [type]="type"></app-loan-officer-search-select>
        </div>
        <app-button-icon-ui class="w-[48%] md:w-[unset]" [buttonType]="'button'" classNames="p-3  rounded-xl border-primary text-primary hover:opacity-100 md:w-[80px] w-full md:float-right" (clickEmit)="reset()" name="Reset" value="cancel"></app-button-icon-ui>
    </div>
</div>