import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header-ui',
  templateUrl: './modal-header-ui.component.html',
  styleUrls: ['./modal-header-ui.component.css'],
})
export class ModalHeaderUiComponent {
  @Input() subtitle?: string;
  @Input() title: string = '';
  @Input() showCloseBtn: boolean = true;
  @Output() closeModal: EventEmitter<null> = new EventEmitter();

  dismiss() {
    this.closeModal.emit();
  }
  getLocalizedTitle() {
    return $localize`:@@repay_loan:Repay Loan`;
  }


getLocalizedSelectStaff(){
  return $localize`:@@select_staff:Select staff`
}
getLocalizedSelectRole(){
  return $localize`:@@select_role:Select Role`
}
getLocalizedWaiveInterest(){
  return $localize`:@@waive_interest_button:Waive Interest`
}
}
