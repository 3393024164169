import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NotificationService } from '../notification.service';

@Directive({
  selector: '[appRemoveLeadingZero]',
})
export class RemoveLeadingZeroDirective {
  constructor(
    @Self() private ngControl: NgControl,
  ) {}

  private prevVal = ''

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const value = this.ngControl.value;
    if(value?.number === this.prevVal) return;
    this.ngControl.control.setValue(this.removeLeadingZero(value?.number));
    this.prevVal = value?.number
  }
  removeLeadingZero(value: string): string {
    return value?.replace(/^0+(?=\d)/, '');
  }
}
