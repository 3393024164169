import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-icon-ui',
  templateUrl: './button-icon-ui.component.html',
  styleUrls: ['./button-icon-ui.component.css'],
})
export class ButtonIconUiComponent {
  @Input() name?: string;
  @Input() value: any;
  @Input() buttonType: 'button' | 'menu' | 'reset' | 'submit' = 'submit';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() classNames: string;
  @Input() tooltip: string;
  @Input() isDisabled: boolean = false;
  @Input() iconImage?: string;
  @Input() icon?: string;
  @Output() clickEmit: EventEmitter<any> = new EventEmitter();

  constructor() {}

// In the parent component
getLocalizedBackText() {
  return $localize`:@@back:Back`;
}

getLocalizedTooltip() {
  return $localize`:@@goBackTooltip:Go Back`;
}
getLocalizedAddnewTransaction() {
  return $localize`:@@add_new_transaction:Add New Transaction`;
}
getLocalizedAddnewTransactionTooltip() {
  return $localize`:@@add_new_transaction:Add New Transaction`;
}
  public trigger(val: any) {
    this.clickEmit.emit(val);
  }
}
