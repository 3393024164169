<!-- <button [disabled]="isDisabled" [type]="buttonType" (click)="trigger(value)" [class]=" ' flex gap-x-1.5 items-center justify-center border p-2 px-5 text-black/95 ' + classNames" mat-raised-button [matTooltip]="tooltip || '' " data-toggle="tooltip" data-placement="left">
  <mat-icon class="!w-[15px] !h-[15px] text-[15px] exclude" *ngIf="icon && iconPosition === 'left' " >{{icon}}</mat-icon>
  <span *ngIf="name">{{ name }}</span>
  <mat-icon class="!w-[15px] !h-[15px] text-[15px] exclude" *ngIf="icon && iconPosition === 'right' ">{{icon}}</mat-icon>
</button> -->

<button
  [attr.type]="buttonType"
  [ngClass]="classNames"
  [disabled]="isDisabled"
  (click)="trigger(value)"
  [attr.title]="tooltip"
  [class]=" ' flex gap-x-1.5 items-center justify-center border p-2 px-5 text-black/95 ' + classNames" mat-raised-button [matTooltip]="tooltip || '' " data-toggle="tooltip" data-placement="left"
>
  <!-- Conditionally render the icon or image on the left or right based on the iconPosition -->
  <ng-container *ngIf="iconPosition === 'left'">
    <!-- Display image if iconImage is provided -->
    <img *ngIf="iconImage" [src]="iconImage" alt="icon" class="h-3 w-3 mr-2" />
    <mat-icon class="!w-[15px] !h-[15px] text-[15px] exclude" *ngIf="icon && iconPosition === 'left' " >{{icon}}</mat-icon>
  </ng-container>

  {{ name }}

  <ng-container *ngIf="iconPosition === 'right'">
    <!-- Display image if iconImage is provided -->
    <img *ngIf="iconImage" [src]="iconImage" alt="icon" class="h-3 w-3 ml-2" />
    <mat-icon class="!w-[15px] !h-[15px] text-[15px] exclude" *ngIf="icon && iconPosition === 'right' ">{{icon}}</mat-icon>
  </ng-container>
</button>
