import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import {
  ChartFilterControlsInterface,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import { CancelHttpRequestService } from 'src/app/shared/services/cancel-request.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-disbursed-loans-count',
  templateUrl: './disbursed-loans-count.component.html',
  styleUrls: ['./disbursed-loans-count.component.css'],
})
export class DisbursedLoansCountComponent implements OnInit {
  public topFiltersFormInstance: FormGroup;
  public productId: any;
  public tenantId = JSON.parse(localStorage.getItem('pf') || null).tenantId;
  public numberOfTenantBranches: number = 0;

  public hideFilters: boolean = false;
  public showSortControl: boolean = false;
  public isLoading: boolean = false;
  public disbursedLoanCountPalette: string[] = [
    '#4d5d76',
    '#93A1B1',
    '#C0C7D1',
    '#E5E8EC',
  ];

  public disbursedLoanCountForm: FormGroup;
  public disbursedLoanCountFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    {
      name: ReportInputTypes.LOAN_PRODUCT,
      type: 'LOAN_PRODUCT',
      position: 'right',
    },
  ];
  public disbursedLoanCountChartData?: { name: string; value: number }[] = [];
  public disbursedLoanCountSortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };
  public subject = new Subject<number>();

  public form: FormGroup;

  public reportsInput = ReportInputTypes;

  public sortLabel = $localize`:Sort|label for sort@@sort:Sort`;

  public sortOptions = [
    {
      label: $localize`:Default|label for default@@default:Default`,
      value: 'default',
    },
    {
      label: $localize`:Value accending|label for value acending@@value_acending:Value acending`,
      value: 'value-asc',
    },
    {
      label: $localize`:Value decending|label for value decending@@"value_decending:Value decending`,
      value: 'value-desc',
    },
    {
      label: $localize`:Name accending|label for name acending@@name_acending:Name acending`,
      value: 'name-asc',
    },
    {
      label: $localize`:Name decending|label for name decending@@name_decending:Name decending`,
      value: 'name-desc',
    },
  ];

  constructor(
    private operationalIndicatorService: OperationalIndicatorService,
    private topFilterService: TopFilterService,
    private utils: UtilityService,
    private cancelRequestService: CancelHttpRequestService,
    private fb: FormBuilder
  ) {
    this.topFiltersFormInstance = this.topFilterService.topFilterForm;
  }

  ngOnInit(): void {
    this.initForm();
    this.getDisbursedLoansCountChartData();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.SORT]: new FormControl(`default`, [
        Validators.required,
      ]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [
        Validators.required,
      ]),
    });
  }

  public get productCtrl() {
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl;
  }

  onSort(sortOption: any) {
    this.sortLabel = sortOption.label;
    this.filterDisbursedLoansCount({
      value: sortOption.value,
      name: ReportInputTypes.SORT,
      type: ReportInputTypes.SORT,
    });
  }

  getDisbursedLoansCountChartData() {
    merge(
      this.topFilterService.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          const { areaId, branchId, loanOfficerId, startDate, endDate } =
            this.topFiltersFormInstance.value;
          const { productId, tenantId } = this;

          const queryParams = new URLSearchParams({
            startDate: this.utils.formatDate_custom_yearFirst(startDate),
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(tenantId && { tenantId }),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(productId && { productId }),
          });

          this.isLoading = true;
          return this.operationalIndicatorService.getDisbursedLoansCount(
            queryParams
          );
        })
      )
      .subscribe((res) => {
        this.disbursedLoanCountChartData = res.data.data.map((item: any) => ({
          name: item.branchName,
          value: item.disbursedCount,
        }));

        this.isLoading = false;
        this.numberOfTenantBranches =
          res.data.total[0].numberOfBranchesForTenant;
        if (this.disbursedLoanCountChartData?.length < 2) {
          this.showSortControl = false;
        } else {
          this.showSortControl = true;
        }
      });
  }

  filterDisbursedLoansCount(ev: any) {
    switch (ev.type) {
      case ReportInputTypes.SORT:
        const selectedString =
          ev.value === 'default' ? 'name-default' : ev.value;
        const [key, sortingDirection] = selectedString.split('-');
        this.disbursedLoanCountSortingOptions = { key, sortingDirection };
        break;
      case ReportInputTypes.LOAN_PRODUCT:
        this.productId = ev.value;
        this.subject.next(Math.random());
        break;
    }
  }

  fetchDetails() {
    this.topFiltersFormInstance.valueChanges.subscribe(() => {
      this.cancelRequestService.cancelPendingRequest();
      this.getDisbursedLoansCountChartData();
    });
  }
}
