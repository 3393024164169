<div class="sm:hidden md:block relative px-5 py-2 my-1 bg-white shadow-2">
  <div
    class="flex overflow-auto hide-scrollbar items-center xxl:gap-[1.69%] gap-[20px]"
    #caroulWrapper
  >
    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.clients"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.disbursedLoans"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.amountDisbursed"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.runnningLoans"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.loansOutstanding"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.depositBalance"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.totalRepayment"
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center xxl:w-[11%]"
      [items]="cards.par"
    ></app-card-ui>
  </div>
  <ng-container>
    <button
      (click)="prev()"
      class="cursor-pointer rounded-full absolute font-bold left-0 top-[40%] xxl:invisible"
    >
      <span
        nz-icon
        class="text-[18px] align-middle text-primary"
        nzType="left-circle"
        nzTheme="fill"
      ></span>
    </button>
    <button
      (click)="next()"
      class="cursor-pointer rounded-full absolute font-bold right-0 top-[40%] xxl:invisible"
    >
      <span
        nz-icon
        class="text-[18px] align-middle text-primary"
        nzType="right-circle"
        nzTheme="fill"
      ></span>
    </button>
  </ng-container>
</div>

<div class="md:hidden px-5 py-2 my-1 bg-white shadow-2">
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
  >
    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.clients"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.disbursedLoans"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.amountDisbursed"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.runnningLoans"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.loansOutstanding"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.depositBalance"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.totalRepayment"
      ngxSlickItem
    ></app-card-ui>

    <app-card-ui
      class="!flex !justify-center"
      [items]="cards.par"
      ngxSlickItem
    ></app-card-ui>
  </ngx-slick-carousel>
</div>
