<h3 class="text-primary" i18n="@@accounting_information">Accounting Information</h3>
<div id="accounting_area" class="flex flex-col mt-[5px] mb-[23px] p-[12px] border-[1px] border-[#D5D5D5] rounded-[10px]">
    <div class="flex gap-[20px] mb-[20px]">
        <app-button-icon-ui [isDisabled]="isDisabled" [buttonType]="'button'" [classNames]="getButtonClass('ACCRUAL')" (clickEmit)="switchAccountType($event)" i18n-name="@@accrual_based" name="Accrual Based" value="ACCRUAL" *ngIf="product === 'loan'"></app-button-icon-ui>
        <app-button-icon-ui [isDisabled]="isDisabled" [buttonType]="'button'" [classNames]="getButtonClass('CASH')" (clickEmit)="switchAccountType($event)" i18n-name="@@cash_based" name="Cash Based" value="CASH"></app-button-icon-ui>
    </div>
    <div class="flex flex-col gap-[20px]" cdkDropListGroup>
        <div *ngFor="let cat of accountingCategories; let a = index">
            <h3 class="text-primary font-semibold mb-[5px]">{{cat.value.name}}</h3>
            <div class="accounting-list">
                <div class="flex gap-[15px] justify-end mb-[20px]">
                    <!-- <mat-form-field appearance="outline" class="short_height w-[153px]">
                        <mat-label i18n="@@transaction_name">Transaction Name</mat-label>
                        <input [disabled]="isDisabled" type="text" matInput [(ngModel)]="newTransactionNames[cat.key]" #transactionInput (input)="onInputTransactionName(cat.key, transactionInput.value)"/>
                    </mat-form-field> -->
                    <app-button-icon-ui [isDisabled]="isDisabled || !isContainCharge(cat.key)" [name]="getLocalizedAddnewTransaction()" [value]="cat.key" [icon]="'plus'" (clickEmit)="addTransaction($event)" [classNames]="'text-secondary rounded-[5px] bg-white hover:bg-secondary hover:text-white border-[#D5D5D5] h-[30px]'"
                        [tooltip]="getLocalizedAddnewTransaction()"></app-button-icon-ui>
                </div>
                <div class="flex gap-[10px] mb-[10px]">
                    <div class="w-[10px]"></div>
                    <div class="w-[148px]"></div>
                    <div class="flex gap-[15px]">
                        <div class="w-[217px] text-center font-semibold">
                            <mat-label i18n="@@debit">Debit</mat-label>
                        </div>
                        <div class="w-[217px] text-center font-semibold">
                            <mat-label i18n="@@credit">Credit</mat-label>
                        </div>
                        <div class="w-[217px] text-center font-semibold">
                            <mat-label i18n="@@event">Event</mat-label>
                        </div>
                        <!-- <div class="w-[170px] text-center font-semibold"><mat-label>Mapping</mat-label></div> -->
                    </div>
                    <div class="w-[25px]"></div>
                </div>
                <div cdkDropList [cdkDropListData]="cat.value.row" (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="cat.key === 'fees_charges' ? noDropPredicate : dropPredicate" [attr.id]="cat.key" class="flex flex-col gap-[5px]">
                    <div *ngIf="cat.value.row.length === 0" class="empty-drop-zone" cdkDrag>
                        <!-- Placeholder element for an empty drop zone -->
                        <mat-label class="italic text-primary" i18n="@@no_data">No Data</mat-label>
                    </div>
                    <div class="flex gap-[10px]" *ngFor="let row of cat.value.row; let i = index" cdkDrag [cdkDragDisabled]="!!row.chargeId">
                        <div [ngClass]="{'accounting-handle': cat.key !== 'fees_charges'}" class="h-[15px] w-[10px] mt-[8px]" cdkDragHandle>
                            <img *ngIf="cat.key !== 'fees_charges'" src="assets/icons/drag-drop-icon.svg" alt="">
                        </div>
                        <!-- <div class="w-[148px]"><mat-label>{{row.label}}</mat-label></div> -->
                        <div class="flex-col justify-end w-[20.81%]">
                            <mat-form-field appearance="outline" class="short_height w-[100%]" [ngClass]="{'highlight-mat-input': oldAccountingCategories.length > 0 && row.label !== oldAccountingCategories[a].value.row[i]?.label}">
                                <mat-label i18n="@@transaction_name">Transaction Name</mat-label>
                                <input [disabled]="isDisabled || !!row.chargeId || !!row.latePaymentChargeId" type="text" matInput appRemoveSpaces appTitleCase [(ngModel)]="row.label" />
                                <!-- <input [disabled]="isDisabled" type="text" matInput [(ngModel)]="row.label" #transactionInput #transactionInput (blur)="onInputTransactionName(row, transactionInput.value)"/> -->
                            </mat-form-field>
                            <small *ngIf="oldAccountingCategories.length > 0 && row.label !== oldAccountingCategories[a].value.row[i]?.label" class="flex justify-end px-2 opacity-80">{{ oldAccountingCategories[a].value.row[i]?.label }}</small>
                        </div>

                        <div class="flex gap-[10px] w-[71.61%]">
                            <!-- Debit input -->
                            <div class="flex-col justify-end w-[32.34%]">
                                <mat-form-field appearance="outline" class="w-[100%] short_height" [ngClass]="{'highlight-mat-input': oldAccountingCategories.length > 0 && row.debitGlId !== oldAccountingCategories[a].value.row[i]?.debitGlId}">
                                    <mat-select [disabled]="isDisabled || eventWithDefaultDebit(row)" [(ngModel)]="row.debitGlId" (selectionChange)="updateDebitAndCreditGl('debit', row, $event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search ngModel (ngModelChange)="filterDebitGlOptions($event, row)" [placeholderLabel]="'Find record..'" i18n-placeholderLabel noEntriesFoundLabel="'No match found'" i18n-noEntriesFoundLabel></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngIf="eventWithDefaultDebit(row)" [value]="0">SELECTED GL</mat-option>
                                        <mat-option [value]="glAccount.id" *ngFor="let glAccount of row.debitGlAccountList | filterGlAccounts:row.creditGlId">{{glAccount.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <small *ngIf="oldAccountingCategories.length > 0 && row.debitGlId !== oldAccountingCategories[a].value.row[i]?.debitGlId" class="flex justify-end px-2 opacity-80" [matTooltip]="row.debitGlAccountList |filterGlAccountName: oldAccountingCategories[a].value.row[i]?.debitGlId">{{ row.debitGlAccountList |filterGlAccountName: oldAccountingCategories[a].value.row[i]?.debitGlId | truncate : 25 }}</small>
                            </div>

                            <!-- Credit input -->
                            <div class="flex-col justify-end w-[32.34%]">
                                <mat-form-field appearance="outline" class="w-[100%] short_height" [ngClass]="{'highlight-mat-input': oldAccountingCategories.length > 0 && row.creditGlId !== oldAccountingCategories[a].value.row[i]?.creditGlId}">
                                    <mat-select [disabled]="isDisabled || eventWithDefaultCredit(row)" [(ngModel)]="row.creditGlId" (selectionChange)="updateDebitAndCreditGl('credit', row, $event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search ngModel (ngModelChange)="filterCreditGlOptions($event, row)" [placeholderLabel]="'Find record..'" i18n-placeholderLabel noEntriesFoundLabel="'No match found'" i18n-noEntriesFoundLabel></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngIf="eventWithDefaultCredit(row)" [value]="0">SELECTED FUNDSOURCE</mat-option>
                                        <mat-option [value]="glAccount.id" *ngFor="let glAccount of row.creditGlAccountList | filterGlAccounts:row.debitGlId">{{glAccount.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <small *ngIf="oldAccountingCategories.length > 0 && row.creditGlId !== oldAccountingCategories[a].value.row[i]?.creditGlId" class="flex justify-end px-2 opacity-80" [matTooltip]="row.creditGlAccountList |filterGlAccountName: oldAccountingCategories[a].value.row[i]?.creditGlId">{{ row.creditGlAccountList | filterGlAccountName: oldAccountingCategories[a].value.row[i]?.creditGlId | truncate : 25 }}</small>
                            </div>

                            <!-- Event input -->
                            <div class="flex-col justify-end w-[32.34%]">
                                <mat-form-field appearance="outline" class="w-[100%] short_height" [ngClass]="{'highlight-mat-input': oldAccountingCategories.length > 0 && row.eventName !== oldAccountingCategories[a].value.row[i]?.eventName}">
                                    <mat-select [disabled]="isDisabled || isLatePaymentCharge(row.eventName, row.eventMapping) " [(ngModel)]="row.eventName" (selectionChange)="onMappingNameChange(row, $event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search ngModel (ngModelChange)="filterEventsOptions($event, row)" [placeholderLabel]="'Find record..'" i18n-placeholderLabel noEntriesFoundLabel="'No match found'" i18n-noEntriesFoundLabel></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="name" *ngFor="let name of row.eventNameList">{{name | underScoreToSpace | Title_Case }}</mat-option>
                                        <!-- <mat-option [value]="name" *ngFor="let name of row.eventNameList | underScoreToSpace | Title_Case ">{{name}}</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                                <small *ngIf="oldAccountingCategories.length > 0 && row.eventName !== oldAccountingCategories[a].value.row[i]?.eventName" class="flex justify-end px-2 opacity-80">{{ oldAccountingCategories[a].value.row[i]?.eventName | underScoreToSpace | Title_Case }}</small>
                            </div>

                            <!-- Mapping input -->
                            <!-- <div class="flex-col justify-end">
                                <mat-form-field appearance="outline" class="w-[160px] short_height" [ngClass]="{'highlight-mat-input': oldAccountingCategories.length > 0 && row.eventMapping !== oldAccountingCategories[a].value.row[i]?.eventMapping}">
                                    <mat-select [disabled]="isDisabled || isLatePaymentCharge(row.eventName, row.eventMapping)" [(ngModel)]="row.eventMapping">
                                        <mat-option>
                                            <ngx-mat-select-search ngModel (ngModelChange)="filterMappingOptions($event, row)" [placeholderLabel]="'Find record..'" i18n-placeholderLabel noEntriesFoundLabel="'No match found'" i18n-noEntriesFoundLabel></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="mapping" *ngFor="let mapping of row.eventMappingList | underScoreToSpace | Title_Case ">{{mapping}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <small *ngIf="oldAccountingCategories.length > 0 && row.eventMapping !== oldAccountingCategories[a].value.row[i]?.eventMapping" class="flex justify-end px-2 opacity-80">{{ oldAccountingCategories[a].value.row[i]?.eventMapping }}</small>
                            </div> -->

                        </div>
                        <button id="bin-image-btn" [disabled]="isDisabled || notSiblingCharge(row) || !!row.latePaymentChargeId || isDefaultDisbursement(row)" (click)="removeTransaction(cat.key, i)" class="cursor-pointer bg-white hover:bg-[#CC2C2C] border-[1px] border-[#CC2C2C] rounded-[5px] py-[5px] px-[7px] w-[30px] h-[28px]">
                            <img id="bin-image" src="assets/icons/delete-bin.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
