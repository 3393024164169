import { Injectable } from '@angular/core';

export enum CardTypeKey {
  client = 'clients',
  disbursedLoans = 'disbursedLoans',
  amountDisbursed = 'amountDisbursed',
  runningLoans = 'runnningLoans',
  loansOutstanding = 'loansOutstanding',
  depositBalance = 'depositBalance',
  totalRepayment = 'totalRepayment',
  par = 'par',
}

export const cardLanguageTitle = {
  approvedClient: {
    en: 'Approved Client',
    fr: 'Client Approuvé',
    es: 'Cliente aprobado',
    pt: 'Cliente aprovado',
    de: 'Goedgekeurde klant',
  },
  activeClient: {
    en: 'Active client',
    fr: 'Client Actif',
    es: 'Cliente Activo',
    pt: 'Cliente Ativo',
    de: 'Activeringsdatum',
  },
  disbursedLoans: {
    en: 'Disbursed Loans',
    fr: 'Prêts Déboursés',
    es: 'Préstamos Desembolsados',
    pt: 'Empréstimos Desembolsados',
    de: 'Uitgekeerde leningen',
  },
  amountDisbursed: {
    en: 'Amount Disbursed',
    fr: 'Montant Déboursé',
    es: 'Monto Desembolsado',
    pt: 'Valor Desembolsado',
    de: 'Uitgekeerd bedrag',
  },
  runningLoans: {
    en: 'Running Loans',
    fr: 'Prêts en Cours',
    es: 'Préstamos en Curso',
    pt: 'Empréstimos em Andamento',
    de: 'lopende leningen',
  },
  loansOutstanding: {
    en: 'Loans Outstanding',
    fr: 'Prêts en Souffrance',
    es: 'Préstamos Pendientes',
    pt: 'Empréstimos Pendentes',
    de: 'Uitstaande leningen',
  },
  depositBalance: {
    en: 'Deposit Balance',
    fr: 'Solde de Dépôt',
    es: 'Saldo de Depósito',
    pt: 'Saldo de Depósito',
    de: 'Stortingssaldo',
  },
  totalRepayment: {
    en: 'Total Repayment',
    fr: 'Remboursement total',
    es: 'Reembolso Total',
    pt: 'Reembolso Total',
    de: 'Totale terugbetaling',
  },
};

export enum CardName {
  approvedClient = 'Approved Client',
  activeClient = 'Active Client',
  disbursedLoans = 'Disbursed Loans',
  amountDisbursed = 'Amount Disbursed',
  runningLoans = 'Running Loans',
  loansOutstanding = 'Loans Outstanding',
  depositBalance = 'Deposit Balance',
  totalRepayment = 'Total Repayment',
  par1 = 'Par > 1',
  par7 = 'Par > 7',
  par30 = 'Par > 30',
  par60 = 'Par > 60',
  par90 = 'Par > 90',
  par180 = 'Par > 180',
  par180Plus = 'Par 180Plus',
  totalPar = 'Total Par',
}

@Injectable({
  providedIn: 'root',
})
export class CardLocalizationService {
  getLocalizedCardName(cardName: CardName): string {
    switch (cardName) {
      case CardName.approvedClient:
        return $localize`:@@approved_client:Approved Client`;
      case CardName.activeClient:
        return $localize`:@@active_client:Active Client`;
      case CardName.disbursedLoans:
        return $localize`:@@disbursed_loans:Disbursed Loans`;
      case CardName.amountDisbursed:
        return $localize`:@@amount_disbursed:Amount Disbursed`;
      case CardName.runningLoans:
        return $localize`:Ru@@running_loans:Running Loans`;
      case CardName.loansOutstanding:
        return $localize`:@@loans_outstanding:Loans Outstanding`;
      case CardName.depositBalance:
        return $localize`:@@deposit_balance:Deposit Balance`;
      case CardName.totalRepayment:
        return $localize`:@@total_repayment:Total Repayment`;
      case CardName.par1:
        return $localize`:@@par_1:Par > 1`;
      case CardName.par7:
        return $localize`:@@par_7:Par > 7`;
      case CardName.par30:
        return $localize`:Par > 30|label for par > 30@@par_30:Par > 30`;
      case CardName.par60:
        return $localize`:Par > 60|label for par > 60@@par_60:Par > 60`;
      case CardName.par90:
        return $localize`:Par > 90|label for par > 90@@par_90:Par > 90`;
      case CardName.par180:
        return $localize`:Par > 180|label for par > 180@@par_180:Par > 180`;
      case CardName.par180Plus:
        return $localize`:Par 180Plus|label for par 180Plus@@par_180_plus:Par 180Plus`;
      case CardName.totalPar:
        return $localize`:Total Par|label for total par@@total_par:Total Par`;
      default:
        return cardName;
    }
  }
}
