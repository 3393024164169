import { SafeUrl } from '@angular/platform-browser';
import { Permissions } from '../dto/permissions';
// date limit
export interface DateLimit {
  start: Date;
  end: Date;
  startAge?: Date;
  endAge?: Date;
}

export interface CardType<T>{
  name: T,
  value: string,
  pipeType: 'currency' | 'number' | 'percent',
  isFetching: boolean,
  icon: string,
  iconClass?: string
}

export interface ClientReportRequest extends PageRequest {
  type?: string;
}

export interface FilterType {
  startDate?: Date | string;
  endDate?: Date | string;
  date?: Date | string;
  report?: string;
  area?: { id: number; name: string };
  branch?: { id: number; name: string };
  loanOfficer?: { id: number; name: string };
  loanProduct?: { id: number; name: string };
  depositProduct?: { id: number; name: string };
  client?: { id: number; first_name: string, last_name: string  };
  depositClient?: { id: number; clientName: string  };
  group?: { id: number; name: string };
  limit?: string;
  loanStatus?: any;
  accountNumber?: any;
  state?: string;
}

//Page Requests
export interface PageRequest {
  page: number;
  pageSize: number;
  state: StateTypes['value'];
  sortField: string;
  sortOrder: string;
  filter?: string;
  franchiseeId?: number;
  topClientBy?: string;
  unPaged?: boolean;
  date?: string;
  startDate?: string;
  endDate?: string;
  id?: string;
  auditType?: 'ClientSavingsAccountAudit' | 'ClientLoanAccountAudit' | 'ClientAudit'
}

//Page Requests
export interface ReportPageRequest {
  page: number;
  pageSize: number;
  state?: string;
  status?: string;
  sortField?: string;
  sortOrder?: string;
  filter?: string;
  franchiseeId?: number;
  unPaged?: boolean;
  date?: string;
  startDate?: string;
  sortBalance?: string;
  endDate?: string;
  type?: string;
}

//Transfer Requests
export interface TransferRequest extends PageRequest {
  areaId?: string;
}

export interface PostingRequest extends PageRequest {
  startDate: string;
  endDate: string;
  branchId?: number;
  areaId?: number;
  loanOfficerId?: number;
}

export interface ChartOfAccountRequest extends PageRequest {
  type?: any;
  accountUsage?: any;
  manualJournalEntries?: any;
}
export interface EntryClosureRequest extends PageRequest {
  endDate?: string;
  startDate?: string;
  createdBy?: string;
}
export interface LoanReportRequest extends PageRequest {
  endDate?: string;
  startDate?: string;
  createdBy?: string;
  type?: string;
}
//Custom Requests for Loan
export interface LoanPageRequest {
  page: number;
  pageSize: number;
  state: StateTypes['value'];
  loanState?: StateTypes['value'];
  sortField: string;
  sortOrder: string;
  filter?: string;
  franchiseeId?: number;
}
//Page Requests
export interface PageRequestNoState {
  page: number;
  pageSize: number;
  sortField: string;
  sortOrder: string;
  filter?: string;
  franchiseeId?: number;
}
export interface ClientPageRequest {
  page?: number;
  offset: number;
  limit: number;
  state: string;
  'sort-field': string;
  'sort-order': string;
  franchiseeId?: number;
}

export interface Pagination {
  totalElements: number;
  totalPages: number;
  page: number;
  pageSize: number;
  last: false;
  first: true;
}

// Module actions
export interface ActionButtonType {
  name: string;
  value:
    | 'approve'
    | 'modify'
    | 'modify2'
    | 'decline'
    | 'reject'
    | 'enable'
    | 'disable'
    | 'delete'
    | 'activate'
    | 'assign-branches'
    | 'self-disburse'
    | 'approve-loans'
    | 'disburse-loans'
    | 'run'
    | 'cancel'
    | 'reverse'
    | 'repay'
    | 'waive'
    | 'edit'
    | 'writeOff'
    | 'deposit'
    | 'withdrawal'
    | 'collapse';
  className?: string;
  children?: ActionButtonType[];
  perms: string[];
  visibility?: boolean;
}

// Module states
export interface StateTypes {
  name: string;
  value:
    | 'approved'
    | 'pending'
    | 'pendingEdited'
    | 'editing'
    | 'rejected'
    | 'declined'
    | 'disabled'
    | 'enabled'
    | 'active'
    | 'inactive'
    | 'cancelled'
    | 'cancelledLoan'
    | 'groups'
    | 'clients'
    | 'loans'
    | 'savings'
    | 'summary'
    | 'charges'
    | 'transactions'
    | 'resolved'
    | 'unresolved'
    | 'transfer'
    | 'new_group_posting'
    | 'repayment_schedule'
    | 'document'
    | 'guarantor'
    | 'audits'
    | 'basic'
    | 'ALIMIT'
    | 'BDLIMIT'
    | string;
  perms: string[];
  isActive?: boolean;
}

// Menu
export interface Menu {
  label: string;
  link?: string;
  active: boolean;
  icon?: string;
  children?: Array<SubMenu>;
  perm?: string[];
}

interface SubMenu {
  label: string;
  active: boolean;
  link: string;
  perm: string[];
}

// Table
export type DisplayColTypes =
  | 'checkbox'
  | 'text'
  | 'boolean'
  | 'object'
  | 'number'
  | 'currency'
  | 'currency_percentile'
  | 'percentile'
  | 'date'
  | 'dateTime'
  | 'customDateSelect'
  | 'customSelectPicker'
  | 'customGlAccountSelectPicker'
  | 'dateTimeMeridian'
  | 'phone'
  | 'multiValue'
  | 'email'
  | 'id'
  | 'repaymentIndex'
  | 'reversal'
  | 'minusValuesCurrency'
  | 'sumValues'
  | 'chkbxValue'
  | 'customInput'
  | 'btn'
  | 'actionBtn'
  | 'checkIcon'
  | 'numberWithDecimal'
  | 'enum';

export interface InitDisplayedCol {
  name: string;
  key: string;
  type: DisplayColTypes;
  value: any;
  perms?: Permissions[];
  footer?: any;
  objectVal?: string | string[];
  values?: string[];
  maxLen?: number;
  onSort?: boolean;
  toolIcon?: boolean;
  onFilter?: boolean;
  isReversed?: any;
  header?: string;
  tooltip?: string;
  border?: 'l' | 'r' | 'x';
  class?: string; // e.g 'd-none w-100 px-3', can be space seperated
}

// Pagination

export interface Pagination {
  firstPage: string;
  lastPage: string;
  nextPage: string;
  pageNumber: number;
  pageSize: number;
  previousPage: string;
  totalPages: number;
  totalRecords: number;
}

export interface Page {
  firstPage: string;
  lastPage: string;
  from?: number;
  to?: number;
  nextPage: string;
  pageNumber: number;
  pageSize: number;
  previousPage: string;
  totalPages: number;
  totalRecords: number;
  pageRequest?: PageRequest;
}
export type PageCtrl = 'prev' | 'first' | 'last' | 'next';

export interface PageCtrls {
  prev: boolean;
  next: boolean;
  first: boolean;
  last: boolean;
}

// Breadcrumb

export interface BreadCrumbLinkType {
  name: string;
  routeTo?: string;
}

// Title

export interface TitleActionBtnType {
  name?: string;
  value: string;
  type: 'btn' | 'menu';
  icon?: string;
  classNames?: string;
  tooltip?: string;
  clickType?: string;
  iconClassNames?: string;
  children?: TitleActionBtnType[];
  perms: string[];
  active?: boolean;
  navStatus?: 'active' | 'not-active'
}

// KeyValuePerm

export interface KeyValuePermType {
  key: string;
  value: string;
  type?: 'currency';
  selected?: boolean;
  perms: string[]
}


// KeyValue

export interface KeyValueType {
  key: string;
  value: string;
  type?: 'currency';
}

// Image-Upload
export interface FileDetail {
  name?: string;
  size?: number;
  file: File | string;
  type?: string;
  url: any;
}

// ID & NAME

export interface IdNameType {
  id: number;
  name: string;
  extra?: {
    id: number;
    name: string;
  };
}

// Item

export interface ItemType {
  title: string | number;
  subtitle?: string;
}
export interface reportSummaryType {
  label: string;
  type: 'currency' | 'number' | 'date';
  value: any;
}

// MultiButton
export interface MultiButtonType {
  name: string;
  classNames: string;
  value: string;
  perms: string[];
}

// Reports Input Type
export enum ReportInputTypes {
  INACTIVE_SINCE = 'inactive_since',
  START_DATE = 'startDate',
  DATE = 'date',
  END_DATE = 'endDate',
  REPORT = 'report',
  AREA = 'areaId',
  BRANCH = 'branchId',
  GROUP = 'groupId',
  LIMIT = 'limit',
  LOAN_OFFICER = 'loanOfficerId',
  LOAN_PRODUCT = 'productId',
  CLIENT_STATUS = 'status',
  LOAN_STATUS = 'loanStatus',
  DEPOSIT_PRODUCT = 'depositProductId',
  CLIENT = 'clientId',
  DEPOSIT_CLIENT = 'depositClientId',
  ACCOUNT_NUMBER = 'accountNumber',
  STATE = 'state',
  SORT = 'sort',
  PORTFILIO_AT_RISK = 'portfolio',
}

// Searchable Select Input Type
export interface SearchableSelectTypes {
  label: string;
  value: any;
  fundSources?: any[];
}
// move to dependencies
// Account Officer
export interface LoanOfficerType {
  id: number;
  name: string;
  extra: { id: number; name: string };
}

// Response Object
export interface Res {
  code: number;
  message: string;
  successful: boolean;
}

export interface API<T>{
  code: number;
  message: string;
  successful: boolean;
  data: T
}
// Pagination Response
export interface PaginationRes {
  total: number;
  page: number;
  pageSize: number;
  totalElements: number;
  last: false;
  first: true;
}

// Approve, Reject, Enable, Disable Action Payload
export interface TogglePayload {
  ids?: number[];
  id?: number;
  remark?: string;
  reason?: string;
  enable?: boolean;
  setMultipleRunningLoans?: boolean;
  message?: string;
  accountId?: number;
  amount?: number;
  state?: StateTypes['value'];
  disbursementDate?;
  firstRepaymentDate?;
}

export interface CancelLoanPayload {
  ids: number[];
  remark?: string;
  reason?: string;
  accountId?: number;
}

export interface SignatoryOpts {
  name: string;
  value: string;
}
export interface Sign {
  name: string;
  value: string;
  id: number;
}
export interface Signature {
  id: number;
  signs: Sign[];
}

export interface AccountingCategory {
  name: string;
  row: AccountingTransaction[];
}

export interface AccountingTransaction {
  eventName: string;
  eventNameList: string[];
  debitGlId: number;
  creditGlId: number;
  creditGlAccountList: any[];
  debitGlAccountList: any[];
  debitGlName: string;
  creditGlName: string;
  eventMapping: string;
  eventMappingList: string[];
  label: string;
  groupLabel: string;
  chargeId?: number
  latePaymentChargeId?: number
  siblingCharge?: boolean
}

export interface ChartFilterControlsInterface {
  name: ReportInputTypes,
  type: keyof typeof ReportInputTypes,
  position: 'right' | 'left'
  value?: any
}

export interface LineChartSetup {
  xAxis: {
    label: string,
    value: string,
  },
  yAxis: 
    {
      label: string,
      color: string,
      value: string,
      useAverage?: boolean,
    }[]
    
  ,
}

export interface Closure {
  approval_state: 'APPROVED' | 'REJECTED' | 'PENDING',
  initiator: string,
  treatedBy: string,
  treated_on: string,
  reserve_account_id: number,
  reserveAccount: string,
  initiator_id: number,
  closure_year: string,
  last_update_time: string,
  treated_by_id: number,
  id: number,
  transaction_year: string,
  initiated_on: string
}