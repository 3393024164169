import { Component, OnInit } from '@angular/core';
import { Page, InitDisplayedCol } from '../../models/common';
import { DummyDataService } from '../../services/dummyData.service';

@Component({
  selector: 'app-account-guarantors',
  templateUrl: './account-guarantors.component.html',
  styleUrls: ['./account-guarantors.component.css']
})
export class AccountGuarantorsComponent implements OnInit {

  public pageProp: Page = this.dummyData.dummyPage;
  public showPagination:boolean = true;
  public columns: InitDisplayedCol[] = [
    {
      name: $localize`:@@first_name:First Name`,
      key: 'first_name',
      type: 'text',
      value: 'first_name',
    },
    {
      name: $localize`:@@last_name:Last Name`,
      key: 'last_name',
      type: 'text',
      value: 'last_name',
    },
    {
      name: $localize`:@@email_label:Email`,
      key: 'email',
      type: 'text',
      value: 'email',
    },
    {
      name: $localize`:@@phone_number_label:Phone Number`,
      key: 'phone_number',
      type: 'text',
      value: 'phone_number',
    },
    {
      name: $localize`:@@photoLabel:Photo`,
      key: 'photo',
      type: 'text',
      value: 'photo',
    },
    {
      name: $localize`:@@idCardLabel:ID Card`,
      key: 'id_card',
      type: 'text',
      value: 'id_card',
    },
    {
      name: $localize`:@@signature:Signature`,
      key: 'signature',
      type: 'text',
      value: 'signature',
    }

  ]

  public payload = this.dummyData.depositTransactionsDummyTableData
  public documents: any[] = []

  constructor(private dummyData: DummyDataService) { }

  ngOnInit(): void {

  }


  public pageSizeToggle (size:number){

  }

  public pageCtrlToggle (ctrl:string){

  }

  onSort(value) {
  }

  onSearch(value) {
  }

}
