import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CHART_TITLES, ChartLocalizationService } from './localization-service';

@Component({
  selector: 'app-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrls: ['./chart-wrapper.component.css'],
})
export class ChartWrapperComponent {
  @Input() title: keyof typeof CHART_TITLES;
  @Input() total: string | number = 1000;
  @Input() showTotal: boolean = false;
  @Input() showTotalCount: boolean = false;
  @Input() showExpandIcons: boolean = true;
  @Input() showValuePercentToggle: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() wrapperClass: string;
  @Input() filterClass: string = 'px-4 pt-2 chart-input text-sm mt-auto';
  @Input() chartClass: string = 'px-3 text-sm';
  @Input() tableClass: string;

  @Output() fullscreen: EventEmitter<boolean> = new EventEmitter(false);
  @Output() switch: EventEmitter<'value' | 'percentage'> = new EventEmitter();
  @ViewChild('wrapper') wrapper: ElementRef;

  public isFullScreen: boolean = false;
  originalParent: any;

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    private ChartLocalizationService: ChartLocalizationService
  ) {}

  get localizedTitle(): string {
    return this.ChartLocalizationService.getLocalizedCHARTName(this.title);
  }

  openCustomFullScreen() {
    // Store the original parent
    this.originalParent = this.wrapper.nativeElement.parentNode;

    const overlay = document.createElement('div');
    overlay.classList.add('wrapper-overlay');
    document.body.appendChild(overlay);

    const element = this.wrapper.nativeElement;
    element.classList.add('my-custom-fullscreen');
    document.body.appendChild(element);
    this.isFullScreen = true;
    this.fullscreen.emit(this.isFullScreen);
  }

  exitCustomFullScreen() {
    const overlay = document.querySelector('.wrapper-overlay');
    const element = this.wrapper.nativeElement;

    if (overlay) {
      document.body.removeChild(overlay);
      // Move the element back to its original parent
      const originalParent = this.originalParent; // Assuming you stored the original parent reference
      originalParent.appendChild(element);
    }

    element.classList.remove('my-custom-fullscreen');
    // document.body.appendChild(element);
    this.isFullScreen = false;
    this.fullscreen.emit(this.isFullScreen);
  }

  openFullScreen() {
    const element = this.wrapper.nativeElement;
    if (element.requestFullscreen) element.requestFullscreen();
    else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
    else if (element.msRequestFullscreen) element.msRequestFullscreen();
    this.isFullScreen = true;
    this.fullscreen.emit(this.isFullScreen);
  }

  exitFullScreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    // else if (document?.mozCancelFullScreen) document?.mozCancelFullScreen();
    // else if (document?.webkitExitFullscreen) document?.webkitExitFullscreen();
    this.isFullScreen = false;
    this.fullscreen.emit(this.isFullScreen);
  }

  togglePercentOrValue(ev: any) {
    this.switch.emit(ev.checked ? 'percentage' : 'value');
  }
}
