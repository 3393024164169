import { Injectable } from '@angular/core';

export enum CardTypeKey {
    client = 'clients',
    disbursedLoans = 'disbursedLoans',
    amountDisbursed = 'amountDisbursed',
    runningLoans = 'runnningLoans',
    loansOutstanding = 'loansOutstanding',
    depositBalance = 'depositBalance',
    totalRepayment = 'totalRepayment',
    par = 'par',
  }

export enum CardName {
    approvedClient = 'Approved Client',
    activeClient = 'Active Client',
    disbursedLoans = 'Disbursed Loans',
    amountDisbursed = 'Amount Disbursed',
    runningLoans = 'Running Loans',
    loansOutstanding = 'Loans Outstanding',
    depositBalance = 'Deposit Balance',
    totalRepayment = 'Total Repayment',
    par1 = 'Par > 1',
    par7 = 'Par > 7',
    par30 = 'Par > 30',
    par60 = 'Par > 60',
    par90 = 'Par > 90',
    par180 = 'Par > 180',
    par180Plus = 'Par 180Plus',
    totalPar = 'Total Par',
  }

@Injectable({
  providedIn: 'root',
})
export class CardLocalizationService {
  getLocalizedCardName(cardName: CardName): string {
    switch (cardName) {
      case CardName.approvedClient:
        return $localize`:Approved Client|label for approved client@@approved_client:Approved Client`;
      case CardName.activeClient:
        return $localize`:Active Client|label for active client@@active_client:Active Client`;
      case CardName.disbursedLoans:
        return $localize`:Disbursed Loans|label for disbursed loans@@disbursed_loans:Disbursed Loans`;
      case CardName.amountDisbursed:
        return $localize`:Amount Disbursed|label for amount disbursed@@amount_disbursed:Amount Disbursed`;
      case CardName.runningLoans:
        return $localize`:Running Loans|label for running loans@@running_loans:Running Loans`;
      case CardName.loansOutstanding:
        return $localize`:Loans Outstanding|label for loans outstanding@@loans_outstanding:Loans Outstanding`;
      case CardName.depositBalance:
        return $localize`:Deposit Balance|label for deposit balance@@deposit_balance:Deposit Balance`;
      case CardName.totalRepayment:
        return $localize`:Total Repayment|label for total repayment@@total_repayment:Total Repayment`;
      case CardName.par1:
        return $localize`:Par > 1|label for par > 1@@par_1:Par > 1`;
      case CardName.par7:
        return $localize`:Par > 7|label for par > 7@@par_7:Par > 7`;
      case CardName.par30:
        return $localize`:Par > 30|label for par > 30@@par_30:Par > 30`;
      case CardName.par60:
        return $localize`:Par > 60|label for par > 60@@par_60:Par > 60`;
      case CardName.par90:
        return $localize`:Par > 90|label for par > 90@@par_90:Par > 90`;
      case CardName.par180:
        return $localize`:Par > 180|label for par > 180@@par_180:Par > 180`;
      case CardName.par180Plus:
        return $localize`:Par 180Plus|label for par 180Plus@@par_180_plus:Par 180Plus`;
      case CardName.totalPar:
        return $localize`:Total Par|label for total par@@total_par:Total Par`;
      default:
        return cardName; // Fallback to the original string
    }
  }
}