<div
  class="border-b border-primary bg-white p-5 z-20 flex justify-between items-center"
>
  <img src="assets/images/new-images/logo-2.png" class="w-[60px]" />

  <h2 class="font-semibold text-xl text-center">{{ title }}</h2>

  <div class="flex gap-4">
    <h3 *ngIf="subtitle" class="border-2 border-gray-300 p-2 rounded-lg">
      {{ subtitle }}
    </h3>
    <mat-icon
      mat-dialog-close
      class="text-red-500 text-3xl cursor-pointer"
      (click)="dismiss()"
      *ngIf="showCloseBtn"
      >highlight_off</mat-icon
    >
  </div>
</div>
