import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountingCategory, SearchableSelectTypes } from '../../models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { DepositProductService } from 'src/app/pages/settings/services/deposit-product.service';
import { WizerValidatorService } from 'src/app/utils/wizer.validators.service';
import { DragDropListComponent } from '../../components/drag-drop-list/drag-drop-list.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-new-view-deposit-product',
  templateUrl: './new-view-deposit-product.component.html',
  styleUrls: ['./new-view-deposit-product.component.css']
})
export class NewViewDepositProductComponent {

  @ViewChild(DragDropListComponent, { static: true }) dragDrop: DragDropListComponent;
  public readonly activeAccountType = 'CASH';

  public charges: any[];
  public glAccounts: any[];

  public basicForm: FormGroup;
  public interestExpenseForm: FormGroup;
  public otherInfoForm: FormGroup;

  public isViewMode: boolean = false
  public isDisabled: boolean = false;
  public isFormValid: boolean = false;
  private selectedDepositProductId: number;

  public events: { label: string, value: string }[] = [
    { label: $localize`:@@deposit:Deposit`, value: 'DEPOSIT' },
    { label: $localize`:@@withdrawal:Withdrawal`, value: 'WITHDRAWAL' }
  ];
  public eventMaps: any[] = [];
  public accountingCategories: { key: string; value: AccountingCategory }[] = [
    { key: 'deposit', value: { name: $localize`:@@deposit:Deposit`, row: [] } },
    { key: 'withdrawal', value: { name: $localize`:@@withdrawal:Withdrawal`, row: [] } },
    { key: 'fees_charges', value: { name: $localize`:@@fees_slash_charges:Fees/Charges`, row: [] } },
    { key: 'interest', value: { name: $localize`:@@interest:Interest`, row: [] } },
  ]

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialoRef: MatDialogRef<NewViewDepositProductComponent>,
    private utils: UtilityService,
    private toast: NotificationService,
    private depositProductService: DepositProductService,
    private validator: WizerValidatorService,
  ) {
    if (data) {

      this.isViewMode = data.isViewMode;
      this.charges = data.charges;
      this.eventMaps = data.eventMaps;

      if (data.accountingData) {
        const { ASSET, INCOME, EXPENSE, LIABILITY } = data.accountingData;

        // Check if each property exists before spreading them
        const assetAccounts = ASSET ? [...ASSET] : [];
        const incomeAccounts = INCOME ? [...INCOME] : [];
        const expenseAccounts = EXPENSE ? [...EXPENSE] : [];
        const liabilityAccounts = LIABILITY ? [...LIABILITY] : [];

        this.glAccounts = [...assetAccounts, ...incomeAccounts, ...expenseAccounts, ...liabilityAccounts].sort((a, b) => a.name.localeCompare(b.name));
      }
    }

    this.initForm()
    if (data && this.isViewMode) {
      const {
        id,
        name,
        code,
        description,

        overdraftLimit,
        overdraftInterestRate,
        overdraftInterestPeriodUnit,
        overdraftCompoundingPeriodUnit,
        overdraftInterestPostingPeriodUnit,
        overdraftInterestCalculationType,

        interestRate,
        interestRatePeriodUnit,
        compoundingPeriodUnit,
        interestPostingPeriodUnit,
        interestCalculationType,

        savingsProductCharges,
        minimumOpeningBalance,
        transferLimit,
        dailyTransferLimit,
        roundingMultiple,

        savingsProductAccountings
      } = data.depositProduct

      this.selectedDepositProductId = id
      this.basicForm.patchValue({ name, code, description, overdraftLimit });
      this.interestExpenseForm.patchValue({
        interestRate,
        interestPeriodUnit: interestRatePeriodUnit,
        compoundingPeriodUnit,
        interestPostingPeriodUnit,
        interestCalculationType,

        overdraftInterestRate,
        overdraftInterestPeriodUnit,
        overdraftCompoundingPeriodUnit,
        overdraftInterestPostingPeriodUnit,
        overdraftInterestCalculationType
      })
      this.otherInfoForm.patchValue({
        chargeIds: savingsProductCharges?.map(charges => charges?.charge?.id),
        openingBalance: minimumOpeningBalance,
        transferLimit,
        dailyTransferLimit,
        roundingMultiple
      })

      // Group the deposirProductAccounting objects by groupLabel
      const groupedData = savingsProductAccountings.reduce((result, obj) => {
        if (!result[obj.groupLabel]) {
          result[obj.groupLabel] = [];
        }
        result[obj.groupLabel].push(obj);
        return result;
      }, {});



      // Map the grouped data to accountingCategories and format eventName
      const updatedAccountingCategories = this.accountingCategories.map(category => {
        const matchingGroup = groupedData[category.value.name];

        if (matchingGroup) {
          // Format eventName to title case for each row
          category.value.row = matchingGroup.map(row => {
            const mappingNames = this.eventMaps.map(item => item.mapping_name);
            const selectedMapping = this.eventMaps.find(item => item.mapping_name.toLowerCase() === row.eventName.toLowerCase());

            return {
              ...row,
              eventName: `${this.utils.underScoreToSpace(row.eventName)}-${this.utils.underScoreToSpace(row.eventMapping)}`,
              eventMapping: this.utils.titleCase(this.utils.underScoreToSpace(row.eventMapping)),
              eventMappingList: selectedMapping.mappings.split(', ') ?? [],
              // eventNameList: mappingNames ?? [],
              eventNameList: selectedMapping.mappings.split(', ') ?? [],
              creditGlAccountList: this.glAccounts,
              debitGlAccountList: this.glAccounts
            };
          });
        }

        return category;
      });



      // Assign the updated categories back to accountingCategories
      this.accountingCategories = updatedAccountingCategories;

      this.disableAllFields();
    }
  }

  initForm() {
    this.initBasicForm();
    this.initInterestExpenseForm();
    this.initOtherInfoForm();
  }

  initBasicForm() {
    this.basicForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      overdraftLimit: new FormControl('', Validators.required),
    });
  }

  initInterestExpenseForm() {
    this.interestExpenseForm = this.fb.group({
      interestRate: new FormControl('', [Validators.required, Validators.pattern(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/)]),
      interestPeriodUnit: new FormControl('MONTH', [Validators.required]),
      compoundingPeriodUnit: new FormControl('MONTH', [Validators.required]),
      interestPostingPeriodUnit: new FormControl({ value: 'MONTH', disabled: true }, [Validators.required]),
      interestCalculationType: new FormControl('MINIMUM_BALANCE', [Validators.required]),

      overdraftInterestRate: new FormControl('', [Validators.required, Validators.pattern(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/)]),
      overdraftInterestPeriodUnit: new FormControl('MONTH', [Validators.required]),
      overdraftCompoundingPeriodUnit: new FormControl('MONTH', [Validators.required]),
      overdraftInterestPostingPeriodUnit: new FormControl({ value: 'MONTH', disabled: true }, [Validators.required]),
      overdraftInterestCalculationType: new FormControl('MINIMUM_BALANCE', [Validators.required])
    });
  }

  initOtherInfoForm() {
    this.otherInfoForm = this.fb.group({
      chargeIds: new FormControl([], [Validators.required]),
      openingBalance: new FormControl('', [Validators.required]),
      transferLimit: new FormControl('', [Validators.required]),
      dailyTransferLimit: new FormControl('', [Validators.required]),
      roundingMultiple: new FormControl('', [Validators.required]),
    });
  }

  invalidateMinimumBalance() {
    const overdraftValue = this.basicForm.get('overdraftLimit').value
    if(overdraftValue) {
      this.otherInfoForm.get('openingBalance').disable()
      this.otherInfoForm.get('openingBalance').removeValidators(Validators.required)
    } else {
      this.otherInfoForm.get('openingBalance').enable()
      this.otherInfoForm.get('openingBalance').addValidators(Validators.required)
    }

    this.otherInfoForm.get('openingBalance').updateValueAndValidity()
  }

  invalidateOverdraftLimit() {
    const openingBalanceValue = this.otherInfoForm.get('openingBalance').value
    if(openingBalanceValue) {
      this.basicForm.get('overdraftLimit').disable()
      this.basicForm.get('overdraftLimit').removeValidators(Validators.required)
    } else {
      this.basicForm.get('overdraftLimit').enable()
      this.basicForm.get('overdraftLimit').addValidators(Validators.required)
    }

    this.basicForm.get('overdraftLimit').updateValueAndValidity()
  }

  validateTransferLimits() {
    const transferLimit = Number(this.utils.sanitizeCurrency(this.otherInfoForm.get('transferLimit')?.value)) ?? null;
    const dailyTransferLimit = Number(this.utils.sanitizeCurrency(this.otherInfoForm.get('dailyTransferLimit')?.value)) ?? null;

    if (
      dailyTransferLimit &&
      transferLimit &&
      transferLimit > dailyTransferLimit
    ) {
      this.toast.error('Transfer limit should not be greater than Daily transfer limit');
      this.isFormValid = false;
      return;
    }

    this.isFormValid = true;
  }

  updatePostingPeriodUnit(ev: MatSelectChange) {
    this.interestExpenseForm.get('interestPostingPeriodUnit')?.patchValue(ev.value)
  }

  updateOverdraftPostingPeriodUnit(ev: MatSelectChange) {
    this.interestExpenseForm.get('overdraftInterestPostingPeriodUnit')?.patchValue(ev.value)
  }

  cancel() {
    this.dialoRef.close()
  }

  submitForm() {
    if (this.basicForm.invalid) {
      this.validator.CheckMessagesAndNotify(this.basicForm.controls);
      this.basicForm.markAllAsTouched();
      return;
    }

    if (this.interestExpenseForm.invalid) {
      this.validator.CheckMessagesAndNotify(this.interestExpenseForm.controls);
      this.interestExpenseForm.markAllAsTouched();
      return;
    }

    if (this.otherInfoForm.invalid) {
      this.validator.CheckMessagesAndNotify(this.otherInfoForm.controls);
      this.otherInfoForm.markAllAsTouched();
      return;
    }

    this.dragDrop.submit();
  }

  submit(mergedRowArray: any) {
    const payload = {
      ...this.basicForm.value,
      ...this.interestExpenseForm.getRawValue(),
      ...this.otherInfoForm.value,
      depositProductAccounting: mergedRowArray
    };

    this.sanitizePayload(payload);

    if (this.isViewMode) return this.editDepositProduct(payload);
    this.addNewDepositProduct(payload);
  }

  editDepositProduct(payload: any) {
    this.depositProductService.updateDepositProduct(this.selectedDepositProductId, payload).subscribe((result) => {
      if (result?.successful) {
        this.toast.success($localize`:@@deposit_product_updated_successfully:Deposit product updated successfully`);
        this.dialoRef.close(true);
      }
    });
  }

  addNewDepositProduct(payload: any) {
    this.depositProductService.createDepositProduct(payload).subscribe((result) => {
      if (result?.successful) {
        this.toast.success($localize`:@@deposit_product_created_successfully:Deposit product created successfully`);
        this.dialoRef.close(true);
      }
    });
  }

  sanitizePayload(payload: any) {
    payload.overdraftLimit = this.utils.sanitizeCurrency(payload?.overdraftLimit)
    payload.overdraftInterestRate = this.utils.sanitizePercentage(payload?.overdraftInterestRate)

    payload.interestRate = this.utils.sanitizePercentage(payload?.interestRate)
    payload.openingBalance = this.utils.sanitizeCurrency(payload?.openingBalance)
    payload.transferLimit = this.utils.sanitizeCurrency(payload?.transferLimit)
    payload.dailyTransferLimit = this.utils.sanitizeCurrency(payload?.dailyTransferLimit)
    payload.roundingMultiple = this.utils.sanitizeCurrency(payload?.roundingMultiple)
  }

  disableAllFields() {
    this.basicForm.disable();

    this.interestExpenseForm.disable();
    this.interestExpenseForm.get('interestPostingPeriodUnit')?.disable()
    this.interestExpenseForm.get('overdraftInterestPostingPeriodUnit')?.disable()

    this.otherInfoForm.disable();
    this.isDisabled = true;
  }

  enableAllFields() {
    this.basicForm.enable();

    this.interestExpenseForm.enable();
    this.interestExpenseForm.get('interestPostingPeriodUnit')?.disable()
    this.interestExpenseForm.get('overdraftInterestPostingPeriodUnit')?.disable()

    this.otherInfoForm.enable();
    this.isDisabled = false;
  }

  editForm(type: 'edit' | 'save') {
    if (type === 'edit') return this.enableAllFields();
    this.submitForm()
  }

  chargeIdChange(selectedIds: number[]) {
    this.dragDrop.chargeIdChange(selectedIds);
  }

}
