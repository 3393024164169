import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, map, of, BehaviorSubject } from 'rxjs';
import {
  ActionButtonType,
  ClientPageRequest,
  PageRequest,
  Res,
  TogglePayload,
} from 'src/app/shared/models/common';
import { DataService } from 'src/app/shared/services/data.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';
import {
  Client,
  ClientBasic,
  ClientBusiness,
  ClientDocuments,
  ClientIdentification,
} from '../models/client';
import { Group } from '../models/group';

interface AccountCount {
  loans: number;
  savings: number;
}

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private baseUrl: string = environment.baseUrl + environment.coreUrl;
  currentTableRequest: any;
  currentTableList: any[];
  currentItemIndex: any;

  public searchClientEndpoint = `${this.baseUrl}clients?clientType=INDIVIDUAL&page=0&pageSize=500&state=APPROVED&sortField=last_update_time&sort-order=DESC&filter=`;
  public searchClientByParamEndpoint = (param?: string) =>
    `${this.baseUrl}clients?page=0&pageSize=500${
      param ? '&' + param : ''
    }&state=APPROVED&sortField=last_update_time&sortOrder=DESC&filter=`;
  public searchDepositClientByParamEndpoint = (param?: string) =>
    `${this.baseUrl}clients/client-With-Deposit-Account?page=0&pageSize=500${
      param ? '&' + param : ''
    }&sortField=last_update_time&sortOrder=DESC&filter=`;

  private clientAccountCount = new BehaviorSubject<AccountCount>({
    loans: 0,
    savings: 0,
  });

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
    private dataService: DataService
  ) {}

  public getClientAccountCount(): Observable<AccountCount> {
    return this.clientAccountCount.asObservable();
  }
  // From Loan and Deposit Account Service
  public setClientAccountCount(type: 'savings' | 'loans', count: number): void {
    const val = { ...this.clientAccountCount.value, [type]: count };
    this.clientAccountCount.next(val);
  }

  setCurrentTableList(tableList): void {
    this.currentTableList = tableList;
  }

  setCurrentItemId(item): void {
    for (let index in this.currentTableList) {
      if (this.currentTableList[index].id === item.id) {
        this.currentItemIndex = index;
        return;
      }
    }
  }

  public previousClient(): any {
    let newItemIndex = Number(this.currentItemIndex) - 1;
    let __this = this;

    //check if this is the first item
    if (newItemIndex < 0) {
      //check if we are the very beginning
      if (this.currentTableRequest.start === 0) {
        return new Promise((resolve, reject) => {
          reject('No previous Row');
        });
      }
      this.currentTableRequest.draw = this.currentTableRequest.draw - 1;
      this.currentTableRequest.start =
        this.currentTableRequest.start - this.currentTableRequest.length;

      return new Promise((resolve, reject) => {
        this.getClients(this.currentTableRequest).subscribe((data) => {
          __this.currentItemIndex = this.currentTableRequest.length - 1;
          __this.currentTableList = data.data;

          if (this.currentTableList[__this.currentItemIndex].id) {
            resolve(this.currentTableList[__this.currentItemIndex].id);
          } else {
            reject('No previous rows');
          }
        });
      });
    } else {
      return new Promise((resolve) => {
        resolve(this.currentTableList[newItemIndex].id);
      });
    }
  }

  public nextClient(): Promise<any> {
    let newItemIndex = Number(this.currentItemIndex) + 1;
    let __this = this;

    //check if this is the last item
    if (newItemIndex === this.currentTableList.length) {
      this.currentTableRequest.draw = this.currentTableRequest.draw + 1;
      this.currentTableRequest.start =
        this.currentTableRequest.start + this.currentTableRequest.length;
      return new Promise((resolve, reject) => {
        this.getClients(this.currentTableRequest).subscribe((data) => {
          __this.currentItemIndex = 0;
          __this.currentTableList = data.data;

          if (this.currentTableList[__this.currentItemIndex].id) {
            resolve(this.currentTableList[__this.currentItemIndex].id);
          } else {
            reject('No more rows');
          }
        });
      });
    } else {
      return new Promise((resolve) => {
        resolve(this.currentTableList[newItemIndex].id);
      });
    }
  }

  public isDirectGet() {
    if (this.currentTableList) {
      return false;
    } else {
      return true;
    }
  }

  public isFirstElement() {
    return (
      Number(this.currentTableRequest.start) === 0 &&
      Number(this.currentItemIndex) === 0
    );
  }

  public postClient(data: Client) {
    return this.http.post<any>(this.baseUrl + 'clients', data);
  }

  // public updateBank(data: any) {
  //   if(!data.bvn) return of(null);

  //   return this.http.put<any>(this.baseUrl + 'clients/bank-details', data);
  // }
  public updateClient(data: Object) {
    return this.http.put<any>(this.baseUrl + 'clients/edit', data);
  }

  public getClients(queryParams: PageRequest): Observable<any> {
    // this.currentTableRequest = req;
    const payload = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(`${this.baseUrl}clients${payload}`);
  }

  public getTransferDetailList(req: any): Observable<any> {
    this.currentTableRequest = req;
    return this.dataService.getList(
      this.baseUrl + 'api/v2/internal/core/clients/transfers',
      req
    );
  }

  public getClient(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'clients/' + id);
  }
  public clientEditCompare(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'clients/' + id + '/compare');
  }

  public toggleEditedClient(id, state):Observable<any> {
    return this.http.put(`${this.baseUrl}clients/${id}/approve-edit`,{state});
  }

  public getClientByBvn(bvn: string): Observable<any> {
    return this.http.get(this.baseUrl + 'api/clients/check-for-bvn/' + bvn);
  }
  public checkBvn(bvn: string): Observable<any> {
    return this.http.get(this.baseUrl + 'clients/bvn/' + bvn);
  }

  public toggle(type: ActionButtonType['value'], data: TogglePayload) {
    if (type === 'approve') {
      return this.approveClients(data);
    }
    if (type === 'reject') {
      return this.rejectClients(data);
    }
    if (type === 'disable') {
      return this.toggleClients({ ...data, enable: false });
    }
    // enable and activate
    return this.toggleClients({ ...data, enable: true });
  }

  public approveClients(data: TogglePayload) {
    return this.http.post<any>(this.baseUrl + 'clients/approve', data);
  }

  public rejectClients(data: TogglePayload) {
    return this.http.post<any>(this.baseUrl + 'clients/reject', data);
  }

  /* enable / disable one client. */
  public toggleSingleClient(data: any): Observable<any> {
    return this.http.patch<any>(this.baseUrl + 'api/clients/status', data);
  }
  /* enable / disable clients.Takes data{'ids': [1,2,3], 'enable': bool} */
  public toggleClients(data: TogglePayload): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'clients/toggle', data);
  }

  public toggleCanAllowMultipleLoan(data: TogglePayload): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'clients/toggleMultipleLoans',
      data
    );
  }

  public getClientDocuments(id: string) {
    return this.http.get<any>(this.baseUrl + `clients/${id}/documents`);
  }
  public getClientComments(id: string, request: string) {
    return this.http.get<any>(
      this.baseUrl + `clients/${id}/comments?${request}`
    );
  }
  public addClientComment(payload: any) {
    return this.http.post<any>(this.baseUrl + `clients/comments`, payload);
  }

  public checkPhoneNumberExists(phoneNumber: string): Observable<Res> {
    return this.http.get<Res>(
      this.baseUrl + `clients/check-phone-non-exists?phoneNumber=${phoneNumber}`
    );
  }

  public getSimilarPersons(req: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `api/clients/check-for-similar-names`,
      req
    );
  }

  public postClientsTransferRequest(req: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `api/v2/internal/core/clients/transfers`,
      req
    );
  }

  public toggleTransferRequests(req: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `api/v2/internal/core/clients/transfers/toggle`,
      req
    );
  }

  /** Check if the id combination supplied already exists at the backend
   *
   * @param clientIdNumber the id number of the specified identification
   * @param id the identification type id
   * @returns {Observable<Object>} contains a code representing either success or error and also a message
   */
  public checkIdNumber(clientIdNumber: string) {
    return this.http.get<any>(
      this.baseUrl + 'api/clients/check-for-idNo/' + clientIdNumber
    );
  }

  /** This adds a new document to a client
   *
   * @param newDocumentForm
   */
  public addDocument(newDocumentForm: Object) {
    return this.http.post<any>(
      this.baseUrl + `clients/documents`,
      newDocumentForm
    );
  }

  /** delete document attached to a client
   *
   * @param documentId
   */
  // public deleteDocument(documentId: number[]) {
  //   return this.http.post<any>(
  //     this.baseUrl + `clients/removeDocument`,
  //     documentId
  //   );
  // }

  /** This adds a new identification to a client
   *
   * @param newIdentificationForm
   */
  public addIdentification(id: number, newIdentificationForm: Object) {
    return this.http.post<any>(
      this.baseUrl + `api/clients/identifications/${id}`,
      newIdentificationForm
    );
  }

  public getIndividualClientsForLoanOfficer(id: number) {
    return this.http.get<any>(
      `${this.baseUrl}api/v2/internal/core/clients/individualClientsForLoanOfficer/${id}`
    );
  }

  public getUserClients(id: number) {
    return this.http.get<any>(
      `${this.baseUrl}api/v2/internal/core/clients/userClients/${id}`
    );
  }

  private evalBasicProps(basic: ClientBasic): ClientBasic {
    const groupId = basic.clientGroupId as Group;
    let dateOfBirth = basic.dateOfBirth;
    let expiryDate = basic.expiryDate;

    if (dateOfBirth?._d) {
      dateOfBirth = this.utilityService.formatDate_custom_yearFirst(
        basic.dateOfBirth._d
      );
    }

    if (expiryDate?._d) {
      expiryDate = this.utilityService.formatDate_custom_yearFirst(
        basic.expiryDate._d
      );
    }

    const clientPhoto = basic.clientPhoto;
    const evalBasic: ClientBasic = {
      ...basic,
      clientGroupId: groupId?.id,
      dateOfBirth,
      expiryDate,
      clientPhoto,
    };

    if (evalBasic.clientType === 'INDIVIDUAL') {
      delete evalBasic.clientGroupId;
      delete evalBasic.clientGroupRole;
    }
    delete evalBasic.branch;
    delete evalBasic.loanOfficer;
    return evalBasic;
  }
  private evalIdentificationProps(
    identification: ClientIdentification
  ): ClientIdentification | {} {
    if (!identification) return {};
    const clientIdPhoto = identification.clientIdPhoto.base64;
    const evalidentification: ClientIdentification = {
      ...identification,
      clientIdPhoto,
    };
    return evalidentification;
  }
  private evalDocumentProps(documents: ClientDocuments): ClientDocuments | {} {
    if (!documents) return {};
    const clientSignaturePhoto = documents.clientSignaturePhoto.base64;

    const evalDocuments: ClientDocuments = {
      ...documents,
      clientSignaturePhoto,
    };
    return evalDocuments;
  }

  private evalBusinessProps(business: ClientBusiness): ClientBusiness {
    let businessDetailStartDate = business.businessDetailStartDate;
    if (businessDetailStartDate._d) {
      businessDetailStartDate = this.utilityService.formatDate_custom_yearFirst(
        business.businessDetailStartDate._d
      );
    }

    const clientBusinessPhoto1 = business.clientBusinessPhoto1;
    const clientBusinessPhoto2 = business.clientBusinessPhoto2;
    const clientBusinessPhoto3 = business.clientBusinessPhoto3;
    const clientBusinessPhoto4 = business.clientBusinessPhoto4;
    const clientBusinessPhoto5 = business.clientBusinessPhoto5;
    const evalBusiness: ClientBusiness = {
      ...business,
      businessDetailStartDate,
      clientBusinessPhoto1,
      clientBusinessPhoto2,
      clientBusinessPhoto3,
      clientBusinessPhoto4,
      clientBusinessPhoto5,
    };
    delete evalBusiness.businessPhoto;
    return evalBusiness;
  }

  public extractClientData(completed: any) {
    const payload: Client | any = {
      ...this.evalBasicProps(completed.basic),
      // ...completed.contact,
      // ...this.evalIdentificationProps(completed.identification),
      // ...this.evalDocumentProps(completed.documents),
      // ...completed.bank,
      ...this.evalBusinessProps(completed.business),
      // ...completed.nextOfKin,
    };
    return payload;
  }

  public getClientAudit(params: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}core/audits`, { params });
  }

  public deleteDocument(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}clients/remove-bulk-document`, {
      ids: ids,
    });
  }
}
