<app-chart-wrapper  [isLoading]="isLoading" [title]="'Running Loans'" (fullscreen)="hideFilters = $event" [showTotal]="true" [total]="total">

    <!-- Filters -->
    <form filter [formGroup]="form" class="flex gap-4 mb-6 border border-red-500" [ngClass]="showSortControl?'justify-between': 'justify-end'">
        <ng-container *ngIf="showSortControl">
            <button mat-button [matMenuTriggerFor]="sorting" class="sort-btn">
            {{ sortLabel }}
            <img class="ml-2.5 h-5" src="assets/icons/sort-order.svg" alt="" />
          </button>
            <mat-menu #sorting="matMenu">
                <button mat-menu-item *ngFor="let option of sortOptions" (click)="onSort(option)">
              {{ option.label }}
            </button>
            </mat-menu>
        </ng-container>

        <app-loan-product-search-select [disabled]="hideFilters" (emitSelectedVal)="filterRunningLoans({ type: reportsInput.LOAN_PRODUCT, value: $event?.id })" class="w-[40%]" [control]="productCtrl" type="client">
        </app-loan-product-search-select>


    </form>
    <!-- End Filters -->
    <app-circle-chart chart *ngIf="runningLoansChartData?.length === 1 && !isLoading" [width]="'50px'" [height]="'50px'" [totalAmount]="runningLoansChartData[0]?.value ?? 0" [imageName]="'money_refresh'" [classNames]="'text-primary'">
    </app-circle-chart>

    <app-no-data-to-show chart *ngIf="!runningLoansChartData?.length && !isLoading"></app-no-data-to-show>

    <!-- <app-doughnut-chart chart *ngIf="runningLoansChartData?.length > 1 && runningLoansChartData?.length < 5" [chartData]="runningLoansChartData" [palette]="runningLoansPalette"></app-doughnut-chart> -->

    <app-column-chart chart *ngIf="runningLoansChartData?.length > 1 && !isLoading" [chartData]="runningLoansChartData" [sortingOptions]="runningLoansSortingOptions">
    </app-column-chart>

</app-chart-wrapper>