<mat-form-field appearance="outline" [formGroup]="form">
  <mat-label i18n>{{ label }}</mat-label>
  <mat-select
    [disabled]="disabled"
    formControlName="value"
    (selectionChange)="setValue($event, true)"
    [multiple]="multiple"
  >
    <input
      [disabled]="disabled"
      matInput
      placeholder="Search..."
      i18n-placeholder="@@search"
      class="search-input"
      (keyup)="searchSelectField($event)"
    />
    <mat-option value="all" *ngIf="showDefaultOption">
      <ng-container i18n="@@all">All</ng-container>
    </mat-option>
    <mat-option
      *ngFor="let option of searchOptions; trackBy: byOptionId"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
