<table>
    <thead>
        <tr>
            <th i18n="@@businessType">Business Type</th>
            <th i18n="@@count">Count</th>
            <th i18n="@@percentage">Percentage</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let n of [1,2,3,4,5,6]">
            <td i18n="@@make_up_artist">Makeup Artist</td>
            <td class="text-right">1,600</td>
            <td class="text-right">4.0%</td>
        </tr>
        <tr>
            <th i18n="@@total">Total</th>
            <th class="text-right">46,000</th>
            <th class="text-right">100%</th>
        </tr>
    </tbody>
</table>