<div
  class="modal fade"
  id="newUpdateModal"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newUpdateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="newUpdateModalLabel"
          i18n="@@app_update_available"
        >
          App Update Available
        </h5>
        <button
          type="button"
          class="close"
          i18n-name="@@close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        i18n="@@would_you_like_to_download_new_application_update_"
      >
        Would you like to download new application update ?
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          i18n="@@no"
        >
          No
        </button>
        <button
        i18n="@@yes"
          type="button"
          class="btn btn-primary"
          (click)="downloadUpdate()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
