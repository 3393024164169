<div class="max-h-[355px] overflow-auto m-auto" [ngClass]="tableWrapperClass">
  <div
    *ngIf="!dataSource?.data"
    class="h-[100%] flex justify-center items-center"
  >
    <p class="font-bold" i18n="@@fetch_list_of_records">
      Fetch list of records
    </p>
  </div>
  <!-- <div *ngIf="dataSource?.data && !dataSource.data.length" class="h-[200px] w-full flex justify-center items-center">
        <p class="font-bold">No Data To Show</p>
    </div> -->

  <table
    #table
    id="grid2"
    *ngIf="dataSource?.data?.length"
    mat-table
    [dataSource]="dataSource"
    class="w-full"
    [ngClass]="tableClass"
  >
    <ng-container
      *ngFor="let column of initColumns; trackBy: customTrackBy"
      [matColumnDef]="column.key"
      [sticky]="column.value === 'checkbox'"
    >
      <!-- if text -->
      <ng-container *ngIf="column.type === 'text'">
        <th
          class="text-[11px] text-black bg-chart m-1 border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
          <span
            class="ml-[5px]"
            *ngIf="column.toolIcon"
            [matTooltip]="column.tooltip || ''"
            data-toggle="tooltip"
            data-placement="left"
          >
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span
            [ngClass]="{ 'text-red-500': item['reversed'] }"
            [matTooltip]="item[column.value]"
          >
            <!-- <ng-container *ngIf="item && column?.value"> -->
            {{
              item[column.value]?.toString() || "-"
                | underScoreToSpace
                | titlecase
                | truncate : column.maxLen
            }}
            <!-- </ng-container> -->
            <!-- {{ item[column.value] | truncate: column.maxLen | titlecase }} -->
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>

      <!-- if email -->
      <ng-container *ngIf="column.type === 'email'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70"
          class="text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="{ 'text-red-500': item['reversed'] }"
          *matCellDef="let item"
        >
          <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>
      <!-- if boolean -->
      <ng-container *ngIf="column.type === 'boolean'">
        <th
          class="text-[11px] text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70"
          [ngClass]="column.class"
          *matCellDef="let item"
        >
          <span
            [ngClass]="{ 'text-red-500': item['reversed'] }"
            [matTooltip]="item[column.value]"
          >
            <ng-container
              *ngIf="column.value === 'reversed'; else defaultBoolean"
            >
              <span>
                {{
                  item[column.value]
                    | booleanReversedValues : item["reversal_transaction_id"]
                }}</span
              >
            </ng-container>

            <ng-template #defaultBoolean>
              {{ item[column.value] | booleanValues }}
            </ng-template>
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>
      <!-- if object access key in objectVal -->
      <ng-container *ngIf="column.type === 'object'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | findValInObject : column.objectVal }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>
      <!-- if number, use commas -->
      <ng-container *ngIf="column.type === 'number'">
        <th
          style="text-align: right"
          class="text-black text-[11px] border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
          <span
            class="ml-[5px]"
            *ngIf="column.toolIcon"
            [matTooltip]="column.tooltip || ''"
            data-toggle="tooltip"
            data-placement="left"
          >
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-right text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | numberWithComma }}
          </span>
        </td>
        <td
          style="text-align: right"
          class="floatRight text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer | numberWithComma }}
          </span>
        </td>
      </ng-container>

      <!-- if numberWithDecimal, use commas and two place decimal -->
      <ng-container *ngIf="column.type === 'numberWithDecimal'">
        <th
          style="text-align: right"
          class="floatRight text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
          <span
            class="ml-[5px]"
            *ngIf="column.toolIcon"
            [matTooltip]="column.tooltip || ''"
            data-toggle="tooltip"
            data-placement="left"
          >
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-right text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | numberWithCommaAndTwoDecimal }}
          </span>
        </td>
        <td
          class="text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
          style="text-align: right"
        >
          <span>
            {{ column.footer | numberWithCommaAndTwoDecimal }}
          </span>
        </td>
      </ng-container>
      <!-- if id -->
      <ng-container *ngIf="column.type === 'id'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>
      <!-- if currency or percentile -->
      <ng-container *ngIf="column.type === 'currency_percentile'">
        <th
          style="text-align: right"
          class="text-[11px] floatRight text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
          <span
            class="ml-[5px]"
            *ngIf="column.toolIcon"
            [matTooltip]="column.tooltip || ''"
            data-toggle="tooltip"
            data-placement="left"
          >
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
        </th>

        <td
          mat-cell
          class="text-[11px] !opacity-70 text-right text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <ng-container *ngIf="item?.chargeType; else formatCurrency">
            <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
              {{ item[column.value] | wz_naira_money_format }}
            </span>
            <ng-template #percentFormat>
              {{ item[column.value] | wz_percentage_format_2 }}
            </ng-template>
          </ng-container>

          <ng-template #formatCurrency>
            {{ item[column.value] | wz_naira_money_format }}
          </ng-template>
        </td>
      </ng-container>
      <!-- if currency format with symbol -->
      <ng-container *ngIf="column.type === 'currency'">
        <th
          style="text-align: right"
          class="text-[11px] text-right text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name | wz_money_symbol }}
          </span>
          <span
            class="ml-[5px]"
            *ngIf="column.toolIcon"
            [matTooltip]="column.tooltip || ''"
            data-toggle="tooltip"
            data-placement="left"
          >
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-right text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            <ng-container *ngIf="item?.chargeType; else formatCurrency">
              <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
                {{ item[column.value] | wz_money_format }}
              </span>
              <ng-template #percentFormat>
                {{ item[column.value] | wz_percentage_format_2 }}
              </ng-template>
            </ng-container>

            <ng-template #formatCurrency>
              {{ item[column.value] | wz_money_format }}
            </ng-template>
          </span>
        </td>
        <td
          class="text-black floatRight border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer | wz_money_format }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'percentile'">
        <th
          style="text-align: right"
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-right text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | wz_percentage_format_2 }}
          </span>
        </td>
        <td
          style="text-align: right"
          class="text-black border-y border-y-black bg-chart m-1 floatRight text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer | wz_percentage_format_2 }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'date'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
          <span
            class="ml-[5px]"
            *ngIf="column.toolIcon"
            [matTooltip]="column.tooltip || ''"
            data-toggle="tooltip"
            data-placement="left"
          >
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] text-center !opacity-70 text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | date : "d MMM. YYYY" : "UTC" }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'dateTime'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | date : "dd-MM-YYYY h:mm:ss a" : "UTC" }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>

      <ng-container *ngIf="column.type === 'phone'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | wz_phone_number }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'multiValue'">
        <th
          class="text-[11px] text-black border-b bg-chart m-1 !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          [ngClass]="headerClass"
          mat-header-cell
          *matHeaderCellDef
        >
          <span>
            {{ column.name }}
          </span>
        </th>
        <td
          mat-cell
          class="text-[11px] !opacity-70 text-black border-b !border-b-black/15"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          *matCellDef="let item"
        >
          <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ column.values | mergeMultiValues : item | titlecase }}
          </span>
        </td>
        <td
          class="text-black border-y border-y-black bg-chart m-1 text-[11px]"
          [ngClass]="
            column.border === 'x'
              ? 'border-x border-black/10'
              : column.border === 'l'
              ? 'border-l border-black/10'
              : column.border === 'r'
              ? 'border-r border-black/10'
              : ''
          "
          mat-footer-cell
          *matFooterCellDef
        >
          <span>
            {{ column.footer }}
          </span>
        </td>
      </ng-container>

      <!-- if more (...) -->
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </ng-container>
    <ng-container *ngIf="showFooter">
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns; sticky: true"
        class="font-semibold"
      ></tr>
    </ng-container>
  </table>
</div>
