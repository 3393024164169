import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TogglePayload } from 'src/app/shared/models/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GuarantorService {
  baseUrl: string = environment.baseUrl + environment.accountUrl;

  constructor(private http: HttpClient) {}

  public getAllGuarantors(req: any): Observable<any> {
    return this.http.get(this.baseUrl + `guarantors?${req}`);
  }

  public getGuarantor(id: number): Observable<any> {
    return this.http.get(this.baseUrl + `guarantors/` + id);
  }

  public updateGuarantor(id: number, data: Object) {
    return this.http.put<any>(this.baseUrl + `guarantors/${id}`, data);
  }

  public getGuarantorInfoBVN(bvn: number) {
    return this.http.get<any>(this.baseUrl + `guarantors/bvn/${bvn}`);
  }

  public getGuarantorInfoPhone(phone: number) {
    return this.http.get<any>(this.baseUrl + `guarantors/mobile/${phone}`);
  }

  public enableGuaranteeMultipleLoans(guarantorId: number) {
    return this.http.put<any>(
      this.baseUrl + `guarantors/${guarantorId}/guarantee-multiple/enable`,
      { guaranteeMultipleLoans: true }
    );
  }

  public disableGuaranteeMultipleLoans(guarantorId: number) {
    return this.http.put<any>(
      this.baseUrl + `guarantors/${guarantorId}/guarantee-multiple/disable`,
      { guaranteeMultipleLoans: false }
    );
  }

  public enableGuarantor(data: TogglePayload) {
    return this.http.put<any>(this.baseUrl + `guarantors/enable`, data);
  }

  public disableGuarantor(data: TogglePayload) {
    return this.http.put<any>(this.baseUrl + `guarantors/disable`, data);
  }
}
