<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <app-modal-header-ui title="New Loan Account" i18n-title="@@newLoanAccountTitle"></app-modal-header-ui>

  <div class="dialog-body">
      <app-button-toggle-ui (stateEmit)="navigateToState($event)" [buttons]="toggleStates"></app-button-toggle-ui>

      <!-- Basic Form-->
      <form class="my-8 grid grid-cols-2 md:grid-cols-3 gap-7" [formGroup]="basicForm" [hidden]="activeTab === 'guarantor'">
          <app-searchable-select [label]="getLocalizedLoanProductsLabel()" i18n-label="@@loanProductsLabel" [options]="loanProducts" formControlName="loanProductId"></app-searchable-select>

          <mat-form-field style="width: 100%" class="w-full" appearance="outline">
              <mat-label i18n="@@loanSizeLabel">Loan Size</mat-label>
              <input appStrictNumbers type="tel" formControlName="principal" matInput placeholder="" />
              <mat-hint class="mt-4" *ngIf="loanProductRecords">
                  <span i18n="@@minLabel">Min</span>: {{ loanProductRecords?.minimumPrincipal | numberWithComma }}
                  <span i18n="@@maxLabel">Max</span>: {{ loanProductRecords?.maximumPrincipal | numberWithComma }}
              </mat-hint>
          </mat-form-field>

          <mat-form-field class="w-full" appearance="outline">
              <mat-label i18n="@@loanTermLabel">Loan Term</mat-label>
              <input appStrictNumbers type="tel" formControlName="loanPeriodLength" matInput placeholder="" />
              <span matSuffix>/
                <mat-select
                  formControlName="loanPeriodLengthUnit"
                  (selectionChange)="selectedLoanTenorUnit()"
                >
                  <mat-option
                    [value]="repayment.value"
                    *ngFor="let repayment of loanFrequencyOptions"
                    >{{ repayment.label }}
                  </mat-option>
                </mat-select>
              </span>
              <mat-hint class="mt-4" *ngIf="loanProductRecords">
                  <span i18n="@@minLabel">Min</span>: {{ loanProductRecords?.minimumLoanPeriodLength }},
                  <span i18n="@@maxLabel">Max</span>: {{ loanProductRecords?.maximumLoanPeriodLength }}
              </mat-hint>
          </mat-form-field>

          <mat-form-field style="width: 100%" class="w-full" appearance="outline">
              <mat-label i18n="@@interestRateLabel">Interest Rate</mat-label>
              <input type="tel" formControlName="interestRate" matInput placeholder="" />
              <span matSuffix>%
                <mat-select formControlName="interestRateUnit">
                  <mat-option value="DAY" i18n="@@dayOption">Day</mat-option>
                  <mat-option value="WEEK" i18n="@@weekOption">Week</mat-option>
                  <mat-option value="MONTH" i18n="@@monthOption">Month</mat-option>
                  <mat-option value="YEAR" i18n="@@yearOption">Year</mat-option>
                </mat-select>
              </span>
          </mat-form-field>

          <mat-form-field style="width: 100%" class="w-full" appearance="outline">
              <mat-label i18n="@@gracePeriodLabel">Grace Period</mat-label>
              <input type="tel" formControlName="gracePeriodLength" matInput placeholder="" />
              <span matSuffix>|
                <mat-select formControlName="gracePeriodUnit">
                  <mat-option value="DAY" i18n="@@dayOption">Day</mat-option>
                  <mat-option value="WEEK" i18n="@@weekOption">Week</mat-option>
                  <mat-option value="MONTH" i18n="@@monthOption">Month</mat-option>
                  <mat-option value="YEAR" i18n="@@yearOption">Year</mat-option>
                </mat-select>
              </span>
              <mat-hint class="mt-4" *ngIf="loanProductRecords">
                  <span i18n="@@minLabel">Min</span>: {{ loanProductRecords?.minimumGracePeriod }},
                  <span i18n="@@maxLabel">Max</span>: {{ loanProductRecords?.maximumGracePeriod }}
              </mat-hint>
          </mat-form-field>

          <mat-form-field style="width: 100%" class="w-full" appearance="outline">
              <mat-label i18n="@@repaymentFrequencyLabel">Repayment Frequency</mat-label>
              <input type="tel" formControlName="loanRepaymentFrequency" matInput placeholder="" />
              <span matSuffix>
                <mat-select formControlName="loanRepaymentFrequencyPeriodUnit">
                  <mat-option value="DAY" i18n="@@dayOption">Day</mat-option>
                  <mat-option value="WEEK" i18n="@@weekOption">Week</mat-option>
                  <mat-option value="MONTH" i18n="@@monthOption">Month</mat-option>
                  <mat-option value="YEAR" i18n="@@yearOption">Year</mat-option>
                </mat-select>
              </span>
              <mat-hint class="mt-4" *ngIf="loanProductRecords">
                  <span i18n="@@minLabel">Min</span>: {{ loanProductRecords?.minimumLoanRepaymentFrequency }},
                  <span i18n="@@maxLabel">Max</span>: {{ loanProductRecords?.maximumLoanRepaymentFrequency }}
              </mat-hint>
          </mat-form-field>
      </form>

      <!-- Guarantor Form -->
      <form [formGroup]="guarantorForm" class="flex flex-col gap-5 my-8 max-h-[50vh] md:max-h-[unset] overflow-auto" [hidden]="activeTab === 'basic'">
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@phoneNumberLabel">Phone Number</mat-label>
                  <input class="hidden" value="prevent_mat_form_throwing_missing_matInput_errors" matInput />
                  <ngx-intl-tel-input appRemoveLeadingZero class="z-40" [cssClass]="'w-full bg-transparent'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Nigeria"
                      [maxLength]="15" [phoneValidation]="true" [numberFormat]="PhoneNumberFormat.National" name="mobile number" formControlName="mobileNumber">
                  </ngx-intl-tel-input>
                  <span matSuffix>
                  <mat-icon
                    *ngIf="!checkingPhone"
                    style="font-size: 18px"
                    class="mr-[-20px]"
                    >security</mat-icon
                  >
                  <mat-spinner
                    *ngIf="checkingPhone"
                    class="pl-2"
                    diameter="18"
                    strokeWidth="1"
                  >
                  </mat-spinner>

                </span>
                  <mat-hint class="mt-4" i18n="@@excludeLeadingZeroHint">Exclude Leading Zero(0)</mat-hint>
              </mat-form-field>

              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@firstNameLabel">First Name</mat-label>
                  <input appRemoveSpaces type="text" formControlName="firstName" matInput />
              </mat-form-field>
              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@middleNameLabel">Middle Name</mat-label>
                  <input appRemoveSpaces type="text" formControlName="middleName" matInput />
              </mat-form-field>
              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@lastNameLabel">Last Name</mat-label>
                  <input appRemoveSpaces type="text" formControlName="lastName" matInput />
              </mat-form-field>
          </div>

          <div class="grid grid-cols-3 md:grid-cols-5 gap-4">
              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@genderLabel">Gender</mat-label>
                  <mat-select formControlName="gender">
                      <mat-option value="MALE" i18n="@@maleOption">Male</mat-option>
                      <mat-option value="FEMALE" i18n="@@femaleOption">Female</mat-option>
                      <mat-option value="OTHER" i18n="@@otherOption">Other</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="guarantorForm.get('bvn') as bvn" style="width: 100%" appearance="outline">
                  <mat-label i18n="@@bvnLabel">BVN</mat-label>
                  <input maxlength="11" type="tel" appStrictNumbers matInput formControlName="bvn" (blur)="checkBVN()" />
                  <span matSuffix>
                  <mat-icon
                    *ngIf="!checkingBVN"
                    style="font-size: 18px"
                    class="mr-[-20px]"
                    >security</mat-icon
                  >
                  <mat-spinner
                    *ngIf="checkingBVN"
                    class="pl-2"
                    diameter="18"
                    strokeWidth="1"
                  >
                  </mat-spinner>
                </span>
              </mat-form-field>

              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@dateOfBirthLabel">Date of Birth</mat-label>
                  <input readonly [max]="dobAgeRange" matInput [matDatepicker]="dateOfBirth" placeholder="Choose a date" i18n-placeholder="@@chooseDatePlaceholder" formControlName="dateOfBirth" />
                  <mat-datepicker-toggle matSuffix [for]="dateOfBirth" class="mr-[-20px]">
                  </mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirth></mat-datepicker>
                  <mat-hint class="mt-4" *ngIf="loanProductRecords" i18n="@@ageRangeHint">Age range 18 - 60</mat-hint>
              </mat-form-field>

              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@occupationLabel">Occupation</mat-label>
                  <input appRemoveSpaces type="text" formControlName="occupation" matInput />
              </mat-form-field>

              <mat-form-field style="width: 100%" class="w-full col-span-2 md:col-span-1" appearance="outline">
                  <mat-label i18n="@@emailAddressLabel">Email Address</mat-label>
                  <input appRemoveSpaces type="text" formControlName="email" matInput />
              </mat-form-field>
          </div>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <app-searchable-select [label]="getLocalizedState()" i18n="@@stateLabel" [options]="states" formControlName="stateId">
              </app-searchable-select>

              <app-searchable-select [label]="getLocalizedLgvt()" i18n="@@localGovtLabel" [options]="localGovernments" formControlName="lgaId">
              </app-searchable-select>

              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@townCityLabel">Town/City</mat-label>
                  <input appRemoveSpaces type="text" formControlName="townName" matInput />
              </mat-form-field>

              <mat-form-field style="width: 100%" class="w-full textarea-input" appearance="outline">
                  <mat-label i18n="@@addressLabel">Address</mat-label>
                  <textarea appRemoveSpaces formControlName="addressLine1" matInput rows="1"></textarea>
              </mat-form-field>
          </div>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
              <app-searchable-select [label]="getLocalizedBusinessState()" i18n="@@businessStateLabel" [options]="states" formControlName="businessStateId">
              </app-searchable-select>

              <app-searchable-select [label]="getLocalizedBusinessLgvt()" i18n="@@businessLocalGovtLabel" [options]="businessLocalGovernments" formControlName="businessLgaId">
              </app-searchable-select>

              <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                  <mat-label i18n="@@businessTownCityLabel">Business Town/City</mat-label>
                  <input appRemoveSpaces type="text" formControlName="businessTownName" matInput />
              </mat-form-field>
              <mat-form-field style="width: 100%" class="w-full textarea-input" appearance="outline">
                  <mat-label i18n="@@businessAddressLabel">Business Address</mat-label>
                  <textarea appRemoveSpaces formControlName="businessAddressLine1" matInput rows="1"></textarea>
              </mat-form-field>
          </div>

          <div class="flex flex-wrap gap-4">
              <app-file-upload-ui label="Photo" i18n-label="@@photoLabel" [currentPhoto]="imageValues?.photo" [disabled]="isViewMode" (upload)="saveFile($event, 'photo')"></app-file-upload-ui>

              <app-file-upload-ui label="Signature" i18n-label="@@signatureLabel" [currentPhoto]="imageValues?.signature" [disabled]="isViewMode" (upload)="saveFile($event, 'signature')"></app-file-upload-ui>

              <app-file-upload-ui label="ID Card" i18n-label="@@idCardLabel" [currentPhoto]="imageValues?.idCard" [disabled]="isViewMode" (upload)="saveFile($event, 'idCard')"></app-file-upload-ui>
          </div>
      </form>

      <div class="flex items-center justify-center gap-3 my-3 " [ngClass]="
      activeTab === 'basic' ? 'flex-row' : 'flex-col-reverse md:flex-row'
    ">
          <ng-container *ngIf="activeTab === 'basic'; else guarantorActionBtns">
              <app-button-icon-ui classNames="btn border-error text-error bg-white w-100px" name="Cancel" value="Cancel" i18n-name="@@cancel" tooltip="Cancel" i18n-tooltip="@@cancelTooltip" (clickEmit)="close()"></app-button-icon-ui>
              <app-button-icon-ui *ngIf="loanProductRecords?.requireGuarantor" [iconPosition]="'right'" [icon]="'east'" classNames="btn text-white bg-success w-100px " name="Next" value="next" i18n-name="@@nextButton" tooltip="Move to the next step" i18n-tooltip="@@nextStepTooltip" (clickEmit)="onSubmitBasic()"></app-button-icon-ui>
              <app-button-icon-ui *ngIf="!loanProductRecords?.requireGuarantor" [iconPosition]="'right'" [icon]="'save'" class="w-full md:!w-[100px]" classNames="btn w-full text-white bg-success" name="Submit" value="submit" i18n-name="@@submitButton" tooltip="Submit" i18n-tooltip="@@submitTooltip" (clickEmit)="onSubmitGuarantor()"></app-button-icon-ui>
          </ng-container>
          <ng-template #guarantorActionBtns>
              <app-button-icon-ui class="w-full md:!w-[100px]" classNames="btn border-error text-error bg-white w-full" name="Cancel" value="Cancel" i18n-name="@@cancelButton" tooltip="Cancel" i18n-tooltip="@@cancelTooltip" (clickEmit)="close()"></app-button-icon-ui>
              <app-button-icon-ui [iconPosition]="'left'" [icon]="'west'" class="w-full md:!w-[100px]" classNames="btn w-full text-white bg-primary" name="Previous" value="previous" i18n-name="@@previousButton" tooltip="Move to the previous step" i18n-tooltip="@@previousStepTooltip" (clickEmit)="gotoBasic()"></app-button-icon-ui>
              <app-button-icon-ui [iconPosition]="'right'" [icon]="'save'" class="w-full md:!w-[100px]" classNames="btn w-full text-white bg-success" name="Submit" value="submit" i18n-name="@@submitButton" tooltip="Submit" i18n-tooltip="@@submitTooltip" (clickEmit)="onSubmitGuarantor()"></app-button-icon-ui>
          </ng-template>
      </div>
  </div>
</div>
