import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/utils/permission.service';
import { UtilityService } from 'src/app/utils/utility.service';
import {
  InitDisplayedCol,
  Page,
  DisplayColTypes,
  PageCtrl,
} from '../../models/common';
import { NotificationService } from 'src/app/utils/notification.service';
import { ColumnType } from '../../models/table';

@Component({
  selector: 'app-table3-ui',
  templateUrl: './table3-ui.component.html',
  styleUrls: ['./table3-ui.component.css'],
})
export class Table3UiComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren('fundSourceSelect', { read: MatSelect })
  fundSourceSelect: QueryList<MatSelect>;
  @ViewChildren('glAccountSelect', { read: MatSelect })
  glAccountSelect: QueryList<MatSelect>;

  @Input() set data(payload: any) {
    let data = payload;
    if (payload) {
      data = this.preCheckSelectedIds(payload);
    }
    this.dataSource = new MatTableDataSource(
      Array.isArray(payload) ? payload : null
    );
  }
  @Input() initColumns: InitDisplayedCol[];
  @Input() selectedIds: Set<any> = new Set();

  @Input() pageProp: Page;
  @Input() showPagination: boolean;

  @Input() usePermission: boolean = false;

  @Input() matSortActive: string = '';
  @Input() matSortDirection: SortDirection = '';
  @Input() showCheckboxes: boolean = true;

  @Input() loanProducts: any[] = [];

  @Output() checkboxCheckEmit: EventEmitter<any> = new EventEmitter();
  @Output() changefunSourceGls: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeToggle: EventEmitter<number> = new EventEmitter();
  @Output() PageCtrlToggle: EventEmitter<PageCtrl> = new EventEmitter();
  @Output() sortEmit: EventEmitter<any> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() onApplyToAll: EventEmitter<any> = new EventEmitter();

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any>;
  public masterCheckboxChecked: boolean;

  minDate: Date;
  maxDate: Date;

  leaderAccountId: number;
  leaderLoanOfficerAccountId: number;
  selectInput: FormControl = new FormControl('');
  public symbol: string = '₦';

  constructor(
    private permissionsService: PermissionService,
    private notif: NotificationService,
    public utils: UtilityService
  ) {
    const currentYear = new Date().getFullYear();
    const currentTime = new Date();
    this.minDate = new Date(currentYear - 6, 0, 1);
  }

  ngOnInit(): void {
    this.displayedColumns = this.initColumns.map((col) => col.key);
    this.glAccountSetDefault();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.displayedColumns = this.initColumns.map((col) => col.key);
    this.glAccountSetDefault();
    this.selectedIds = new Set();

    if (!this.selectedIds.size) {
      this.masterCheckboxChecked = false;
    }
    // if(changes['pageProp']) this.pageProp = changes['pageProp'].currentValue
  }

  ngAfterViewInit() {
    if (this.dataSource) this.dataSource.sort = this.sort;
  }

  private preCheckSelectedIds(data: any[]) {
    if (!data || !data.length) return;
    return data.map((d) => {
      d.checked = this.selectedIds.has(d.id);
      return d;
    });
  }

  public hasPermission(perms: string[]) {
    return this.permissionsService.hasPermissionList(perms);
  }
  public showToolTip(val, maxLen) {
    const len = maxLen ?? 50;
    if (val.length <= len) {
      return val;
    }
    return '';
  }

  public announceSortChange(sortState: any) {
    this.sortEmit.emit(sortState);
  }

  public onPageSizeToggle(val: number) {
    this.pageSizeToggle.emit(val);
  }
  public onPageCtrlToggle(val: PageCtrl) {
    this.PageCtrlToggle.emit(val);
  }

  public onCheck(event: MatCheckboxChange, item: any) {
    if (this.masterCheckboxChecked) this.masterCheckboxChecked = false;
    const elemCount = this.dataSource.data.length;
    this.dataSource.data.map((d: any) => {
      const id = d.id || d.accountId;
      item.id = item.id || item.accountId;
      if (id) {
        if (d.id === item.id) {
          d.checked = event.checked;
        }

        if (d.checked) {
          this.selectedIds.add(d);
        } else {
          this.selectedIds.delete(d);
        }
        return;
      }
    });
    if (this.selectedIds.size === elemCount) this.masterCheckboxChecked = true;
    this.checkboxCheckEmit.emit(this.selectedIds);
  }

  public onCheckAll(event: MatCheckboxChange) {
    this.masterCheckboxChecked = event.checked;
    this.dataSource.data.forEach((element: any) => {
      element.checked = event.checked;
    });

    this.selectedIds = new Set(this.dataSource.data.map((val) => val));

    event.checked
      ? this.checkboxCheckEmit.emit(this.selectedIds)
      : this.checkboxCheckEmit.emit(new Set());
  }

  public unCheckAll() {
    this.masterCheckboxChecked = false;
    this.data.forEach((element: any) => {
      element.checked = false;
    });
  }

  public triggerRowClick(rowId: number) {
    this.rowClick.emit(rowId);
  }

  // findFundSourceByProductId(productId: number) {
  //   return this.loanProducts.find(loanProduct => loanProduct.value === 300).fundSources
  // }

  loanOfficerApplyToAll(item: any, column: InitDisplayedCol, index: number) {
    if (!item[column.value]) {
      this.notif.error(
        $localize`:@@no_loan_officer_selected:No loan officer selected`
      );
      return;
    }
    this.leaderLoanOfficerAccountId = item.id;
    const selectedLoanOfficerId = item[column.value];
    this.dataSource.data = this.dataSource.data.map((loan) => {
      if (loan['hasLoanOfficer']) return loan;
      loan[column.value] = selectedLoanOfficerId;
      return loan;
    });
  }
  glAccountApplyToAll(item: any, column: InitDisplayedCol, index: number) {
    if (!item[column.value]) {
      this.notif.error($localize`:@@no_account_selected:No account selected`);
      return;
    }
    this.leaderAccountId = item.id;
    const selectedAccountId = item[column.value];

    this.dataSource.data = this.dataSource.data.map((loan) => {
      const branchGls = loan?.branch?.branchGls || loan?.branchGls || [];
      if (branchGls.find((gl) => gl.id === selectedAccountId)) {
        loan[column.value] = selectedAccountId;
        return loan;
      }
      return loan;
    });
  }
  glAccountSetDefault() {
    const glColumn = this.initColumns.find(
      (val) => val.type === 'customGlAccountSelectPicker'
    );
    if (!glColumn) return;
    this.dataSource.data = this.dataSource?.data?.map((loan) => {
      const branchGls = loan?.branch?.branchGls || loan?.branchGls || [];
      if (branchGls.length === 1) {
        loan[glColumn.value] = branchGls[0].id;
        return loan;
      }
      return loan;
    });
  }

  applyToAll(accountId: number, index: number) {
    this.leaderAccountId = accountId;
    const selectedFundSourceGlId = this.fundSourceSelect.get(index).value;

    this.dataSource.data.forEach((approvedLoan) => {
      // const fundSourceGlIds = this.findFundSourceByProductId(approvedLoan.productId).map((fundSource) => fundSource.glAccountId);
      approvedLoan.fundSourceGlId = selectedFundSourceGlId;
      // if (fundSourceGlIds.includes(selectedFundSourceGlId))
      this.fundSourceSelect.forEach(
        (selectInput) => (selectInput.value = selectedFundSourceGlId)
      );
    });
  }
  formatCurrency(amount) {
    // TODO: Use currency code to get the currency symbol
    // i.e en-NG/NGN = ₦, en-US/USD = $ etc
    return amount
      ? `${this.symbol}${amount
          .replace(/[^0-9\.]/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      : null;
  }
  applyToAllCustomInput(value: number, fieldName: string) {
    this.dataSource.data = this.dataSource.data.map((record) => {
      record[fieldName] = this.formatCurrency(value);
      return record;
    });
  }
}
