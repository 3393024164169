<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  class="productContainerModal"
>
  <div
    class="flex items-center px-[15px] pb-[10px] border-b-[1px] border-primary sticky top-0 left-0 bg-white z-20 pt-6 w-[1015px]"
  >
    <img
      src="assets/images/new-images/logo-2.png"
      class="flex-[0_0_10%] header-image w-[90px] h-[26.89px]"
      routerLink="/dashboard"
    />

    <h2 class="font-semibold m-[0_auto] text-xl text-center">
      <ng-container i18n="@@editButton" *ngIf="isViewMode">Edit</ng-container>
      <ng-container i18n="@@editButton" *ngIf="!isViewMode">New</ng-container>
      <ng-container i18n="@@loan_product"> Loan Product</ng-container>
    </h2>
    <mat-icon class="text-red-500 text-4xl cursor-pointer" (click)="cancel()"
      >highlight_off</mat-icon
    >
  </div>

  <div class="flex flex-col gap-[5px] px-[15px] mt-[20px] ">
    <h3 class="text-primary" i18n="@@loan_product_information">
      Loan Product Information
    </h3>
    <div class="flex flex-col rounded-[10px] bg-[#F2F2F2] overflow-x-auto">
      <div class="px-[15px]">
        <form [formGroup]="basicForm">
          <div class="pb-[10px] pt-[15px] border-b-[1px] border-primary">
            <div class="flex gap-[20px]">
              <div class="flex items-center gap-[5px] w-[20.5%]">
                <mat-label i18n="@@name">Name*</mat-label>
                <mat-form-field
                  appearance="outline"
                  class="short_height w-[80.5%]"
                >
                  <input
                    type="text"
                    matInput
                    appRemoveSpaces
                    appTitleCase
                    formControlName="name"
                  />
                </mat-form-field>
              </div>

              <div class="flex items-center gap-[10px] w-[12.431%]">
                <mat-label i18n="@@code">Code*</mat-label>
                <mat-form-field
                  appearance="outline"
                  class="short_height w-[67.23%]"
                >
                  <input
                    type="text"
                    matInput
                    appRemoveSpaces
                    formControlName="code"
                  />
                </mat-form-field>
              </div>

              <div class="flex items-center gap-[10px] w-[24.27%]">
                <!-- <mat-label i18n="@@fund_source">Fund Source*</mat-label>
                                    <mat-form-field appearance="outline" class="short_height w-[68%]">
                                        <mat-select multiple formControlName="fundSources">
                                            <mat-option [value]="fundSource?.id" *ngFor="let fundSource of fundSources">{{
                                                fundSource?.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field> -->
                <app-search-select-multiple
                  [fetchedList]="fundSources"
                  class="w-full"
                  [label]="fundSourceLabel"
                  [control]="fundSourceCtrl"
                  [type]="'client'"
                ></app-search-select-multiple>
              </div>

              <div class="flex items-center gap-[10px] w-[35.34%]">
                <mat-label i18n="@@description">Description</mat-label>
                <mat-form-field
                  appearance="outline"
                  class="short_height w-[80.7%] textarea-input"
                >
                  <textarea
                    matInput
                    appRemoveSpaces
                    appSentenceCase
                    formControlName="description"
                    rows="1"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="flex gap-[8px] justify-start mr-4">
              <mat-checkbox i18n="@@require_guarantor" formControlName="requireGuarantor">
                Require Guarantor
              </mat-checkbox>
              <mat-checkbox i18n="@@enable_on_web_client" formControlName="enableWebClients">
                Enable on Web Client
              </mat-checkbox>
            </div>
          </div>
        </form>
      </div>
      <div class="">
        <form [formGroup]="loanForm">
          <div class="flex pl-[15px] pt-[15px] pr-[10px] pb-[20px]">
            <div
              class="flex gap-[10px] border-r-[1px] border-primary pr-[10px] w-[54.73%]  "
            >
              <div class="flex flex-col w-[34%]">
                <div class="text-center mb-[24px] h-[35px]">
                  <mat-label i18n="@@principal" class="font-semibold"
                    >Principal</mat-label
                  >
                </div>
                <div class="flex flex-col gap-[5px]">
                  <div class="mt-[0.25em] mb-[0.15em] mx-0 h-[30px]"></div>
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@default">Default *</mat-label>
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[61.125%]"
                    >
                      <input
                        type="text"
                        appCurrency
                        matInput
                        formControlName="defaultPrincipal"
                        (blur)="
                          validateMinMaxInputs(
                            loanForm,
                            {
                              default: 'defaultPrincipal',
                              min: 'minimumPrincipal',
                              max: 'maximumPrincipal'
                            },
                            'principal'
                          )
                        "
                      />
                    </mat-form-field>
                  </div>
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@minimum">Minimum *</mat-label>
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[61.125%]"
                    >
                      <input
                        type="text"
                        appCurrency
                        matInput
                        formControlName="minimumPrincipal"
                        (blur)="
                          validateMinMaxInputs(
                            loanForm,
                            {
                              default: 'defaultPrincipal',
                              min: 'minimumPrincipal',
                              max: 'maximumPrincipal'
                            },
                            'principal'
                          )
                        "
                      />
                    </mat-form-field>
                  </div>
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@maximum">Maximum *</mat-label>
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[61.125%]"
                    >
                      <input
                        type="text"
                        appCurrency
                        matInput
                        formControlName="maximumPrincipal"
                        (blur)="
                          validateMinMaxInputs(
                            loanForm,
                            {
                              default: 'defaultPrincipal',
                              min: 'minimumPrincipal',
                              max: 'maximumPrincipal'
                            },
                            'principal'
                          )
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-[14.295%]">
                <div class="text-center mb-[24px]">
                  <mat-label i18n="@@interest_rate" class="font-semibold"
                    >Interest Rate</mat-label
                  >
                </div>
                <div class="flex flex-col gap-[5px]">
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <mat-label i18n="@@per">Per</mat-label>
                    <mat-select formControlName="interestPeriodUnit">
                      <mat-option value="DAY" i18n="@@dayOption"
                        >Day</mat-option
                      >
                      <mat-option value="WEEK" i18n="@@weekOption"
                        >Week</mat-option
                      >
                      <mat-option value="MONTH" i18n="@@monthOption"
                        >Month</mat-option
                      >
                      <mat-option value="YEAR" i18n="@@yearOption"
                        >Year</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      matInput
                      formControlName="defaultInterestRate"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultInterestRate',
                            min: 'minimumInterestRate',
                            max: 'maximumInterestRate'
                          },
                          'interest rate'
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      matInput
                      formControlName="minimumInterestRate"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultInterestRate',
                            min: 'minimumInterestRate',
                            max: 'maximumInterestRate'
                          },
                          'interest rate'
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      matInput
                      formControlName="maximumInterestRate"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultInterestRate',
                            min: 'minimumInterestRate',
                            max: 'maximumInterestRate'
                          },
                          'interest rate'
                        )
                      "
                    />
                  </mat-form-field>
                </div>
              </div>


              <div class="flex flex-col w-[14.295%]">
                <div class="text-center mb-[24px]">
                  <mat-label i18n="@@loan_tenure" class="font-semibold"
                    >Loan Tenure
                  </mat-label>
                </div>
                <div class="flex flex-col gap-[5px]">
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <mat-label i18n="@@period">Period</mat-label>
                    <mat-select
                      formControlName="loanPeriodLengthUnit"
                      (selectionChange)="selectedLoanTenorUnit($event)"
                    >
                      <mat-option value="DAY" i18n="@@dayOption"
                        >Day</mat-option
                      >
                      <mat-option value="WEEK" i18n="@@weekOption"
                        >Week</mat-option
                      >
                      <mat-option value="MONTH" i18n="@@monthOption"
                        >Month</mat-option
                      >
                      <mat-option value="YEAR" i18n="@@yearOption"
                        >Year</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="defaultLoanPeriodLength"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultLoanPeriodLength',
                            min: 'minimumLoanPeriodLength',
                            max: 'maximumLoanPeriodLength',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'loan tenor',
                          true
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="minimumLoanPeriodLength"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultLoanPeriodLength',
                            min: 'minimumLoanPeriodLength',
                            max: 'maximumLoanPeriodLength',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'loan tenor',
                          true
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="maximumLoanPeriodLength"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultLoanPeriodLength',
                            min: 'minimumLoanPeriodLength',
                            max: 'maximumLoanPeriodLength',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'loan tenor'
                        )
                      "
                    />
                  </mat-form-field>
                </div>
              </div>


              <div class="flex flex-col w-[14.295%]">
                <div class="text-center flex flex-col mb-[24px]">
                  <mat-label i18n="@@repayment" class="font-semibold truncate"
                    >Repayment
                  </mat-label>
                  <mat-label i18n="@@frequency"  class="font-semibold truncate"
                    >Frequency
                  </mat-label>
                </div>
                <div class="flex flex-col gap-[5px]">
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <mat-label i18n="@@period">Period</mat-label>
                    <mat-select
                      formControlName="loanRepaymentFrequencyPeriodUnit"
                      (selectionChange)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultLoanPeriodLength',
                            min: 'minimumLoanPeriodLength',
                            max: 'maximumLoanPeriodLength',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'loan tenor',
                          true
                        )
                      "
                    >
                      <mat-option
                        [value]="repayment.value"
                        *ngFor="let repayment of repaymentFrequencyOptions"
                        >{{ repayment.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="loanRepaymentFrequency"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'defaultLoanPeriodLength',
                            min: 'minimumLoanPeriodLength',
                            max: 'maximumLoanPeriodLength',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'loan tenor',
                          true,
                          true
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="minimumLoanRepaymentFrequency"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'loanRepaymentFrequency',
                            min: 'minimumLoanRepaymentFrequency',
                            max: 'maximumLoanRepaymentFrequency',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'Repayment Frequency'
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="maximumLoanRepaymentFrequency"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'loanRepaymentFrequency',
                            min: 'minimumLoanRepaymentFrequency',
                            max: 'maximumLoanRepaymentFrequency',
                            frequency: 'loanRepaymentFrequency'
                          },
                          'Repayment Frequency'
                        )
                      "
                    />
                  </mat-form-field>
                </div>
              </div>



              <div class="flex flex-col w-[14.295%]">
                <div class="text-center mb-[24px] relative">
                  <mat-label i18n="@@grace_period" class="font-semibold truncate"
                    >Grace Period</mat-label
                  ><div i18n="@@grace_period" class="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-sm invisible group-hover:visible whitespace-normal max-w-xs z-50">
                    Grace Period
                  </div>
                </div>
                <div class="flex flex-col gap-[5px]">
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <mat-label i18n="@@period">Period</mat-label>
                    <mat-select formControlName="gracePeriodUnit">
                      <mat-option
                        [value]="gracePeriodUnit.value"
                        *ngFor="let gracePeriodUnit of gracePeriodUnits"
                        >{{ gracePeriodUnit.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="gracePeriodLength"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'gracePeriodLength',
                            min: 'minimumGracePeriod',
                            max: 'maximumGracePeriod'
                          },
                          'grace period'
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="minimumGracePeriod"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'gracePeriodLength',
                            min: 'minimumGracePeriod',
                            max: 'maximumGracePeriod'
                          },
                          'grace period'
                        )
                      "
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="short_height w-[100%]"
                  >
                    <input
                      type="text"
                      appStrictNumbers
                      matInput
                      formControlName="maximumGracePeriod"
                      (blur)="
                        validateMinMaxInputs(
                          loanForm,
                          {
                            default: 'gracePeriodLength',
                            min: 'minimumGracePeriod',
                            max: 'maximumGracePeriod'
                          },
                          'grace period'
                        )
                      "
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>









            <div class="flex gap-[10px] pl-[10px] w-[34.04%]">
              <div class="flex flex-col w-[169px]">
                <div class="mb-[24px] h-[35px]">
                  <mat-label
                    i18n="@@other_loan_product_information"
                    class="flex font-semibold w-[97px] leading-[18px]"
                    >Other Loan Product Information</mat-label
                  >
                </div>
                <div class="flex flex-col gap-[5px]">
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@amortization_type"
                      >Amortization Type *</mat-label
                    >
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[80px]"
                    >
                      <mat-select
                        value="day"
                        formControlName="amortizationType"
                      >
                        <mat-option i18n="@@flat" value="NORMAL">Flat</mat-option>
                        <mat-option i18n="@@baloon" value="BALLOON">Balloon</mat-option>
                        <mat-option i18n="@@upfront_interest" value="UPFRONT_INTEREST"
                          >Upfront Interest</mat-option
                        >
                        <mat-option value="REDUCING_BALANCE" i18n="@@reducing_balance"
                          >Reducing Balance</mat-option
                        >
                        <mat-option value="STRAIGHT_LINE"
                         i18n="@@straight_line" >Straight Line</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@charges">Charges *</mat-label>
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[80px]"
                    >
                      <mat-select
                        multiple
                        formControlName="chargeIds"
                        (selectionChange)="chargeIdChange($event.value)"
                      >
                        <mat-option
                          [value]="charge?.id"
                          *ngFor="let charge of charges"
                          >{{ charge?.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@late_payment_charge"
                      >Late Payment Charge *</mat-label
                    >
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[80px]"
                    >
                      <mat-select
                        formControlName="latePaymentChargeId"
                        (selectionChange)="
                          latePaymentchargeIdChange($event.value)
                        "
                      >
                        <mat-option i18n="@@none" [value]="null">None</mat-option>
                        <mat-option
                          [value]="penaltyCharge?.id"
                          *ngFor="let penaltyCharge of penaltyCharges"
                          >{{ penaltyCharge?.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex items-center justify-between">
                    <mat-label i18n="@@late_payment_charge"
                      >Late Payment Grace Period *</mat-label
                    >
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[80px]"
                    >
                      <input
                        type="text"
                        appStrictNumbers
                        matInput
                        formControlName="latePaymentPeriodLength"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-[157px]">
                <div class="mb-[24px] h-[35px]"></div>
                <div class="flex flex-col gap-[5px]">
                  <div class="flex gap-[10px]">
                    <div class="flex items-center gap-[10px]">
                      <mat-label class="w-[62px]" i18n="@@compulsory_savings"
                        >Compulsory Savings *</mat-label
                      >
                      <mat-form-field
                        appearance="outline"
                        class="short_height w-[80px]"
                      >
                        <mat-select
                          formControlName="compulsorySavingsType"
                          (selectionChange)="checkCompulsorySavingsType($event)"
                        >
                          <mat-option i18n="@@none" value="NONE">None</mat-option>
                          <mat-option i18n="@@flat" value="FLAT">Flat</mat-option>
                          <mat-option i18n="@@percentage_of_principal" value="PERCENTAGE_OF_PRINCIPAL"
                            >Percentage of Principal</mat-option
                          >
                          <mat-option
                            i18n="@@percentage_of_instalment"
                            value="PERCENTAGE_OF_INSTALMENT"
                            >Percentage of Installment</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- Compulsory Savings Type Conditional Fields -->
                    <div
                      class=""
                      *ngIf="
                        loanFormCtrl['compulsorySavingsType'].value !== 'NONE'
                      "
                    >
                      <mat-form-field
                        appearance="outline"
                        class="short_height w-[80px]"
                      >
                        <mat-label i18n="@@amortization_type"
                          >{{
                            loanFormCtrl["compulsorySavingsType"].value ===
                            "FLAT"
                              ? "Amount"
                              : "Rate"
                          }}
                        </mat-label>
                        <input
                          *ngIf="
                            loanFormCtrl['compulsorySavingsType'].value ===
                            'FLAT'
                          "
                          type="tel"
                          appCurrency
                          matInput
                          formControlName="compulsorySavingsAmount"
                        />
                        <input
                          *ngIf="
                            loanFormCtrl['compulsorySavingsType'].value !==
                            'FLAT'
                          "
                          type="text"
                          appPercent
                          matInput
                          formControlName="compulsorySavingsAmount"
                          (blur)="
                            isPercentRange(
                              loanForm,
                              'compulsorySavingsType',
                              'compulsorySavingsAmount'
                            )
                          "
                        />
                      </mat-form-field>
                    </div>
                    <div
                      class=""
                      *ngIf="
                        loanFormCtrl['compulsorySavingsType'].value !== 'NONE'
                      "
                    >
                      <mat-form-field
                        appearance="outline"
                        class="short_height w-[80px]"
                      >
                        <mat-label i18n="@@savings_product"
                          >Savings Product</mat-label
                        >
                        <mat-select formControlName="savingsProductId">
                          <mat-option
                            [value]="product.id"
                            *ngFor="let product of savingsProduct"
                            >{{ product.name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- End -->
                  </div>

                  <div class="flex gap-[10px]">
                    <div class="flex items-center gap-[10px]">
                      <mat-label class="w-[62px]" i18n="@@prepayment_penalty"
                        >Prepayment Penalty *</mat-label
                      >
                      <mat-form-field
                        appearance="outline"
                        class="short_height w-[80px]"
                      >
                        <mat-select
                          formControlName="prepaymentPenaltyType"
                          (selectionChange)="checkPrepaymentPenaltyType($event)"
                        >
                          <mat-option i18n="@@none" value="NONE">None</mat-option>
                          <mat-option i18n="@@flat" value="FLAT">Flat</mat-option>
                          <mat-option i18n="@@percentage_of_principal" value="PERCENTAGE_OF_PRINCIPAL"
                            >Percentage of Principal</mat-option
                          >
                          <mat-option
                            i18n="@@percentage_of_instalment"
                            value="PERCENTAGE_OF_INSTALMENT"
                            >Percentage of Installment</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- Prepayment Penalty Type Conditional Fields -->
                    <div
                      class=""
                      *ngIf="
                        loanFormCtrl['prepaymentPenaltyType'].value !== 'NONE'
                      "
                    >
                      <mat-form-field
                        appearance="outline"
                        class="short_height w-[80px]"
                      >
                      <mat-label>
                        <ng-container *ngIf="loanFormCtrl['prepaymentPenaltyType'].value === 'FLAT'">
                          <span i18n="@@amount_label">Amount</span>
                        </ng-container>
                        <ng-container *ngIf="loanFormCtrl['prepaymentPenaltyType'].value !== 'FLAT'">
                          <span i18n="@@rate">Rate</span>
                        </ng-container>
                      </mat-label>
                        <input
                          *ngIf="
                            loanFormCtrl['prepaymentPenaltyType'].value ===
                            'FLAT'
                          "
                          type="tel"
                          appCurrency
                          matInput
                          formControlName="prepaymentPenaltyAmount"
                          (blur)="
                            isPercentRange(
                              loanForm,
                              'prepaymentPenaltyType',
                              'prepaymentPenaltyAmount'
                            )
                          "
                        />
                        <input
                          *ngIf="
                            loanFormCtrl['prepaymentPenaltyType'].value !==
                            'FLAT'
                          "
                          type="text"
                          appPercent
                          matInput
                          formControlName="prepaymentPenaltyAmount"
                          (blur)="
                            isPercentRange(
                              loanForm,
                              'prepaymentPenaltyType',
                              'prepaymentPenaltyAmount'
                            )
                          "
                        />
                      </mat-form-field>
                    </div>
                    <!-- End -->
                  </div>

                  <div class="flex items-center gap-[10px]">
                    <mat-label class="w-[62px]" i18n="@@working_days"
                      >Working Days *</mat-label
                    >
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[80px]"
                    >
                      <mat-select formControlName="isCalenderDays">
                        <mat-option i18n="@@working_days" value="WORKING">Working Days</mat-option>
                        <mat-option i18n="@@calender_days" value="CALENDAR">Calendar Days</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="flex items-center gap-[40px]">
                    <mat-label class="w-[62px]" i18n="@@rounding_multiple"
                      >Rounding Multiple *</mat-label
                    >
                    <mat-form-field
                      appearance="outline"
                      class="short_height w-[80px]"
                    >
                      <input
                        type="text"
                        appStrictNumbers
                        matInput
                        formControlName="roundingMultiple"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="mt-[30px] px-[15px]">
    <app-drag-drop-list
      [isDisabled]="isDisabled"
      [accountingCategories]="accountingCategories"
      [glAccounts]="glAccounts"
      [events]="events"
      [charges]="charges"
      [penaltyCharges]="penaltyCharges"
      [eventMaps]="eventMaps"
      [accountType]="activeAccountType"
      [createDefaultTxn]="!isViewMode"
      (switchAccountTypeEmit)="switchAccountType($event)"
      (emitAccountingList)="submit($event)"
    ></app-drag-drop-list>
    <div class="flex gap-[20px] mb-[50px] justify-end">
      <app-button-icon-ui
        *ngIf="isViewMode && isDisabled"
        [buttonType]="'button'"
        classNames="px-[33px] py-[14px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]"
        (clickEmit)="editForm($event)"
        name="Edit"
        value="edit"
        i18n-name="@@edit_button"
      ></app-button-icon-ui>
      <app-button-icon-ui
        *ngIf="isViewMode && !isDisabled"
        [buttonType]="'button'"
        classNames="px-[33px] py-[14px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]"
        [isDisabled]="!isChanged"
        (clickEmit)="editForm($event)"
        name="Save"
        value="save"
        i18n-name="@@saveButton"
      ></app-button-icon-ui>
      <app-button-icon-ui
        *ngIf="!isViewMode"
        [buttonType]="'button'"
        classNames="px-[33px] py-[14px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]"
        (clickEmit)="submitForm()"
        name="Submit"
        value="submit"
        i18n-name="@@submit"
      ></app-button-icon-ui>
      <app-button-icon-ui
        [buttonType]="'button'"
        classNames="px-[33px] py-[14px] rounded-[10px] border border-error opacity-80 hover:opacity-100 text-error w-[120px] h-[45px]"
        (clickEmit)="cancel()"
        name="Cancel"
        value="cancel"
        i18n-name="@@cancel"
      ></app-button-icon-ui>
    </div>
  </div>
</div>
