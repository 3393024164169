<div [ngStyle]="setMyStyles()"></div>
<div class="login-glass"></div>

<section id="content" class="m-t-lg wrapper-md animated fadeInUp">
  <div class="container aside-xxl forms-container" id="forms-container">
    <a class="block login-brand" href="#"
      ><img src="assets/images/system-images/logo.png"
    /></a>
    <section
      class="panel panel-default bg-white m-t-lg form-login-container"
      id="form-login-container"
    >
      <header class="panel-heading text-center hidden">
        <strong i18n="@@sign_in">Sign in</strong>
      </header>
      <form
        *ngIf="verifyTokenResponse.code === 0"
        action="#"
        autocomplete="off"
        class="panel-body wrapper-lg"
        id="form-login"
      >
        <br />
        <div class="form-group">
          <!--          <label class="col-sm-12 control-label required-field">Username</label>-->
          <input
            type="text"
            placeholder="Username"
            name="_name"
            i18n-placeholder="@@usernameLabel"
            [(ngModel)]="verifyTokenResponse.object.username"
            class="form-control input-md"
            data-smk-msg=" "
            required
            disabled
          />
        </div>
        <div class="form-group">
          <!--          <label class="col-sm-12 control-label required-field">New Password</label>-->
          <input
            type="password"
            placeholder="New Password"
            i18n-placholder="@@newPasswordPlaceholder"
            name="newPassword"
            [(ngModel)]="passwordResetRequest.newPassword"
            class="form-control input-md"
            data-smk-msg=" "
            required
          />
        </div>
        <div class="form-group">
          <!--          <label class="col-sm-12 control-label required-field">Re-type Password</label>-->
          <input
            type="password"
            i18n-placholder="@@retype_password"
            placeholder="Re-type Password"
            name="retypedPassword"
            [(ngModel)]="passwordResetRequest.retypedPassword"
            class="form-control input-md"
            data-smk-msg=" "
            required
          />
        </div>
        <button
          type="button"
          class="btn btn-success btn-login"
          (click)="resetPassword()"
          i18n="@@reset"
        >
          Reset
        </button>
        <br />
        <span
          style="margin-bottom: 20px"
          (click)="backToLogin()"
          class="pull-right m-t-xs"
          ><small style="cursor: pointer" 1i8n="@@back_to_login"
            >Back to login</small
          ></span
        >
      </form>

      <div *ngIf="verifyTokenResponse.code === 96" class="alert alert-danger">
        <i class="fa fa-ban-circle"></i>&nbsp; {{ verifyTokenResponse.message }}

        <strong>
          <a
            (click)="backToLogin()"
            style="cursor: pointer"
            class="alert-link"
            1i8n="@@back_to_login"
            >Back to login</a
          >
        </strong>
      </div>
    </section>

    <footer id="footer">
      <div class="text-center padder">
        <small
          ><a style="color: #8e8c8c" target="_blank" href="http://myaquila.com"
            >©{{ year }} Aquila</a
          ></small
        >
      </div>
    </footer>
  </div>
</section>
