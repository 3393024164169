<div class="flex flex-wrap items-center">
    <div class="flex gap-x-4" *ngIf="actionButtons?.length > 0">
        <ng-container *ngFor="let button of actionButtons">
            <ng-container *ngIf="button.children">
                <button type="btn" class="btn btn-default button-icon flex items-center justify-center" mat-button [matMenuTriggerFor]="children">
          <span>{{ button.name }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
                <mat-menu #children="matMenu">
                    <div class="flex items-center flex-col justify-center gap-2 py-2" *ngFor="let childBtn of button.children">
                        <button [disabled]="disableActionButtons" class="p-3 rounded-xl opacity-90 font-semibold mx-1" *hasPermissions="childBtn.perms" (click)="actionToggled(childBtn.value)">
              {{ childBtn.name }}
            </button>
                    </div>
                </mat-menu>
            </ng-container>
            <ng-container *ngIf="!button.children">
                <button [disabled]="disableActionButtons" [ngClass]="evalClass(button.value)" class="p-3 min-w-[80px] rounded-xl opacity-90 font-semibold mx-1" *hasPermissions="button.perms" (click)="actionToggled(button.value)">
          {{ button.name }}
        </button>
            </ng-container>
        </ng-container>
    </div>
</div>