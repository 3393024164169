import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, merge, switchMap, takeUntil } from 'rxjs';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import {
  ChartFilterControlsInterface,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import { CancelHttpRequestService } from 'src/app/shared/services/cancel-request.service';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-deposit-balance',
  templateUrl: './deposit-balance.component.html',
  styleUrls: ['./deposit-balance.component.css'],
})
export class DepositBalanceComponent implements OnInit {
  public topFiltersFormInstance: FormGroup;
  public productId: any;
  public hideFilters: boolean = false;
  public isLoading: boolean = false;
  public showSortControl: boolean = false;
  public depositBalancePalette: string[] = [
    '#4d5d76',
    '#93A1B1',
    '#C0C7D1',
    '#E5E8EC',
  ];
  public depositBalanceForm: FormGroup;
  public depositBalanceFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    {
      name: ReportInputTypes.DEPOSIT_PRODUCT,
      type: 'DEPOSIT_PRODUCT',
      position: 'right',
    },
  ];
  depositChartData?: { name: string; value: number }[] = [];
  public depositSortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };
  public tenantId = JSON.parse(localStorage.getItem('pf') || null).tenantId;
  public numberOfTenantBranches: number = 0;
  public subject = new Subject<number>();
  public form: FormGroup;

  public reportsInput = ReportInputTypes;

  public sortLabel = $localize`:Sort|label for sort@@sort:Sort`;

  public sortOptions = [
    {
      label: $localize`:Default|label for default@@default:Default`,
      value: 'default',
    },
    {
      label: $localize`:Value accending|label for value acending@@value_acending:Value acending`,
      value: 'value-asc',
    },
    {
      label: $localize`:Value decending|label for value decending@@"value_decending:Value decending`,
      value: 'value-desc',
    },
    {
      label: $localize`:Name accending|label for name acending@@name_acending:Name acending`,
      value: 'name-asc',
    },
    {
      label: $localize`:Name decending|label for name decending@@name_decending:Name decending`,
      value: 'name-desc',
    },
  ];

  constructor(
    private operationalService: OperationalIndicatorService,
    private topFilterService: TopFilterService,
    private utils: UtilityService,
    private fb: FormBuilder
  ) {
    this.topFiltersFormInstance = this.topFilterService.topFilterForm;
  }

  ngOnInit(): void {
    this.initForm();
    this.getDepositChartData();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.SORT]: new FormControl(`default`, [
        Validators.required,
      ]),
      [ReportInputTypes.DEPOSIT_PRODUCT]: new FormControl(0, [
        Validators.required,
      ]),
    });
  }

  public get productCtrl() {
    return this.form.get(ReportInputTypes.DEPOSIT_PRODUCT) as FormControl;
  }

  getDepositChartData() {
    merge(
      this.topFilterService.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          const { areaId, branchId, loanOfficerId, endDate } =
            this.topFiltersFormInstance.value;
          const { productId, tenantId } = this;
          const queryParams = new URLSearchParams({
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(tenantId && { tenantId }),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(productId && { productId }),
          });

          this.isLoading = true;

          return this.operationalService.getDepositBalanceLoans(queryParams);
        })
      )
      .subscribe((res) => {
        this.depositChartData = res.data.data.map((item: any) => ({
          name: item.branchName,
          value: item.depositBalance,
        }));
        this.isLoading = false;

        this.numberOfTenantBranches =
          res.data.total[0].numberOfBranchesForTenant;
        if (this.depositChartData?.length < 2) {
          this.showSortControl = false;
        } else {
          this.showSortControl = true;
        }
      });
  }

  onSort(sortOption: any) {
    this.sortLabel = sortOption.label;
    this.filterDepositBalance({
      value: sortOption.value,
      name: ReportInputTypes.SORT,
      type: ReportInputTypes.SORT,
    });
  }

  filterDepositBalance(ev: any) {
    switch (ev.type) {
      case ReportInputTypes.DEPOSIT_PRODUCT:
        this.productId = ev.value;
        this.subject.next(Math.random());
        break;
      case ReportInputTypes.SORT:
        const selectedString =
          ev.value === 'default' ? 'name-default' : ev.value;
        const [key, sortingDirection] = selectedString.split('-');
        this.depositSortingOptions = { key, sortingDirection };
        break;
    }
  }
}
