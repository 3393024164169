import { Injectable } from '@angular/core';

export const CHART_TITLES = {
  'Fees & Commissions': 'Fees & Commissions',
  'Gross Earnings': 'Gross Earnings',
  'Interest Income vs. Interest Expense':
    'Interest Income vs. Interest Expense',
  'Net Interest Income': 'Net Interest Income',
  'Staff Cost': 'Staff Cost',
  'Clients Occupation': 'Clients Occupation',
  Clients: 'Clients',
  'Daily Repayment vs. Disbursement': 'Daily Repayment vs. Disbursement',
  'Deposit Balance': 'Deposit Balance',
  'Disbursed Loans': 'Disbursed Loans',
  'Portfolio-at-Risk': 'Portfolio-at-Risk',
  'Running Loans': 'Running Loans',
  'Write-Offs': 'Write-Offs',
  'Logged-In Users': 'Logged-In Users',
  'Recent Activity': 'Recent Activity',
  'Approved Client': 'Approved Client',
  'Active Client': 'Active Client',
  'Amount Disbursed': 'Amount Disbursed',
  'Loans Outstanding': 'Loans Outstanding',
  'Total Repayment': 'Total Repayment',
};

@Injectable({
  providedIn: 'root',
})
export class ChartLocalizationService {
  getLocalizedCHARTName(title: keyof typeof CHART_TITLES): string {
    switch (title) {
      case 'Fees & Commissions':
        return $localize`:Fees & Commissions|label for fees and commissions@@fees_and_commissions:Fees & Commissions`;

      case 'Gross Earnings':
        return $localize`:Gross Earnings|label for gross earnings@@gross_earnings:Gross Earnings`;

      case 'Interest Income vs. Interest Expense':
        return $localize`:Interest Income vs. Interest Expense|label for interest income vs expense@@interest_income_vs_expense:Interest Income vs. Interest Expense`;

      case 'Net Interest Income':
        return $localize`:Net Interest Income|label for net interest income@@net_interest_income:Net Interest Income`;

      case 'Staff Cost':
        return $localize`:Staff Cost|label for staff cost@@staff_cost:Staff Cost`;

      case 'Clients Occupation':
        return $localize`:Clients Occupation|label for clients occupation@@clients_occupation:Clients Occupation`;

      case 'Clients':
        return $localize`:Clients|label for clients@@clients:Clients`;

      case 'Daily Repayment vs. Disbursement':
        return $localize`:Daily Repayment vs. Disbursement|label for daily repayment vs. disbursement@@daily_repayments_vs_disbursement:Daily Repayment vs. Disbursement`;

      case 'Portfolio-at-Risk':
        return $localize`:Portfolio-at-Risk|label for portfolio at risk@@portfolio_at_risk_title:Portfolio-at-Risk`;

      case 'Write-Offs':
        return $localize`:Write-Offs|label for write offs@@write_offs:Write-Offs`;

      case 'Logged-In Users':
        return $localize`:Logged-In Users|label for logged in users@@logged_in_users:Logged-In Users`;

      case 'Recent Activity':
        return $localize`:Recent Activity|label for recent activity@@recent_activity:Recent Activity`;

      case 'Approved Client':
        return $localize`:Approved Client|label for approved client@@approved_client:Approved Client`;

      case 'Active Client':
        return $localize`:Active Client|label for active client@@active_client:Active Client`;

      case 'Disbursed Loans':
        return $localize`:Disbursed Loans|label for disbursed loans@@disbursed_loans:Disbursed Loans`;

      case 'Amount Disbursed':
        return $localize`:Amount Disbursed|label for amount disbursed@@amount_disbursed:Amount Disbursed`;

      case 'Running Loans':
        return $localize`:Running Loans|label for running loans@@running_loans:Running Loans`;

      case 'Loans Outstanding':
        return $localize`:Loans Outstanding|label for loans outstanding@@loans_outstanding:Loans Outstanding`;

      case 'Deposit Balance':
        return $localize`:Deposit Balance|label for deposit balance@@deposit_balance:Deposit Balance`;

      case 'Total Repayment':
        return $localize`:Total Repayment|label for total repayment@@total_repayment:Total Repayment`;

      default:
        return CHART_TITLES[title] || title;
    }
  }
}
