import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  EventEmitter,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-lock-screen',
  templateUrl: './modal-lock-screen.component.html',
  styleUrls: ['./modal-lock-screen.component.css'],
})
export class ModalLockScreenComponent implements OnChanges {
  @ViewChild('unlockBtn')unlockBtn:ElementRef<HTMLButtonElement>
  @Input() u: any = {};
  @Output() resetIdle: EventEmitter<any> = new EventEmitter();

  public baseUrl: string = environment.baseUrl;
  public passwordVisible: boolean = false;

  public unlockScreenParams: any = {};

  constructor(
    private toast: NotificationService,
    private _auth: AuthService,
    private utils: UtilityService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.unlockScreenParams.username) {
      const user = JSON.parse(localStorage.getItem('pf') || null);
      if (!user) return;

      this.u.username = user.email;
    }
  }

  public unlockScreen(): void {
    if (!this.unlockScreenParams?.password || this.unlockScreenParams.password === '') {
      this.toast.error('Password is required');
      return;
    }
    this.unlockBtn.nativeElement.disabled = true;
    this.unlockBtn.nativeElement.innerText = $localize`:@@please_wait:Please Wait`;
    this.unlockScreenParams.email = this.u.username;

    this._auth
      .login(this.unlockScreenParams.email, this.unlockScreenParams.password)
      .subscribe({
        next: () => {
          this.utils.unlockScreen();
          this.resetIdle.emit();
          this.unlockBtn.nativeElement.disabled = false;
          this.unlockBtn.nativeElement.innerText = $localize`:@@unlock:Unlock`;
          this.unlockScreenParams.password = '';
        },
        error: (res: HttpErrorResponse) => {
          this.unlockBtn.nativeElement.disabled = false;
          this.unlockBtn.nativeElement.innerText = $localize`:@@unlock:Unlock`
          this.toast.error(res.error.message)
        },
      });
  }
}
