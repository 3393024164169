<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <app-modal-header-ui
    i18n-title="@@new_deposit_account"
    title="New Deposit Account"
  ></app-modal-header-ui>
  <div class="dialog-body">
    <form
      class="w-[330px] gap-y-4 my-5 flex flex-col"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <div class="formField">
        <app-deposit-product-search-select
          (emitSelectedVal)="emitSelectedVal($event)"
          [control]="savingsFormCtrl"
          type="server"
        ></app-deposit-product-search-select>
      </div>
      <!-- <div class="formField">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Savings Ref. Acc</mat-label>
                <mat-select formControlName="savingsReferenceGlAccountId">
                    <mat-option *ngFor="let ref of savingsRef" [value]="ref.id">
                        {{ ref.glAccountName || 'Unknown Reference' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
      <div style="gap: 0 10px" class="flex items-center justify-between mb-3">
        <app-button-icon-ui
          [buttonType]="'submit'"
          classNames="p-3 rounded-xl text-white bg-success/90 hover:bg-success w-[150px]"
          i18n-name="@@submit"
          name="Submit"
          value="submit"
        ></app-button-icon-ui>
        <app-button-icon-ui
          [buttonType]="'button'"
          classNames="p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[150px]"
          (clickEmit)="cancel()"
          i18n-name="@@cancel"
          name="Cancel"
          value="cancel"
        ></app-button-icon-ui>
      </div>
    </form>
  </div>
</div>
