import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, debounceTime, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-search-select-multiple',
  templateUrl: './search-select-multiple.component.html',
  styleUrls: ['./search-select-multiple.component.css'],
})
export class SearchSelectMultipleComponent implements OnInit {
  @Input() type: 'client' | 'server' = 'server';
  @Input() label: string = $localize`:@@add_label:Add Label`;
  @Input() placeholder: string = $localize`:@@find_record:Find record..` ;
  @Input() endpoint: string = '';
  // pass list for client side search
  @Input() set fetchedList(list) {
    this.clientList = list;
    this.filteredList.next(list);
  }
  // list of parameter you want to filter by on client side search
  @Input() filterBy = ['name'];
  @Input() defaultSelected: any;
  @Input() defaultListValue: any;

  @Input() showDefaultListValue: boolean = true;
  @Input() control: FormControl;
  @Input() keyValue: { key: string[] | string; value: string, joinSymbol?: string } = {
    key: 'name',
    value: 'id',
  };
  @Input() filteredList = new BehaviorSubject<any>([]);
  public selectedList = new BehaviorSubject<any>([]);

  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public toggledAllCheckbox = false;
  private clientList = [];
  public filterCtrl: FormControl = new FormControl();
  public currentVal;
  public isIndeterminate = false;
  public isChecked = false;

  constructor(private http: HttpClient) {}

  private initFilter() {
    if (this.defaultSelected) {
      this.filteredList.next([this.defaultSelected]);
      this.control.setValue(this.defaultSelected[this.keyValue['value']]);
      this.currentVal = this.defaultSelected;
    }

    this.filterCtrl.valueChanges
      .pipe(
        debounceTime(600),
        switchMap((value) => {
          if (this.type === 'client') {
            return of({
              list: this.clientList,
              param: value,
            });
          }
          if (value === '') return [];
          if (!value) return of([]);
          return this.http
            .get(`${this.endpoint}${value}`)
            .pipe(map((d: any) => d.data));
        })
      )
      .subscribe((res) => {
        if (this.type === 'client') {
          this.filterClientList(res.list, res.param);
          return;
        }
        this.filterServerList(res);
      });

    this.control.valueChanges.subscribe((val: any[]) => {
      let countMatch = 0;
      const selected = [];
      const value = this.filteredList.value.filter((obj) => {

        const isMatch = val?.indexOf(obj[this.keyValue['value']]);

        if (isMatch >= 0) {
          selected.push(obj);
          countMatch++;
        }
        return isMatch;
      });
      this.selectedList.next(selected);

      if (
        this.filteredList.value.length &&
        countMatch === this.filteredList.value.length
      ) {
        this.toggledAllCheckbox = true;
      } else {
        this.toggledAllCheckbox = false;
      }

      this.currentVal = value;
      this.emitSelectedVal.emit(selected);
    });
  }

  clickOut(val) {
    if (!val) {
      const res = [...this.list.value].map((val: any) => JSON.parse(val));
      this.filteredList.next(res);
    }
  }

  private list = new BehaviorSubject(new Set());

  private filterServerList(res: any) {
    // const currentVal = this.control.value;
    // res = res.filter((val) => val[this.keyValue['value']] !== currentVal);
    // const records = currentVal ? [this.currentVal, ...res] : res;
    const newSet = new Set();
    this.list.next(newSet);
    const listVal = this.list.value;
    const selectedList = this.selectedList.value;
    
    selectedList.forEach((element) => {
      listVal.add(JSON.stringify(element));
    });
    
    res.forEach((element) => {
      listVal.add(JSON.stringify(element));
    });
    // this.list.next(listVal);
    // this.list.next([...this.list.value, ...res]);
    const allList = [...this.list.value].map((val: any) => JSON.parse(val));

    if (selectedList.length && allList.length === selectedList.length) {
      this.toggledAllCheckbox = true;
    } else {
      this.toggledAllCheckbox = false;
    }
    this.filteredList.next(allList);
  }

  private filterClientList(res: any, value: string) {
    const records = res.filter((record) => {
      const currentVal = this.control.value;
      if (record[this.keyValue['value']] === currentVal) return true;
      for (let i = 0, len = this.filterBy.length; i < len; i++) {
        const key = record[this.filterBy[i]].toLowerCase();
        if (key.includes(value.toLowerCase())) return true;
      }
      return false;
    });

    this.filteredList.next(records);
  }

  ngOnInit(): void {
    this.initFilter();
  }

  public toggleSelectAll(selectAllValue: boolean) {
    if (selectAllValue) {
      this.control.patchValue(this.filteredList.value.map((val) => val.id));
    } else {
      this.control.patchValue([]);
    }
  }
}
