import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-client-table',
  templateUrl: './chart-client-table.component.html',
  styleUrls: ['./chart-client-table.component.css'],
})
export class ChartClientTableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
